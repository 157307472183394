import { Outlet, Navigate, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { Box, CircularProgress, Stack } from '@mui/material';
import logo from '../assets/images/logo_blue.svg';

const RequireAuth = ({ allowedRoles }) => {
  const { auth, role, Admin } = useAuth();
  const location = useLocation();

  if (['Super Admin', ...Admin].includes(role)) {
    return allowedRoles.includes(role) ? (
      <Outlet />
    ) : auth?.accessToken ? (
      <Box sx={{ height: '100vh', display: 'grid', placeItems: 'center' }}>
        <Stack direction={'column'} alignItems="center" gap={2}>
          <img src={logo} alt="" />
          <CircularProgress />
        </Stack>
      </Box>
    ) : (
      <Navigate to="/login" state={{ from: location }} replace />
    );
  }
  if (['ASM', 'RSM', 'DSM'].includes(role)) {
    return allowedRoles.includes(role) ? (
      <Outlet />
    ) : auth?.accessToken ? (
      <Navigate to="/" state={{ from: location }} replace />
    ) : (
      <Navigate to="/login" state={{ from: location }} replace />
    );
  }
  if (role === 'SO/SR') {
    return allowedRoles.includes(role) ? (
      <Outlet />
    ) : auth?.accessToken ? (
      <Navigate to="/customers" state={{ from: location }} replace />
    ) : (
      <Navigate to="/login" state={{ from: location }} replace />
    );
  }
  if (role === 'Accounts' || role === 'DO') {
    return allowedRoles.includes(role) ? (
      <Outlet />
    ) : auth?.accessToken ? (
      <Navigate to="/collections" state={{ from: location }} replace />
    ) : (
      <Navigate to="/login" state={{ from: location }} replace />
    );
  }
  if (role === 'IMS') {
    return allowedRoles.includes(role) ? (
      <Outlet />
    ) : auth?.accessToken ? (
      <Navigate to="/outletinfo" state={{ from: location }} replace />
    ) : (
      <Navigate to="/login" state={{ from: location }} replace />
    );
  }
  if (role === 'Factory') {
    return allowedRoles.includes(role) ? (
      <Outlet />
    ) : auth?.accessToken ? (
      <Navigate to="/factory" state={{ from: location }} replace />
    ) : (
      <Navigate to="/login" state={{ from: location }} replace />
    );
  }
  if (role === 'Default') {
    return allowedRoles.includes(role) ? (
      <Outlet />
    ) : auth?.accessToken ? (
      <Navigate to="/welcome" state={{ from: location }} replace />
    ) : (
      <Navigate to="/login" state={{ from: location }} replace />
    );
  }
  return allowedRoles.includes(role) ? (
    <Outlet />
  ) : auth?.accessToken ? (
    <Box sx={{ height: '100vh', display: 'grid', placeItems: 'center' }}>
      <Stack direction={'column'} alignItems="center" gap={2}>
        <img src={logo} alt="" />
        <CircularProgress />
      </Stack>
    </Box>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};
export default RequireAuth;
