import { Box, styled } from '@mui/material';

export const LayoutContainer = styled(Box)(({ theme, left }) => ({
  display: 'flex',
  width: '100%',
  '& .leftBox': {
    // display: 'flex',
    // flexDirection: 'column',
    // height: '100vh',

    minHeight: '100vh',
    // overflowY: 'auto',
    background: `${theme.palette.white.main}`,
  },
  '& .leftBox::-webkit-scrollbar': {
    width: 5,
  },
  '& .leftBox::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 2px grey',
  },
  '& .leftBox::-webkit-scrollbar-thumb': {
    background: `${theme.palette.primary.main}`,
    borderRadius: 10,
  },
  '& .rightBox': {
    overflow: 'auto',
    minHeight: '100vh',
    width: '100%',
    '& .childrenContainer': {
      padding: 25,
    },
  },
  [theme.breakpoints.down('md')]: {
    '& .leftBox': {
      position: 'fixed',
      zIndex: 100,
      paddingTop: 65,

      left: left,
      top: 0,
      overflow: 'auto',
      height: '100vh',
    },
    '& .rightBox': {
      '& .childrenContainer': {
        padding: 15,
      },
    },
  },
}));
