import BorderColorIcon from '@mui/icons-material/BorderColor';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import validator from 'validator';
import Modals from '../../component/Modals';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import DynamicForm from './DynamicForm';
import RedirectDialog from './RedirectDialog';

// const example = [
//   { companyName: 'GOLD MARK', productCat: 'DRINKS', amount: '' },
//   { companyName: 'KOOKIEJAR', productCat: 'JUICE', amount: '' },
//   { companyName: 'KOOKIEJAR', productCat: 'ICE CREAM', amount: '' },
// ];

export default function DealerForm() {
  const { userId, role } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const { state: x } = useLocation();

  const [modalShow, setModalShow] = useState(false);
  const [redirectShow, setRedirectShow] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [createdId, setCreatedId] = useState('');

  // error states
  const [showModalError, setShowModalError] = useState(false);
  const [showFormError, setShowFormError] = useState(false);
  const [showCompanyError, setShowCompanyError] = useState(false);
  const [nameError, setNameError] = useState('');
  const [dealerTypeError, setDealerTypeError] = useState('');
  const [mobileNoError, setMobileNoError] = useState('');
  const [primaryContactError, setPrimaryContactError] = useState('');
  const [secondaryConatctError, setSecondaryConatctError] = useState('');
  const [dealerNameError, setDealerNameError] = useState('');
  const [transactionCategoryError, setTransactionCategoryError] = useState('');
  const [runningSO_SRError, setRunningSO_SRError] = useState('');
  const [noOfVansError, setNoOfVansError] = useState('');
  const [locationError, setLocationError] = useState('');

  // selection states
  const [regionList, setRegionList] = useState([]);
  const [pointTownList, setPointTownList] = useState([]);
  const [hqList, setHqList] = useState([]);

  // form states
  const [image, setImage] = useState(null);
  const [name, setName] = useState(
    x?.name || localStorage.getItem('name') || '',
  );
  const [mobileNo, setMobileNo] = useState(
    x?.mobileNo || localStorage.getItem('mobileNo') || '',
  );
  const [visitDate, setVisitDate] = useState(
    x?.visitDate ? dayjs(x?.visitDate) : dayjs(),
  );
  const [dealerName, setDealerName] = useState(x?.dealerName || '');
  const [primaryNo, setPrimaryNo] = useState(x?.primaryNo || '');
  const [secondaryNo, setSecondaryNo] = useState(x?.secondaryNo || '');
  const [dealerType, setDealerType] = useState(x?.dealerType || '');
  const [region, setRegion] = useState(
    x?.region || {
      id: localStorage.getItem('regionId'),
      name: localStorage.getItem('region'),
    },
  );
  const [hq, setHq] = useState(
    x?.hq || {
      id: localStorage.getItem('hqId'),
      name: localStorage.getItem('hq'),
    },
  );
  const [pointTown, setPointTown] = useState(
    x?.pointTown || {
      id: localStorage.getItem('pointTownID'),
      name: localStorage.getItem('pointTown'),
    },
  );

  const [companies, setCompanies] = useState(
    x?.companies?.reduce((acc, curr) => {
      const existingCompany = acc.find(
        (item) => item.companyName === curr.companyName,
      );
      if (existingCompany) {
        existingCompany.categorySales.push({
          title: curr.productCat,
          amount: curr.amount,
        });
      } else {
        acc.push({
          companyName: curr.companyName,
          categorySales: [{ title: curr.productCat, amount: curr.amount }],
        });
      }
      return acc;
    }, []) || [
      {
        companyName: '',
        categorySales: [{ title: '', amount: '' }],
      },
    ],
  );

  const [transactionCategory, setTransactionCategory] = useState(
    x?.transactionCategory || '',
  );
  const [runningSO_SR, setRunningSO_SR] = useState(x?.runningSO_SR || '');
  const [noOfVans, setNoOfVans] = useState(x?.noOfVans || '');
  const [latitude, setLatitude] = useState(x?.latitude || null);
  const [longitude, setLongitude] = useState(x?.longitude || null);
  const [status, setStatus] = useState(x?.status || 'pending');

  const timer = useRef(null);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          setLocationError(null);
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              setLocationError(
                'You have to turn on location service and allow the request.',
              );
              break;
            case error.POSITION_UNAVAILABLE:
              setLocationError(
                'Location information is unavailable. Check your internet connection.',
              );
              break;
            case error.TIMEOUT:
              setLocationError('The request to get user location timed out.');
              break;
            case error.UNKNOWN_ERROR:
              setLocationError(
                'An unknown error occurred while getting your location.',
              );
              break;
            default:
              setLocationError(error.message);
              break;
          }
        },
      );
    } else {
      clearInterval(timer.current);
      setLocationError(
        'Geolocation is not supported by this browser. Please use Chrome Browser.',
      );
    }
  };

  useEffect(() => {
    if (!x?.id) {
      timer.current = setInterval(getLocation, 10000);
      getLocation();
    } else {
      clearInterval(timer.current);
    }
    return () => {
      clearInterval(timer.current);
    };
  }, [x?.id]);

  useEffect(() => {
    if (locationError) {
      alert(locationError);
    }
  }, [locationError]);

  // get region by region Id
  useEffect(() => {
    axiosPrivate
      .get(
        '/api/v1/territory?populate=yes&limit=none&territoryType=63aaa3c505e4dc7daa1a85ce',
      )
      .then((res) => {
        setRegionList(res?.data?.data);
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.message);
      });
  }, []);

  // get HQ by it's parent territory id
  useEffect(() => {
    axiosPrivate
      .get(
        `/api/v1/territory?populate=yes&limit=none&parentTerritory=${region?.id}`,
      )
      .then((res) => {
        setHqList(res?.data?.data);
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.message);
      });
  }, [region?.id]);

  // get Point/Town by it's parent territory id
  useEffect(() => {
    axiosPrivate
      .get(
        `/api/v1/territory?populate=yes&limit=none&parentTerritory=${hq?.id}`,
      )
      .then((res) => {
        setPointTownList(res?.data?.data);
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.message);
      });
  }, [hq?.id]);

  // // -----------------------------

  // verify Mobile Number
  useEffect(() => {
    if (!validator.isMobilePhone(mobileNo, 'bn-BD', { strictMode: false })) {
      setMobileNoError('Invalid phone');
    } else {
      setMobileNoError(null);
    }
  }, [mobileNo]);

  // verify Primary Number
  useEffect(() => {
    if (
      !validator.isMobilePhone(primaryNo, 'bn-BD', {
        strictMode: false,
      })
    ) {
      setPrimaryContactError('Invalid phone');
    } else {
      setPrimaryContactError(null);
    }
  }, [primaryNo]);

  // verify Secondary Number
  useEffect(() => {
    if (
      secondaryNo &&
      !validator.isMobilePhone(secondaryNo, 'bn-BD', {
        strictMode: false,
      })
    ) {
      setSecondaryConatctError('Invalid phone');
    } else {
      setSecondaryConatctError(null);
    }
  }, [secondaryNo]);

  //   verify Name
  useEffect(() => {
    if (name.length < 3) {
      setNameError('Valid name is required');
    } else {
      setNameError(null);
    }
  }, [name]);

  //   verify Dealer Name
  useEffect(() => {
    if (dealerName.length < 1) {
      setDealerNameError('Dealer Name is required');
    } else {
      setDealerNameError(null);
    }
  }, [dealerName]);

  // verify dealer Type
  useEffect(() => {
    if (dealerType.length < 3) {
      setDealerTypeError('Must select a Dealer Type');
    } else {
      setDealerTypeError(null);
    }
  }, [dealerType]);

  // verify Transaction Category
  useEffect(() => {
    if (transactionCategory?.length < 1) {
      setTransactionCategoryError('Must select a Transaction Category');
    } else {
      setTransactionCategoryError(null);
    }
  }, [transactionCategory]);

  // verify runningSO/SR
  useEffect(() => {
    if (runningSO_SR?.length < 1) {
      setRunningSO_SRError('Must select Running SO/SR Number');
    } else {
      setRunningSO_SRError(null);
    }
  }, [runningSO_SR]);

  // verify noOfVans
  useEffect(() => {
    if (noOfVans?.length < 1) {
      setNoOfVansError('Must select Number Of Vans');
    } else {
      setNoOfVansError(null);
    }
  }, [noOfVans]);

  // open modal
  useEffect(() => {
    if (
      localStorage.getItem('name') &&
      localStorage.getItem('mobileNo') &&
      localStorage.getItem('regionId') &&
      localStorage.getItem('hqId') &&
      localStorage.getItem('pointTownID')
    ) {
      setModalShow(false);
    } else if (x?.id) {
      setModalShow(false);
    } else {
      setModalShow(true);
    }
  }, [x?.id]);

  const checkCompanyError = () => {
    const formData = [...companies];
    formData.forEach((company, companyIndex) => {
      if (company.companyName === '') {
        company.nameError = 'Company name is required';
        setShowCompanyError(true);
      } else {
        delete company.nameError;
        setShowCompanyError(false);
      }

      company.categorySales.forEach((cat, catIndex) => {
        if (cat.title === '') {
          cat.titleError = 'error';
          setShowCompanyError(true);
        } else {
          delete cat.titleError;
          setShowCompanyError(false);
        }
        if (cat.amount === '') {
          cat.amountError = 'error';
          setShowCompanyError(true);
        } else {
          delete cat.amountError;
          setShowCompanyError(false);
        }
      });
    });

    setCompanies(formData);
  };

  // edit modal
  const handleEdit = () => {
    setModalShow(true);
  };

  // save information on local storage
  const saveModal = () => {
    setShowModalError(true);
    if (!nameError && !mobileNoError) {
      setShowModalError(false);

      localStorage.setItem('name', name);
      localStorage.setItem('mobileNo', mobileNo);
      localStorage.setItem('region', region.name);
      localStorage.setItem('regionId', region.id);
      localStorage.setItem('hq', hq.name);
      localStorage.setItem('hqId', hq.id);
      localStorage.setItem('pointTown', pointTown.name);
      localStorage.setItem('pointTownID', pointTown.id);

      window.location.reload();
    }
  };

  // const outputData = example.reduce((acc, curr) => {
  //   const existingCompany = acc.find(
  //     (item) => item.companyName === curr.companyName,
  //   );
  //   if (existingCompany) {
  //     existingCompany.categorySales.push({
  //       title: curr.productCat,
  //       amount: curr.amount,
  //     });
  //   } else {
  //     acc.push({
  //       companyName: curr.companyName,
  //       categorySales: [{ title: curr.productCat, amount: curr.amount }],
  //     });
  //   }
  //   return acc;
  // }, []);
  // console.log('outputData: ', outputData);

  const handleSubmit = async () => {
    const modifiedcompaniesArray = [];

    await companies.forEach((item) => {
      item?.categorySales.forEach((product) => {
        modifiedcompaniesArray.push({
          companyName: item.companyName,
          productCat: product.title,
          amount: product.amount,
        });
      });
    });

    setShowFormError(true);

    if (!x?.id) {
      getLocation();
    }

    checkCompanyError();

    if (
      !nameError &&
      !dealerTypeError &&
      !mobileNoError &&
      !primaryContactError &&
      !secondaryConatctError &&
      !noOfVansError &&
      !runningSO_SRError &&
      !transactionCategoryError &&
      !showCompanyError
    ) {
      setShowFormError(false);
      setIsSubmitting(true);

      const data = new FormData();

      data.append('name', name);
      data.append('visitDate', visitDate);
      data.append('mobileNo', mobileNo);
      data.append('dealerName', dealerName);
      data.append('primaryNo', primaryNo);
      data.append('secondaryNo', secondaryNo);
      data.append('dealerType', dealerType);
      data.append('region', region?.id);
      data.append('hq', hq?.id);
      data.append('pointTown', pointTown?.id);
      data.append('companies', JSON.stringify(modifiedcompaniesArray));
      data.append('transactionCategory', transactionCategory);
      data.append('runningSO_SR', runningSO_SR);
      data.append('noOfVans', noOfVans);
      data.append('latitude', latitude);
      data.append('longitude', longitude);
      if (x?.id) data.append('status', status);
      if (image instanceof File && image.type.startsWith('image/')) {
        data.append('image', image);
      }

      if (x?.id) {
        // Updating x document
        axiosPrivate
          .patch(`/api/v1/dealer/${x?.id}`, data)
          .then((res) => {
            toast.success(res?.data?.message);

            if (res?.data?.data?.clamiedTaDa) {
              navigate('/dealerinfo', { state: null });
            } else {
              setCreatedId(res?.data?.data?.id);
              setRedirectShow(true);
            }
          })
          .catch((error) => {
            toast.warn(error?.response?.data?.message);
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      } else {
        data.append('recordedBy', userId);

        axiosPrivate
          .post('/api/v1/dealer', data)
          .then((res) => {
            toast.success(res?.data?.message);

            setCreatedId(res?.data?.data?.id);
            setRedirectShow(true);
          })
          .catch((error) => {
            toast.warn(error?.response?.data?.message);
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      }
    }
  };

  const DealerCatList = [
    'CATEGORY A: > 1500000 TAKA MONTHLY TRANSACTION',
    'CATEGORY B: 1000000 - 1500000 TAKA MONTHLY TRANSACTION',
    'CATEGORY C: 500000 - 1000000 TAKA MONTHLY TRANSACTION',
    'CATEGORY D: < 500000 TAKA MONTHLY TRANSACTION',
  ];

  return (
    <>
      <Stack
        sx={{ maxWidth: '600px', margin: '20px auto' }}
        gap={2}
        p={{ md: 3, xs: 1 }}
        pt={0}>
        <Stack
          direction="column"
          component={Paper}
          sx={{ borderRadius: '10px' }}
          p={{ md: 3, xs: 2 }}
          gap={3}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography>Recording Person Information</Typography>
            <Button
              disabled={x?.id ? true : false}
              variant="contained"
              onClick={handleEdit}
              startIcon={<BorderColorIcon />}>
              Edit
            </Button>
          </Stack>

          <TextField
            disabled
            label="Name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={showFormError && nameError}
            helperText={showFormError && nameError}
          />

          <TextField
            disabled
            label="Mobile Number"
            type="text"
            value={mobileNo}
            onChange={(e) => setMobileNo(e.target.value)}
            error={showFormError && mobileNoError}
            helperText={showFormError && mobileNoError}
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              disabled
              label="Date of Visit"
              inputFormat="DD/MM/YYYY"
              value={visitDate}
              onChange={(newValue) => setVisitDate(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Stack>

        <Stack
          direction="column"
          component={Paper}
          sx={{ borderRadius: '10px' }}
          gap={3}>
          <Stack direction="column" p={{ md: 3, xs: 2 }} gap={3}>
            <Typography>New Dealer Information</Typography>

            <TextField
              label="Dealer Name"
              type="text"
              value={dealerName}
              onChange={(e) => setDealerName(e.target.value)}
              error={showFormError && dealerNameError}
              helperText={showFormError && dealerNameError}
            />

            <TextField
              label="Primary Number"
              type="number"
              value={primaryNo}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => setPrimaryNo(e.target.value)}
              error={showFormError && primaryContactError}
              helperText={showFormError && primaryContactError}
            />

            <TextField
              label="Secondary Number"
              type="number"
              onWheel={(e) => e.target.blur()}
              value={secondaryNo}
              onChange={(e) => setSecondaryNo(e.target.value)}
              error={showFormError && secondaryConatctError}
              helperText={showFormError && secondaryConatctError}
            />

            <FormControl fullWidth error={showFormError && dealerTypeError}>
              <InputLabel>Dealer Type</InputLabel>
              <Select
                value={dealerType}
                label="Dealer Type"
                onChange={(e) => setDealerType(e.target.value)}>
                <MenuItem value="DEALER">DEALER</MenuItem>
                <MenuItem value="DEALER CUM SR">DEALER CUM SR</MenuItem>
                <MenuItem value="DEPOT">DEPOT</MenuItem>
              </Select>
              {showFormError ? (
                <FormHelperText>{dealerTypeError}</FormHelperText>
              ) : null}
            </FormControl>

            <Autocomplete
              fullWidth
              disabled
              options={regionList}
              value={region}
              isOptionEqualToValue={(option, value) => value?.id === option?.id}
              onChange={(event, value) => {
                setRegion(value);
                setHq({});
                setPointTown({});
              }}
              getOptionLabel={(option) => option?.name || ''}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Select Region" />
              )}
            />

            <Autocomplete
              fullWidth
              disabled
              options={hqList}
              value={hq}
              isOptionEqualToValue={(option, value) => value?.id === option?.id}
              onChange={(event, value) => {
                setHq(value);
                setPointTown({});
              }}
              getOptionLabel={(option) => option?.name || ''}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Select HQ" />
              )}
            />

            <Autocomplete
              fullWidth
              disabled
              options={pointTownList}
              value={pointTown}
              isOptionEqualToValue={(option, value) => value?.id === option?.id}
              onChange={(event, value) => {
                setPointTown(value);
              }}
              getOptionLabel={(option) => option?.name || ''}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Select Point/Town" />
              )}
            />

            <FormControl fullWidth error={showFormError && runningSO_SRError}>
              <InputLabel>No. Of Running SO/SR</InputLabel>
              <Select
                value={runningSO_SR}
                label="No. Of Running SO/SR"
                onChange={(e) => setRunningSO_SR(e.target.value)}>
                <MenuItem value=">=4">{'>=4'}</MenuItem>
                <MenuItem value="2-3">2-3</MenuItem>
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="0">0</MenuItem>
              </Select>
              {showFormError ? (
                <FormHelperText>{runningSO_SRError}</FormHelperText>
              ) : null}
            </FormControl>

            <FormControl fullWidth error={showFormError && noOfVansError}>
              <InputLabel>No. Of Vans</InputLabel>
              <Select
                value={noOfVans}
                label="No. Of Vans"
                onChange={(e) => setNoOfVans(e.target.value)}>
                <MenuItem value=">=3">{'>=3'}</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="0">0</MenuItem>
              </Select>
              {showFormError ? (
                <FormHelperText>{noOfVansError}</FormHelperText>
              ) : null}
            </FormControl>
          </Stack>

          <Stack direction="column" px={{ md: 3, xs: 1 }} gap={3}>
            <DynamicForm
              companies={companies}
              setCompanies={setCompanies}
              setError={setShowFormError}
            />
          </Stack>

          <Stack direction="column" p={{ md: 3, xs: 2 }} gap={3}>
            <FormControl
              fullWidth
              error={showFormError && transactionCategoryError}>
              <InputLabel>Dealer Monthly Transaction</InputLabel>
              <Select
                value={transactionCategory}
                label="Dealer Monthly Transaction"
                onChange={(e) => setTransactionCategory(e.target.value)}>
                {DealerCatList.map((dealerCat, DealerCatIndex) => (
                  <MenuItem
                    key={DealerCatIndex}
                    value={dealerCat}
                    sx={{
                      whiteSpace: 'initial',
                    }}>
                    {dealerCat}
                  </MenuItem>
                ))}
              </Select>
              {showFormError ? (
                <FormHelperText>{transactionCategoryError}</FormHelperText>
              ) : null}
            </FormControl>

            {/* <Takephoto image={image} setImage={setImage} /> */}

            {x?.id && ['Super Admin'].includes(role) ? (
              <FormControl>
                <FormLabel id="outlet-status-label">
                  Verification Status
                </FormLabel>
                <RadioGroup
                  aria-labelledby="outlet-status-label"
                  name="outlet-status"
                  value={status}
                  defaultValue="pending"
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}>
                  <FormControlLabel
                    value="pending"
                    control={<Radio />}
                    label={
                      <Box
                        sx={{ display: 'inline-flex', alignItems: 'center' }}>
                        Pending&nbsp;
                        <HelpOutlineIcon color="primary" fontSize="small" />
                      </Box>
                    }
                  />
                  <FormControlLabel
                    value="verified"
                    control={<Radio />}
                    label={
                      <Box
                        sx={{ display: 'inline-flex', alignItems: 'center' }}>
                        Verified&nbsp;
                        <CheckCircleIcon color="success" fontSize="small" />
                      </Box>
                    }
                  />
                  <FormControlLabel
                    value="failed"
                    control={<Radio />}
                    label={
                      <Box
                        sx={{ display: 'inline-flex', alignItems: 'center' }}>
                        Failed&nbsp;
                        <CancelIcon color="error" fontSize="small" />
                      </Box>
                    }
                  />
                </RadioGroup>
              </FormControl>
            ) : null}

            {locationError ? (
              <p
                style={{
                  fontSize: 'large',
                  fontWeight: 'bold',
                  color: 'red',
                  textAlign: 'center',
                }}>
                {locationError}
              </p>
            ) : (
              <table width={200} style={{ margin: 'auto' }}>
                <tr>
                  <th colSpan={2}>Location</th>
                </tr>
                <tr>
                  <td>Latitude :</td>
                  <td>{latitude}</td>
                </tr>
                <tr>
                  <td>Longitude :</td>
                  <td>{longitude}</td>
                </tr>
              </table>
            )}

            {showFormError && (
              <Typography
                variant="subtitle2"
                color="text.secondary"
                align="center"
                sx={{
                  bgcolor: 'error.main',
                  color: '#fff',
                  p: 1,
                  borderRadius: 2,
                  textAlign: 'center',
                  fontSize: 16,
                }}>
                Make sure you have checked all input fields for errors before
                you proceed.
              </Typography>
            )}

            <Button
              disabled={isSubmitting}
              variant="contained"
              onClick={handleSubmit}>
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <Modals
        title="Set Recording Person Information"
        show={modalShow}
        width="lg"
        form
        hideicon>
        <Stack direction="column" gap={3}>
          <TextField
            label="Name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={showModalError && nameError}
            helperText={showModalError && nameError}
          />

          <TextField
            label="Mobile Number"
            type="text"
            value={mobileNo}
            onChange={(e) => setMobileNo(e.target.value)}
            error={showModalError && mobileNoError}
            helperText={showModalError && mobileNoError}
          />

          <Autocomplete
            fullWidth
            disabled={regionList.length < 1}
            options={regionList}
            value={region}
            isOptionEqualToValue={(option, value) => value?.id === option?.id}
            onChange={(event, value) => {
              setRegion(value);
              setHq({});
              setPointTown({});
            }}
            getOptionLabel={(option) => option?.name || ''}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Select Region" />
            )}
          />

          <Autocomplete
            fullWidth
            disabled={!region?.id || hqList.length < 1}
            options={hqList}
            value={hq}
            isOptionEqualToValue={(option, value) => value?.id === option?.id}
            onChange={(event, value) => {
              setHq(value);
              setPointTown({});
            }}
            getOptionLabel={(option) => option?.name || ''}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Select HQ" />
            )}
          />

          <Autocomplete
            fullWidth
            disabled={!hq?.id || pointTownList.length < 1}
            options={pointTownList}
            value={pointTown}
            isOptionEqualToValue={(option, value) => value?.id === option?.id}
            onChange={(event, value) => {
              setPointTown(value);
            }}
            getOptionLabel={(option) => option?.name || ''}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Select Point/Town" />
            )}
          />
        </Stack>

        <Button
          fullWidth
          disabled={
            name && mobileNo && region?.name && hq?.name && pointTown?.name
              ? false
              : true
          }
          variant="contained"
          onClick={saveModal}
          sx={{ mt: 3 }}>
          Save
        </Button>
      </Modals>

      <RedirectDialog
        open={redirectShow}
        onClose={() => {
          setRedirectShow(false);
          navigate('/dealerinfo', { state: null });
        }}
        onAccept={() => {
          setRedirectShow(false);
          navigate('/tadaform', {
            state: {
              dealerSearchRef: createdId,
              travelDate: visitDate?.toDate(),
              reasonForTravel: 'Dealer search visit',
              region: region,
              headquarter: hq,
              point: pointTown,
              latitude: latitude,
              longitude: longitude,
              from: 'FORM',
            },
          });
        }}
      />
    </>
  );
}
