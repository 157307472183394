import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Link, Outlet, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

export default function Payroll() {
  const location = useLocation();
  const { role, Admin } = useAuth();

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={location?.pathname}>
          <Tab
            label="Ledger"
            value="/payroll-ledger"
            component={Link}
            to={'/payroll-ledger'}
          />
          <Tab
            label="Tickets"
            value="/payroll-ticket"
            component={Link}
            to={'/payroll-ticket'}
          />
          <Tab
            disabled={!['Super Admin', ...Admin].includes(role)}
            label="Report"
            value="/payroll-report"
            component={Link}
            to={'/payroll-report'}
          />
          <Tab
            // disabled
            disabled={!['Super Admin', ...Admin].includes(role)}
            label="Payslip"
            value="/payroll-payslip"
            component={Link}
            to={'/payroll-payslip'}
          />
        </Tabs>
      </Box>
      <Box pt={3}>
        <Outlet />
      </Box>
    </Box>
  );
}
