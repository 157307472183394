import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReportIcon from '@mui/icons-material/TableChartSharp';
import SeparateIcon from '@mui/icons-material/ViewListSharp';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useQuery } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import useAuth from '../../../../hooks/useAuth';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import roundToDigit from '../../../../utils/roundToDigit';

function CustomerProfileHeader({ cID, dam_claim }) {
  const axiosPrivate = useAxiosPrivate();
  const { role } = useAuth();
  const [activeIndex, setActiveIndex] = useState(-1);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.startsWith('/customers/report-ledger')) {
      setActiveIndex(0);
    }
    if (location.pathname.startsWith('/customers/ledger')) {
      setActiveIndex(1);
    }
    if (location.pathname.startsWith('/customers/transaction')) {
      setActiveIndex(2);
    }
    if (location.pathname.startsWith('/customers/stock')) {
      setActiveIndex(3);
    }
  }, [location.pathname]);

  const { data, isFetching } = useQuery('customerinfo', () => {
    return axiosPrivate.get(`/api/v1/customer/${cID}?populate=yes`);
  });

  const d = data?.data?.data;

  if (dam_claim) {
    if (isFetching) {
      return <Typography color="text.disabled">Loading...</Typography>;
    }
    return (
      <Typography color="primary.main">
        {d?.nameCode} - {d?.territory?.name}
      </Typography>
    );
  }

  const hasStock = Boolean(
    d?.customerType?.hasStock && !['SO/SR'].includes(role),
  );
  const hasLedger = Boolean(
    d?.customerType?.hasLedger &&
      !['ASM', 'RSM', 'DSM', 'SO/SR'].includes(role),
  );

  return (
    <Box>
      <Stack
        direction={{ lg: 'row' }}
        justifyContent="space-between"
        gap={1}
        mb={2}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="start"
          gap={1}>
          <IconButton
            component={Link}
            to="/customers"
            state={{ title: 'Customers' }}
            aria-label="go back">
            <ArrowBackIcon />
          </IconButton>

          {!isFetching ? (
            d?.nameCode ? (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="start"
                flexWrap="wrap"
                gap={1}
                divider={<Divider flexItem orientation="vertical" />}>
                <Typography fontWeight="bold">{d?.nameCode}</Typography>
                <Typography>
                  {d?.customerType?.customerType}
                  {d?.customerClass?.customerClass !== 'Default'
                    ? ` (${d.customerClass.customerClass})`
                    : ''}
                </Typography>
                <Typography>
                  {`${d?.territory?.territoryType?.territoryType} : ${d?.territory?.name}`}
                </Typography>
              </Stack>
            ) : (
              <Typography color="text.disabled">Unknown</Typography>
            )
          ) : (
            <Typography color="text.disabled">Loading...</Typography>
          )}
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
          gap={1}
          role="tablist">
          {hasLedger && (
            <Button
              component={Link}
              to={`/customers/report-ledger/${cID}`}
              role="tab"
              aria-selected={activeIndex < 2}
              variant={activeIndex < 2 ? 'contained' : 'outlined'}
              color="primary"
              size="small"
              sx={btnStyle}>
              Ledger
            </Button>
          )}

          <Button
            component={Link}
            to={`/customers/transaction/${cID}`}
            role="tab"
            aria-selected={activeIndex === 2}
            variant={activeIndex === 2 ? 'contained' : 'outlined'}
            color="primary"
            size="small"
            sx={btnStyle}>
            Transactions
          </Button>

          {hasStock && (
            <Button
              component={Link}
              to={`/customers/stock/${cID}`}
              role="tab"
              aria-selected={activeIndex === 3}
              variant={activeIndex === 3 ? 'contained' : 'outlined'}
              color="primary"
              size="small"
              sx={btnStyle}>
              Stock
            </Button>
          )}
        </Stack>
      </Stack>

      {activeIndex < 2 ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between">
          <Typography variant="h6" pb={1}>
            Ledger Balance:&nbsp;
            <span
              style={{
                fontWeight: 'bolder',
                color: d?.ledgerBalance > 0 ? 'green' : 'red',
              }}>
              {roundToDigit(d?.ledgerBalance)}
            </span>
          </Typography>

          <ToggleButtonGroup
            value={activeIndex}
            exclusive
            color="success"
            size="small"
            aria-label="Ledger view format">
            <ToggleButton
              value={0}
              component={Link}
              to={`/customers/report-ledger/${cID}`}
              aria-label="Report view"
              title="Report view">
              <ReportIcon />
            </ToggleButton>
            <ToggleButton
              value={1}
              component={Link}
              to={`/customers/ledger/${cID}`}
              aria-label="Separate view"
              title="Separate view">
              <SeparateIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      ) : null}
    </Box>
  );
}

export default CustomerProfileHeader;

const btnStyle = { borderRadius: '48px' };
