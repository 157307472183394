const ProductCategories = [
  'BISCUIT',
  'CHIPS',
  'CHANACHUR SNACKS',
  'DRINKS',
  'JUICE',
  'CHOCOLATE CANDY',
  'HOME CARE',
  'CAKE',
  'WAFER',
  'FRUIT GEL',
  'ICE CREAM',
  'MURI',
  'PICKLE CHUTNEY',
  'BREAD',
  'COMMODITY PRODUCT',
  'SAUCE',
  'SHEMAI',
  'INSTANT NOODLES',
  'CUP NOODLES',
  'STICK NOODLES',
  'BATTERY',
  'STATIONERY',
];

export default ProductCategories;
