import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Autocomplete,
  Button,
  Container,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useHeader from '../../hooks/useHeader';
import ActiveSwitch from '../../component/input/ActiveSwitch';

function ModifyLiftingPlan() {
  const navigate = useNavigate();
  const params = useParams();
  const [refetch] = useOutletContext();
  const axiosPrivate = useAxiosPrivate();
  const { setHeader } = useHeader();
  const { user, userId } = useAuth();

  const editId = params?.commitmentID;

  const [isLoading, setIsLoading] = React.useState(false);
  const [customerList, setCustomerList] = React.useState([]);
  const [customer, setCustomer] = React.useState({});
  const [timeStart, setTimeStart] = React.useState(
    dayjs().startOf('month').toDate(),
  );
  const [timeEnd, setTimeEnd] = React.useState(dayjs().endOf('month').toDate());
  const [commitmentAmount, setCommitmentAmount] = React.useState(0);
  const [switchDate, setSwitchDate] = React.useState(true);

  React.useEffect(() => {
    if (editId) {
      setHeader({ title: 'Edit Lifting Plan' });
    } else {
      setHeader({ title: 'Add Lifting Plan' });
    }
  }, [editId]);

  React.useEffect(() => {
    if (editId) {
      setIsLoading(true);
      axiosPrivate
        .get(`/api/v1/lifting-commitment/${editId}?populate=yes`)
        .then((res) => {
          setCustomer(res?.data?.data?.customer);
          setTimeStart(res?.data?.data?.timeStart);
          setTimeEnd(res?.data?.data?.timeEnd);
          setCommitmentAmount(res?.data?.data?.commitmentAmount);
        })
        .catch((error) => toast.warn(error?.response?.data?.message))
        .finally(() => setIsLoading(false));
    }
  }, [editId]);

  React.useEffect(() => {
    axiosPrivate
      .get(
        `/api/v1/customer?limit=none&isActive=true&sort=code&parentTerritory=${user?.employee?.territory?.id}`,
      )
      .then((res) => {
        setCustomerList(res?.data?.data);
      })
      .catch((error) => toast.warn(error?.response?.data?.message));
  }, [user?.employee?.territory?.id]);

  const handleSubmit = () => {
    setIsLoading(true);

    let method = 'post';
    let url = '/api/v1/lifting-commitment';
    const data = {
      employee: userId,
      customer: customer?.id,
      timeStart,
      timeEnd,
      commitmentAmount,
    };

    if (editId) {
      method = 'patch';
      url += `/${editId}`;
    }

    axiosPrivate({ method, url, data })
      .then((res) => {
        toast.success(res?.data?.message);
        refetch();
        navigate('/lifting-plan-details');
      })
      .catch((error) => toast.warn(error?.response?.data?.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <Container maxWidth="sm">
      <IconButton
        component={Link}
        to="/lifting-plan-details"
        aria-label="Go back"
        edge="start"
        sx={{ mb: 1 }}>
        <ArrowBackIcon />
      </IconButton>

      <Stack
        component={Paper}
        sx={{ px: 2, py: 3, gap: 3, alignItems: 'center' }}>
        <ActiveSwitch
          value={switchDate}
          setValue={setSwitchDate}
          primaryText="Month"
          secondaryText="Date"
          color="primary"
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {switchDate ? (
            <DatePicker
              label="Planned Month"
              disabled={isLoading}
              fullWidth
              inputFormat="MMMM YYYY"
              views={['month', 'year']}
              value={timeStart}
              onChange={(newValue) => {
                setTimeStart(dayjs(newValue).startOf('month').toDate());
                setTimeEnd(dayjs(newValue).endOf('month').toDate());
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          ) : (
            <Stack sx={{ flexDirection: { sm: 'row' }, gap: 2, width: 1 }}>
              <DatePicker
                label="Planned From"
                disabled={isLoading}
                inputFormat="DD/MM/YYYY"
                value={timeStart}
                onChange={(newValue) => setTimeStart(dayjs(newValue).toDate())}
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                label="Planned To"
                disabled={isLoading}
                inputFormat="DD/MM/YYYY"
                value={timeEnd}
                onChange={(newValue) => setTimeEnd(dayjs(newValue).toDate())}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
          )}
        </LocalizationProvider>

        <Autocomplete
          disabled={editId || isLoading || customerList?.length < 1}
          fullWidth
          options={customerList}
          value={customer}
          isOptionEqualToValue={(option, value) => value?.id === option?.id}
          onChange={(event, value) => setCustomer(value)}
          getOptionLabel={(option) => option?.nameCode || ''}
          renderInput={(params) => (
            <TextField {...params} label="Select Customer" />
          )}
        />

        <TextField
          disabled={isLoading}
          label="Planned Collection Amount"
          fullWidth
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">Tk</InputAdornment>,
          }}
          onWheel={(e) => e.target.blur()}
          value={commitmentAmount}
          onChange={(e) => setCommitmentAmount(e.target.value)}
        />

        <Button
          disabled={isLoading}
          fullWidth
          variant="contained"
          size="large"
          onClick={handleSubmit}>
          Submit
        </Button>
      </Stack>
    </Container>
  );
}

export default ModifyLiftingPlan;
