import React, { useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EmojiPeopleIcon from '@mui/icons-material/Hail';
import CancelIcon from '@mui/icons-material/HighlightOff';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { Badge, Chip, Stack, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { MultiSelectColumnFilter } from '../../component/Table/SelectColumnFilter';
import Table from '../../component/Table/Table';
import Title from '../../component/report/Title';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';

function EmployeeAttendance() {
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();

  const [filterDate, setFilterDate] = useState(dayjs());

  const [columns, setColumns] = useState([]);

  const { data, error, isError, isLoading } = useQuery(
    ['employee-attendance', filterDate],

    ({ queryKey: [key, filter] }) => {
      let start = dayjs().startOf('month').toISOString();
      let end = dayjs().endOf('month').toISOString();

      const reportDate = dayjs(filter);
      if (reportDate.isValid) {
        const col = [
          {
            Header: 'Region',
            accessor: 'region.name',
          },
          {
            Header: 'HQ',
            accessor: 'headquarter.name',
          },
          {
            Header: 'Point/Town',
            accessor: 'point.name',
          },
          {
            Header: 'Employee',
            accessor: 'employee.nameCode',
          },
          {
            Header: 'Role',
            accessor: 'employee.role.employeeRole',
            Filter: MultiSelectColumnFilter,
            filter: 'includesSome',
          },
          {
            Header: 'Attendance',
            accessor: 'totalAttendance',
          },
          {
            Header: 'Work With SO/SR',
            accessor: 'totalWorkWith',
          },
          {
            Header: 'Ex. Dealer Visit',
            accessor: 'totalDealarVisits',
          },
          {
            Header: 'Dealer Search',
            accessor: 'totalDealarSearch',
          },
          {
            Header: 'Meeting Purpose',
            accessor: 'totalMeetings',
          },
        ];

        const daysInMonth = reportDate.daysInMonth();
        let i = 1;
        while (i <= daysInMonth) {
          const day = reportDate.date(i);
          const dayKey = day.format('YYYY-MM-DD');

          col.push({
            Header: `Day ${day.format('D')}`,
            accessor: `dates.${dayKey}.approvedDA`,
            Filter: () => <span>{day.format('ddd')}</span>,
            Cell: ({ row, value }) => {
              const info = row?.original?.dates?.[dayKey];

              const isPresent = Boolean(info?.approvedDA > 0);
              const isMeeting = Boolean(info?.meetings > 0);
              const workWithCount = info?.workWith?.length || 0;
              const dealarVisitsCount = info?.dealarVisits?.length || 0;
              const dealarSearchCount = info?.dealarSearch?.length || 0;

              if (
                !isPresent &&
                !isMeeting &&
                !workWithCount &&
                !dealarVisitsCount &&
                !dealarSearchCount
              ) {
                return (
                  <div title="No approved DA found">
                    <CancelIcon color="error" />
                  </div>
                );
              }

              let presenceTitle = '';
              let meetingsTitle = '';
              let workWithTitle = `Worked with: ${workWithCount}\n`;
              let dealarVisitsTitle = `Existing dealers visited: ${dealarVisitsCount}\n`;
              let dealarSearchTitle = `Dealer searched: ${dealarSearchCount}\n`;

              if (isPresent) {
                presenceTitle = `Approved DA: ${info.approvedDA} TK`;
              }

              if (isMeeting) {
                meetingsTitle = `Travelled for meeting purpose: ${info?.meetings}`;
              }

              if (workWithCount) {
                info.workWith.forEach((d, i) => {
                  workWithTitle += `\n${i + 1}.\n`;
                  workWithTitle += `${d?.customer?.nameCode || '?'}\n`;
                  workWithTitle += `Point/Town: ${d?.pointTown?.name || '?'}\n`;
                  workWithTitle += `SO/SR: ${d?.srName?.nameCode || '?'}\n`;
                  workWithTitle += `Working time: ${d?.workingTime || '?'}\n`;
                  workWithTitle += `Order quantity: ${
                    d?.orderQuantity || '?'
                  }\n`;
                });
              }

              if (dealarVisitsCount) {
                info.dealarVisits.forEach((d, i) => {
                  dealarVisitsTitle += `\n${i + 1}.\n`;
                  dealarVisitsTitle += `${d?.customer?.nameCode || '?'}\n`;
                  dealarVisitsTitle += `Point/Town: ${
                    d?.pointTown?.name || '?'
                  }\n`;
                });
              }

              if (dealarSearchCount) {
                info.dealarSearch.forEach((d, i) => {
                  dealarSearchTitle += `\n${i + 1}.\n`;
                  dealarSearchTitle += `Name: ${d?.dealerName || '?'}\n`;
                  dealarSearchTitle += `Type: ${d?.dealerType || '?'}\n`;
                  dealarSearchTitle += `Phone: ${d?.primaryNo || '?'}\n`;
                  dealarSearchTitle += `Point/Town: ${
                    d?.pointTown?.name || '?'
                  }\n`;
                });
              }

              return (
                <Stack direction="row" sx={{ gap: 0.5, alignItems: 'center' }}>
                  {isPresent ? (
                    <div title={presenceTitle}>
                      <CheckCircleIcon color="success" />
                    </div>
                  ) : null}
                  {workWithCount ? (
                    <Badge
                      badgeContent={workWithCount > 1 ? workWithCount : 0}
                      {...badgeProps}>
                      <div title={workWithTitle}>
                        <SupervisorAccountIcon color="secondary" />
                      </div>
                    </Badge>
                  ) : null}
                  {dealarVisitsCount ? (
                    <Badge
                      badgeContent={
                        dealarVisitsCount > 1 ? dealarVisitsCount : 0
                      }
                      {...badgeProps}>
                      <div title={dealarVisitsTitle}>
                        <WarehouseIcon color="warning" />
                      </div>
                    </Badge>
                  ) : null}
                  {dealarSearchCount ? (
                    <Badge
                      badgeContent={
                        dealarSearchCount > 1 ? dealarSearchCount : 0
                      }
                      {...badgeProps}>
                      <div title={dealarSearchTitle}>
                        <TravelExploreIcon color="info" />
                      </div>
                    </Badge>
                  ) : null}
                  {isMeeting ? (
                    <Badge
                      badgeContent={info?.meetings > 1 ? info?.meetings : 0}
                      {...badgeProps}>
                      <div title={meetingsTitle}>
                        <EmojiPeopleIcon color="action" />
                      </div>
                    </Badge>
                  ) : null}
                </Stack>
              );
            },
          });

          i++;
        }

        setColumns(col);
        start = reportDate.startOf('month').toISOString();
        end = reportDate.endOf('month').toISOString();
      }

      return axiosPrivate
        .get(
          `/api/v1/report/employee-attendance?startDate=${start}&endDate=${end}`,
        )
        .then((res) => res?.data?.data);
    },

    { refetchInterval: 300000 },
  );

  if (isError) {
    expired(error);
  }

  return (
    <>
      <Title title="Employee Attendance">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Select Month"
            inputFormat="MMM YYYY"
            views={['month', 'year']}
            value={filterDate}
            onChange={(newValue) => setFilterDate(newValue)}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </LocalizationProvider>
      </Title>

      <Table
        columns={columns}
        data={data || []}
        isLoading={isLoading}
        hideAddIcon
        fullHeight
        noWrap
      />

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="start"
        flexWrap="wrap"
        gap={2}
        mt={2}>
        <Typography variant="subtitle1">Icon meaning:</Typography>
        <Chip
          icon={<CheckCircleIcon />}
          label="Present"
          color="success"
          variant="outlined"
          size="small"
        />
        <Chip
          icon={<CancelIcon />}
          label="Absent"
          color="error"
          variant="outlined"
          size="small"
        />
        <Chip
          icon={<SupervisorAccountIcon />}
          label="Work With SO/SR"
          color="secondary"
          variant="outlined"
          size="small"
        />
        <Chip
          icon={<WarehouseIcon />}
          label="Ex. Dealer Visit"
          color="warning"
          variant="outlined"
          size="small"
        />
        <Chip
          icon={<TravelExploreIcon />}
          label="Dealer Search"
          color="info"
          variant="outlined"
          size="small"
        />
        <Chip
          icon={<EmojiPeopleIcon />}
          label="Meeting Purpose"
          color="default"
          variant="outlined"
          size="small"
        />
      </Stack>
    </>
  );
}

const badgeProps = {
  overlap: 'circular',
  color: 'error',
  sx: {
    '.MuiBadge-badge': {
      minWidth: '12px',
      height: '12px',
      padding: '0px 2px',
      fontSize: '10px',
    },
  },
};

export default EmployeeAttendance;
