import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  List,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContainer, InnerContainer } from '../../assets/style/auth';
import FloatCIrcle from '../../component/FloatCIrcle';
import ProfileCard from '../../component/ProfileCard';
import Logo from '../../component/auth/Logo';
import CustomTabs from '../../component/tabs/CustomTabs';
import useAuth from '../../hooks/useAuth';
import useAxios from '../../hooks/useAxios';

// auth
const LOGIN_URL = '/api/v1/auth/login';

export default function Login() {
  const axios = useAxios();
  const { setAuth, setRole, setUserId, setAvatar, setName, setUser, Admin } =
    useAuth();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    if (showPassword === false) {
      setShowPassword(true);
    } else {
      setShowPassword(false);
    }
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    const { emailOrPhone, password } = data;
    axios
      .post(
        LOGIN_URL,
        { emailOrPhone, password, remember: true },
        { withCredentials: true },
      )
      .then((response) => {
        // console.log(response);
        const accessToken = response?.data?.accessToken;
        const refreshToken = response?.data?.refreshToken;
        const refreshTokenExpires = response?.data?.refreshTokenExpires;

        const { id, email, phone } = response?.data?.data;
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('refreshTokenExpires', refreshTokenExpires);
        setAuth({ data, accessToken, refreshToken, id, email, phone });
        // Send user API request
        axios
          .get('/api/v1/user/me', {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then((response) => {
            const userRole = response?.data?.data?.employee?.role?.employeeRole;
            const avatar = response?.data?.data?.employee?.avatar;
            const id = response?.data?.data?.employee?.id;
            const name = response?.data?.data?.employee?.name;
            const email = response?.data?.data?.email;
            const phone = response?.data?.data?.phone;
            const territory = response?.data?.data?.employee?.territory?.name;
            const territoryCode =
              response?.data?.data?.employee?.territory?.code;
            const territoryType =
              response?.data?.data?.employee?.territory?.territoryType
                ?.territoryType;

            setRole(userRole);
            setAvatar(avatar);
            setUserId(id);
            setName(name);
            setUser(response?.data?.data);
            // localStorage.setItem('phone', phone);
            const userObj = {
              refreshToken: refreshToken,
              refreshExpire: new Date(refreshTokenExpires).getTime(),
              avatar: avatar,
              name: name,
              email: email,
              role: userRole,
              territory: territory,
              territoryCode: territoryCode,
              territoryType: territoryType,
            };

            const existingObj =
              JSON.parse(localStorage.getItem('LoginInfo')) || [];

            // Update the array
            const updatedObj = { ...existingObj };
            updatedObj[phone] = userObj;

            localStorage.setItem('LoginInfo', JSON.stringify(updatedObj));

            if (userRole === 'Default') {
              navigate('/welcome');
            } else if (['Super Admin', ...Admin].includes(userRole)) {
              navigate('/');
            } else if (['ASM', 'RSM', 'DSM', 'SO/SR'].includes(userRole)) {
              navigate('/');
            } else if (userRole === 'Accounts' || userRole === 'DO') {
              navigate('/collections');
            } else if (userRole === 'Factory') {
              navigate('/factory');
            } else {
              navigate('/');
            }
          })
          .catch((error) => {
            if (error.message === 'Network Error') {
              toast.warn(error.message);
            } else {
              toast.warn(error?.response?.data?.message);
            }
          });
      })
      .catch((error) => {
        if (error.message === 'Network Error') {
          toast.warn(error.message);
        } else {
          toast.warn(error?.response?.data?.message);
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <AuthContainer>
        <FloatCIrcle />
        <Box mx={2}>
          <Logo />
          <InnerContainer>
            <CustomTabs
              labels={['Choose Account', 'LogIn']}
              bodys={[
                <Box sx={{ maxHeight: 200, overflowY: 'auto' }}>
                  <List dense>
                    <ProfileCard login />
                  </List>
                </Box>,
                <form onSubmit={handleSubmit(onSubmit)}>
                  <TextField
                    sx={{ mt: 2 }}
                    type="text"
                    label="Email/Phone"
                    variant="standard"
                    fullWidth
                    {...register('emailOrPhone', {
                      required: 'Required field',
                    })}
                    error={!!errors?.emailOrPhone}
                    helperText={
                      errors?.emailOrPhone ? errors.emailOrPhone.message : null
                    }
                  />
                  <FormControl
                    variant="standard"
                    sx={{ mt: 2 }}
                    fullWidth
                    error={!!errors.password}>
                    <InputLabel>Password</InputLabel>
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      {...register('password', {
                        required: 'Required field',
                      })}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}>
                            {showPassword ? (
                              <VisibilityOff
                                color={!!errors.password ? 'error' : 'grey'}
                              />
                            ) : (
                              <Visibility
                                color={!!errors.password ? 'error' : 'grey'}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {!!errors.password ? (
                      <FormHelperText>Required field</FormHelperText>
                    ) : null}
                  </FormControl>

                  <Link to="/forgot-password" className="forgot_text">
                    Forgot Password
                  </Link>

                  <Button
                    disabled={isLoading}
                    type="submit"
                    variant="contained"
                    fullWidth
                    sx={{ borderRadius: '40px' }}>
                    Login
                  </Button>
                </form>,
              ]}
            />

            <Typography
              component={Link}
              to="/signup"
              color="primary"
              display="block"
              pt={2}>
              Create a new account &rarr;
            </Typography>
          </InnerContainer>
        </Box>
      </AuthContainer>
    </>
  );
}
