import { Grid, Switch, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import Button from '../../../component/Button';
import Modals from '../../../component/Modals';
import Table from '../../../component/Table/Table';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useExpiredSession from '../../../hooks/useExpiredSession';

export default function TerritoryType() {
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const [addModalShow, setAddModalShow] = React.useState(false);

  // form states
  const [checked, setChecked] = useState(true);
  const [territoryType, setTerritoryType] = useState('');
  const [codePrefix, setCodePrefix] = useState('');
  const [description, setDescription] = useState('');
  // form states

  // get TerritryType Data
  const { data, isLoading, isFetching, isError, error, refetch } = useQuery(
    'territryType',
    () => axiosPrivate.get('/api/v1/territory/type?sort=rank'),
  );

  const COLUMNS = useMemo(
    () => [
      {
        Header: 'Rank',
        accessor: 'rank',
        Filter: () => null,
      },
      /* {
        Header: 'Rank',
        id: 'selection2',
        Cell: ({ row }) => {
          const handleUpRank = (id) => {
            axiosPrivate
              .patch(`/api/v1/territory/type/${id}/rank-up`)
              .then((res) => {
                toast.success(res.data.message);
                refetch();
              })
              .catch((error) => {
                toast.warn(error?.response?.data?.message);
              });
          };
          const handleDownRank = (id) => {
            axiosPrivate
              .patch(`/api/v1/territory/type/${id}/rank-down`)
              .then((res) => {
                toast.success(res.data.message);
                refetch();
              })
              .catch((error) => {
                toast.warn(error?.response?.data?.message);
              });
          };
          return (
            <>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="subtitle2">{row.original.rank}</Typography>
                <IconButton
                  onClick={() => handleUpRank(row.original.id)}
                  className="Table_icon_btn">
                  <ArrowUpwardIcon color="success" />
                </IconButton>
                <IconButton
                  onClick={() => handleDownRank(row.original.id)}
                  className="Table_icon_btn">
                  <ArrowDownwardIcon color="error" />
                </IconButton>
              </Stack>
            </>
          );
        },
      }, */
      {
        Header: 'Location',
        accessor: 'territoryType',
        disableFilters: true,
      },
      {
        Header: 'Code Prefix',
        accessor: 'codePrefix',
        disableFilters: true,
      },
      {
        Header: 'Description',
        accessor: 'description',
        disableFilters: true,
      },
      /* {
        Header: 'Active',
        accessor: 'isActive',
        Cell: ({ value }) => {
          if (value) {
            return <CheckCircleIcon color="success" />;
          }
          return <HighlightOffIcon color="error" />;
        },
        // disableFilters: true,
        Filter: SelectColumnFilterBool,
      }, */
      /* {
        Header: 'Action',
        id: 'Action',
        Cell: ({ row }) => {
          const [first, setfirst] = React.useState({});
          const [editModalShow, setEditModalShow] = React.useState(false);
          const handleEditShow = (row) => {
            setfirst(row);
            setEditModalShow(true);
          };

          return (
            <>
              <Stack direction="row" alignItems="center" spacing={2}>
                <IconButton
                  onClick={() => handleEditShow(row.original)}
                  className="Table_icon_btn">
                  <BorderColorIcon />
                </IconButton>
              </Stack>
              {editModalShow && (
                <EditModal
                  editModalShow={editModalShow}
                  first={first}
                  setEditModalShow={setEditModalShow}
                  refetch={refetch}
                />
              )}
            </>
          );
        },
        disableFilters: true,
      }, */
    ],
    [],
  );

  // create Territry Type
  const handleSubmit = async () => {
    axiosPrivate
      .post('/api/v1/territory/type', {
        isActive: checked,
        territoryType,
        codePrefix,
        description,
      })
      .then((res) => {
        toast.success(res?.data?.message);
        setAddModalShow(false);
        refetch();
      })
      .catch((error) => {
        toast.warn(error.response.data.message);
      });
  };

  if (isError) {
    expired(error);
  }
  return (
    <>
      <Modals
        title="Add Location Hierarchy"
        show={addModalShow}
        onSave={() => setAddModalShow(false)}
        onHide={() => setAddModalShow(false)}
        form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center">
              <Typography>{checked ? 'Active' : 'Inactive'}</Typography>
              <Switch checked={checked} onChange={() => setChecked(!checked)} />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              type="text"
              id="outlined-basic"
              label="Location Hierarchy"
              variant="outlined"
              fullWidth
              value={territoryType}
              onChange={(e) => setTerritoryType(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              type="text"
              id="outlined-basic"
              label="Code Prefix"
              variant="outlined"
              fullWidth
              value={codePrefix}
              onChange={(e) => setCodePrefix(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="text"
              id="outlined-basic"
              label="Description"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="end" pt={3}>
          <Button type="submit" text="Save" onClick={handleSubmit} />
        </Stack>
      </Modals>

      <Table
        columns={COLUMNS}
        data={data?.data?.data || [{}]}
        filename="Territory_Types"
        addButtonTooltip="Add new location type"
        isLoading={isLoading || isFetching}
        onAddButtonClick={() => setAddModalShow(true)}
        hideAddIcon
      />
    </>
  );
}
