import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import HeadingTitle from '../../../component/HeadingTitle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Link } from 'react-scroll';
import ActiveSwitch from '../../../component/input/ActiveSwitch';

export default function ModifiedEmployees() {
  const [refetch] = useOutletContext();
  const params = useParams();
  const navigate = useNavigate();
  const id = params?.employeeID;
  const axiosPrivate = useAxiosPrivate();
  // ********_____form state_____****************
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [dateOfJoin, setDateOfJoin] = useState(null);
  const [dateOfResign, setDateOfResign] = useState(null);
  const [nationalID, setNationalID] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [reference, setReference] = useState('');
  const [referralNumber, setReferralNumber] = useState('');
  const [presentAddress, setPresentAddress] = useState('');
  const [permanentAddress, setPermanentAddress] = useState('');
  const [educationalQualification, setEducationalQualification] = useState('');
  const [isEducationVerified, setIsEducationVerified] = useState(false);
  const [checked, setChecked] = React.useState();
  const [socialMedia, setSocialMedia] = useState('');
  const [transactionMode, setTransactionMode] = useState('');
  const [bankName, setBankName] = useState('');
  const [bankAccountNumber, setBankAccountNumber] = useState('');
  const [salaryBasic, setSalaryBasic] = useState('');
  const [salaryTravel, setSalaryTravel] = useState('');
  const [salaryMobile, setSalaryMobile] = useState('');
  const [salaryInternet, setSalaryInternet] = useState('');
  const [minimumCollection, setMinimumCollection] = useState('');
  const [salaryPercent, setSalaryPercent] = useState('');
  const [travelAllowancePercent, setTravelAllowancePercent] = useState('');
  const [employeeRole, setEmployeeRole] = React.useState({});

  const [territory, setTerritory] = useState({});
  const [tID, setTID] = useState(employeeRole?.territoryType);
  //   selection states
  const [territoyData, setTerritoyData] = useState([]);
  const [employeeRoleData, setEmployeeRoleData] = React.useState([]);
  // ********_____form state_____***************
  const [permissions, setPermissions] = useState({});

  // get edit Employees data by id
  React.useEffect(() => {
    if (id) {
      axiosPrivate
        .get(`/api/v1/employee/${id}?populate=yes`)
        .then((res) => {
          const data = res?.data?.data;
          setPermissions(data?.role?.permissions);
          setName(data?.name);
          setCode(data?.code);
          setEmail(data?.email);
          setDateOfBirth(data?.dateOfBirth || null);
          setDateOfJoin(data?.dateOfJoin || null);
          setDateOfResign(data?.dateOfResign || null);
          setNationalID(data?.nationalID);
          setContactNumber(data?.contactNumber);
          setReference(data?.reference);
          setReferralNumber(data?.referralNumber);
          setPresentAddress(data?.presentAddress);
          setPermanentAddress(data?.permanentAddress);
          setEducationalQualification(data?.educationalQualification);
          setIsEducationVerified(data?.isEducationVerified);
          setChecked(data?.isActive);
          setSocialMedia(data?.socialMedia);
          setTransactionMode(data?.transactionMode);
          setBankName(data?.bankName);
          setBankAccountNumber(data?.bankAccountNumber);
          setSalaryBasic(data?.salaryBasic);
          setSalaryTravel(data?.salaryTravel);
          setSalaryMobile(data?.salaryMobile);
          setSalaryInternet(data?.salaryInternet);
          setMinimumCollection(data?.minimumCollection);
          setSalaryPercent(data?.salaryPercent);
          setTravelAllowancePercent(data?.travelAllowancePercent);
          setEmployeeRole({
            employeeRole: data?.role?.employeeRole,
            territoryType: data?.role?.territoryType,
            attendanceMethod: data?.role?.attendanceMethod,
          });
          setTerritory({
            name: data?.territory?.name,
          });
        })
        .catch((error) => console.warn(error));
    } else {
      setName('');
      setCode('');
      setEmail();
      setDateOfBirth(null);
      setDateOfJoin(null);
      setDateOfResign(null);
      setNationalID();
      setContactNumber();
      setReference();
      setReferralNumber();
      setPresentAddress();
      setPermanentAddress();
      setEducationalQualification();
      setIsEducationVerified(false);
      setChecked();
      setSocialMedia();
      setTransactionMode();
      setBankName();
      setBankAccountNumber();
      setSalaryBasic();
      setSalaryTravel();
      setSalaryMobile();
      setSalaryInternet();
      setMinimumCollection();
      setSalaryPercent();
      setTravelAllowancePercent();
      setEmployeeRole();
      setTerritory();
      setTID();
    }
  }, [id]);

  // get Employee Role
  React.useEffect(() => {
    axiosPrivate
      .get('/api/v1/employee/role?populate=yes&sort=rank&isActive=true')
      .then((res) => {
        setEmployeeRoleData(res?.data?.data);
      })
      .catch((error) => console.warn(error));
  }, []);

  // get Employee Role

  // get All territory data
  React.useEffect(() => {
    axiosPrivate
      .get(`/api/v1/territory?limit=none&territoryType=${tID}`)
      .then((res) => {
        setTerritoyData(res?.data?.data);
      })
      .catch((error) => console.warn(error));
  }, [tID]);
  //  get All territory data

  // update or add all employee data
  const handleSubmit = () => {
    if (territory.name === undefined) {
      toast.warn('Location undefined!');
    } else {
      axiosPrivate({
        method: id ? 'patch' : 'post',
        url: id ? `/api/v1/employee/${id}` : '/api/v1/employee',
        data: {
          isActive: checked,
          isEducationVerified,
          code,
          dateOfBirth,
          dateOfJoin,
          dateOfResign,
          name,
          email,
          educationalQualification,
          contactNumber,
          reference,
          referralNumber,
          socialMedia,
          transactionMode,
          bankName,
          bankAccountNumber,
          salaryBasic,
          salaryTravel,
          salaryMobile,
          salaryInternet,
          minimumCollection,
          salaryPercent,
          travelAllowancePercent,
          presentAddress,
          permanentAddress,
          nationalID,
          role: employeeRole.id,
          territory: territory.id,
        },
      })
        .then((res) => {
          if (res) {
            if (id) {
              toast.success(
                <div>
                  Employee <b>"{res?.data?.data?.name}"</b> Updated Successfully
                </div>,
              );
              navigate('/employees');
              refetch();
            } else {
              toast.success(
                <div>
                  Employee <b>"{res?.data?.data?.name}"</b> Added Successfully
                </div>,
              );
              navigate('/employees');
              refetch();
            }
          }
        })
        .catch((error) => {
          toast.warn(error.response.data.message);
        });
    }
  };

  return (
    <>
      <Box bgcolor="white.main" p={3} borderRadius={2}>
        <HeadingTitle>{id ? 'Edit' : 'Add'} Employee</HeadingTitle>
        {/* -------basic information---------- */}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center">
              <Typography fontWeight="bold">Employee Status:</Typography>
              &emsp;
              <FormControlLabel
                label={checked ? 'Active' : 'Inactive'}
                control={
                  <Switch
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                  />
                }
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <HeadingTitle variant={1}>Basic Info</HeadingTitle>
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <TextField
              type="text"
              label="Name"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <TextField
              type="text"
              label="Code"
              fullWidth
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={6} xl={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Date of Birth"
                inputFormat="DD/MM/YYYY"
                value={dateOfBirth}
                disableFuture
                onChange={(newValue) => setDateOfBirth(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <TextField
              type="number"
              onWheel={(e) => e.target.blur()}
              label="NID"
              fullWidth
              value={nationalID}
              onChange={(e) => setNationalID(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={6} xl={3}>
            <TextField
              type="text"
              label="Present Address"
              fullWidth
              value={presentAddress}
              onChange={(e) => setPresentAddress(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={6} xl={3}>
            <TextField
              type="text"
              label="Permanent Address"
              fullWidth
              value={permanentAddress}
              onChange={(e) => setPermanentAddress(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Joining Date"
                disableFuture
                inputFormat="DD/MM/YYYY"
                value={dateOfJoin}
                onChange={(newValue) => setDateOfJoin(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Date of Leave"
                disableFuture
                inputFormat="DD/MM/YYYY"
                value={dateOfResign}
                onChange={(newValue) => setDateOfResign(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <Autocomplete
              disableClearable
              disablePortal
              fullWidth
              options={employeeRoleData}
              getOptionLabel={(option) => option?.employeeRole || ''}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              onChange={(event, newValue) => {
                setEmployeeRole(newValue);
                setTID(newValue?.territoryType?.id);
                setTerritory({});
              }}
              value={employeeRole}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Employee Role" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <Autocomplete
              disabled={
                employeeRole !== '' || employeeRoleData?.length < 0
                  ? false
                  : true
              }
              disableClearable
              autoHighlight
              fullWidth
              options={territoyData}
              getOptionLabel={(option) => option.nameCode || ''}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              onChange={(event, newValue) => setTerritory(newValue)}
              value={territory}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Location" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <TextField
              type="text"
              label="Educational Qualification"
              fullWidth
              value={educationalQualification}
              onChange={(e) => setEducationalQualification(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <Stack
              direction="row"
              height={1}
              alignItems="center"
              justifyContent="space-between">
              <Typography>Qualification :</Typography>

              <ActiveSwitch
                primaryText="Verified"
                secondaryText="Not verified"
                value={isEducationVerified}
                setValue={setIsEducationVerified}
              />
            </Stack>
          </Grid>
        </Grid>
        {/* -------contact Info---------- */}
        <Grid container spacing={3} mt={2}>
          <Grid item xs={12}>
            <HeadingTitle variant={1}>Contact Info</HeadingTitle>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              type="email"
              label="Email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <TextField
              type="number"
              onWheel={(e) => e.target.blur()}
              label="Mobile No."
              fullWidth
              value={contactNumber}
              onChange={(e) => setContactNumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              type="text"
              label="Social"
              fullWidth
              value={socialMedia}
              onChange={(e) => setSocialMedia(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <TextField
              type="text"
              label="Reference"
              fullWidth
              value={reference}
              onChange={(e) => setReference(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <TextField
              type="number"
              onWheel={(e) => e.target.blur()}
              label="Referral Mobile No."
              fullWidth
              value={referralNumber}
              onChange={(e) => setReferralNumber(e.target.value)}
            />
          </Grid>
        </Grid>

        {/* -------Salary Info---------- */}
        <Grid container spacing={3} mt={2}>
          <Grid item xs={12}>
            <HeadingTitle variant={1}>Salary Info</HeadingTitle>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              type="text"
              label="Bank Name"
              fullWidth
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              type="text"
              label="Account Number"
              fullWidth
              value={bankAccountNumber}
              onChange={(e) => setBankAccountNumber(e.target.value)}
            />
          </Grid>

          {employeeRole?.attendanceMethod === 'representative' ? (
            <>
              <Grid item xs={12} md={6}>
                <TextField
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  label="Collection Target"
                  fullWidth
                  value={minimumCollection}
                  onChange={(e) => setMinimumCollection(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  type="number"
                  inputProps={{ max: 10 }}
                  onWheel={(e) => e.target.blur()}
                  label="Salary Percent of Collection"
                  fullWidth
                  value={salaryPercent}
                  onChange={(e) => setSalaryPercent(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  type="number"
                  inputProps={{ max: 10 }}
                  onWheel={(e) => e.target.blur()}
                  label="TA Percent of Collection"
                  fullWidth
                  value={travelAllowancePercent}
                  onChange={(e) => setTravelAllowancePercent(e.target.value)}
                />
              </Grid>
            </>
          ) : null}

          <Grid item xs={12} md={6}>
            <TextField
              type="number"
              onWheel={(e) => e.target.blur()}
              label="Salary(Basic)"
              fullWidth
              value={salaryBasic}
              onChange={(e) => setSalaryBasic(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              type="number"
              onWheel={(e) => e.target.blur()}
              label="Salary(Tada)"
              fullWidth
              value={salaryTravel}
              onChange={(e) => setSalaryTravel(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              type="number"
              onWheel={(e) => e.target.blur()}
              label="Salary(Mobile)"
              fullWidth
              value={salaryMobile}
              onChange={(e) => setSalaryMobile(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              type="number"
              onWheel={(e) => e.target.blur()}
              label="Salary(Net)"
              fullWidth
              value={salaryInternet}
              onChange={(e) => setSalaryInternet(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Transaction Mode</InputLabel>
              <Select
                label="Transaction Mode"
                value={transactionMode}
                onChange={(e) => setTransactionMode(e.target.value)}>
                <MenuItem value="ongoing">Ongoing</MenuItem>
                <MenuItem value="settled">Settled</MenuItem>
                <MenuItem value="unsettled">Unsettled</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <br />
        <br />

        {id ? <PermissionsTable permissions={permissions} /> : null}

        <Stack direction="row" gap={2} justifyContent="flex-end" pt={3}>
          <Button
            variant="contained"
            color="error"
            sx={{ px: 3 }}
            onClick={() => navigate('/employees')}>
            Cancel
          </Button>
          <Button variant="contained" sx={{ px: 3 }} onClick={handleSubmit}>
            Save
          </Button>
        </Stack>
      </Box>
    </>
  );
}

function PermissionsTable({ permissions }) {
  const [show, setShow] = useState(false);
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        name="PermissionsDiv">
        <HeadingTitle>Permissions</HeadingTitle>
        <Link
          style={{ cursor: 'pointer' }}
          to="PermissionsDiv"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}>
          <IconButton
            sx={{
              borderRadius: 0,
              px: 2,
              border: (theme) => `1px solid ${theme.palette.primary.main}50`,
              color: (theme) => theme.palette.primary.main,
            }}
            onClick={() => setShow(!show)}>
            {show ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        </Link>
      </Stack>
      {show ? (
        <TableContainer sx={{ mt: 3 }}>
          <Table
            sx={{
              width: { md: 1 / 3, xs: 1 },
              tr: {
                th: {
                  p: 1,
                  borderBottom: (theme) =>
                    `1px solid ${theme.palette.primary.main}34`,
                  fontWeight: 600,
                },
                td: { p: 1 },
                borderBottom: (theme) =>
                  `1px solid ${theme.palette.primary.main}34`,
              },
            }}>
            <TableRow>
              <TableCell component="th">User</TableCell>
              <TableCell component="th">:</TableCell>
              <TableCell>{permissions?.users}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Employees</TableCell>
              <TableCell component="th">:</TableCell>
              <TableCell>{permissions?.employees}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Employee Roles</TableCell>
              <TableCell component="th">:</TableCell>
              <TableCell>{permissions?.employeeRoles}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Transactions</TableCell>
              <TableCell component="th">:</TableCell>
              <TableCell>{permissions?.transactions}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Transaction Types</TableCell>
              <TableCell component="th">:</TableCell>
              <TableCell>{permissions?.transactionTypes}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Territories</TableCell>
              <TableCell component="th">:</TableCell>
              <TableCell>{permissions?.territories}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Territory Types</TableCell>
              <TableCell component="th">:</TableCell>
              <TableCell>{permissions?.territoryTypes}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Stocks</TableCell>
              <TableCell component="th">:</TableCell>
              <TableCell>{permissions?.stocks}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Reports</TableCell>
              <TableCell component="th">:</TableCell>
              <TableCell>{permissions?.reports}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Products</TableCell>
              <TableCell component="th">:</TableCell>
              <TableCell>{permissions?.products}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Customers</TableCell>
              <TableCell component="th">:</TableCell>
              <TableCell>{permissions?.customers}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Customer Types</TableCell>
              <TableCell component="th">:</TableCell>
              <TableCell>{permissions?.customerTypes}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Customer Classes</TableCell>
              <TableCell component="th">:</TableCell>
              <TableCell>{permissions?.customerClasses}</TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      ) : null}
    </>
  );
}
