const CompanyNames = [
  'RIDISHA',
  'DANISH',
  'OLYMPIC',
  'HAQUE',
  'GOLD MARK',
  'ROMANIA',
  'KOOKIEJAR',
  'COCOLA',
  'CARE NUTRITION',
  'GREEN 9',
  'NEXT FOOD',
  'IFAD',
  'NICE FOOD',
  'MASUD GROUP',
  'ALIBABA ',
  'BENGAL',
  'BONOFUL',
  'KISHWAN',
  'AKIJ',
  'DEKKO',
  'ACI FOODS',
  'STAR LINE',
  'NEW LIFE',
  'BD FOOD',
  'ARKU FOODS',
  'FRESH',
  'PRAN',
  'PUSHTI',
  'BENGAL',
  'PROME',
  'SHAD',
  'PRINCE',
  'QUANTUM',
  'WELL FOOD',
  'FINE FOOD',
  'VEGAN FOOD',
  'AL MANAR AGRO & FOODS',
  'NMC FOOD ',
  'SMC',
  'FULKOLI',
  'JABED GROUP',
  'SIX SEASONS',
  'ENA FOOD',
  'ALIN FOOD',
  'TK FOOD',
];

export default CompanyNames;
