import React, { useEffect, useMemo, useState } from 'react';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
} from '@mui/material';
import dayjs from 'dayjs';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmModal from '../../../../component/ConfirmModal';
import TableSkeleton from '../../../../component/Skeleton/TableSkeleton';
import {
  DateRangeColumnFilter,
  MultiSelectColumnFilter,
} from '../../../../component/Table/SelectColumnFilter';
import Table from '../../../../component/Table/Table';
import useAuth from '../../../../hooks/useAuth';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import useExpiredSession from '../../../../hooks/useExpiredSession';
import StockExpandRow from '../../../PhysicalStocks/ExpandRow';
import CustomerProfileHeader from '../CustomerProfileHeader/CustomerProfileHeader';

function CustomerStock() {
  const { role } = useAuth();
  const param = useParams();
  const cID = param?.customerID;
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addModalShow, setAddModalShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  useEffect(() => {
    setIsLoading(true);
    axiosPrivate
      .get(
        `/api/v1/stock?populate=yes&limit=none&sort=-recordedAt&customer=${cID}`,
      )
      .then((res) => {
        setData(res?.data?.data || []);
      })
      .catch((error) => expired(error))
      .finally(() => setIsLoading(false));
  }, [cID]);

  const handleDeleteShow = (id) => {
    setDeleteId(id);
    setDeleteShow(true);
  };

  const handleDelete = () => {
    axiosPrivate
      .delete(`/api/v1/stock/${deleteId}`)
      .then((res) => {
        if (res.status === 204) {
          toast.success('Document was deleted');
          window.location.reload();
        }
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      })
      .finally(() => {
        setDeleteShow(false);
      });
  };

  const COLUMNS = useMemo(
    () => [
      {
        Header: () => null,
        id: 'expander',
        Cell: ({ row }) => {
          return (
            <>
              {row.isExpanded ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </>
          );
        },
      },
      {
        id: 'ID',
        Header: 'Transaction Ref',
        accessor: 'transactionRef',
        hidden: true,
      },
      {
        Header: 'Time',
        accessor: 'recordedAt',
        Cell: ({ value }) => dayjs(value).format('DD/MM/YYYY hh:mm A'),
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Type',
        accessor: 'type',
        Cell: ({ value }) => (
          <span style={{ textTransform: 'capitalize' }}>{value}</span>
        ),
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Intact',
        Cell: ({ row }) => {
          const totalCartons = row?.original.products
            ?.map((p) =>
              Math.floor(p.intactQuantity / p?.product?.quantityPerCarton),
            )
            .reduce((a, c) => a + c, 0);
          const totalPackets = row?.original.products
            ?.map((p) => p?.intactQuantity % p?.product?.quantityPerCarton)
            .reduce((a, c) => a + c, 0);
          let totalDisplay = `${totalCartons} ctn`;
          if (totalPackets > 0) totalDisplay += ` ${totalPackets} pcs`;

          return totalDisplay;
        },
      },
      {
        Header: 'Damaged',
        Cell: ({ row }) => {
          const totalCartons = row?.original.products
            ?.map((p) =>
              Math.floor(p.damagedQuantity / p?.product?.quantityPerCarton),
            )
            .reduce((a, c) => a + c, 0);
          const totalPackets = row?.original.products
            ?.map((p) => p?.damagedQuantity % p?.product?.quantityPerCarton)
            .reduce((a, c) => a + c, 0);
          let totalDisplay = `${totalCartons} ctn`;
          if (totalPackets > 0) totalDisplay += ` ${totalPackets} pcs`;

          return totalDisplay;
        },
      },
      {
        Header: 'Action',
        id: 'Action',
        Cell: ({ row }) => (
          <>
            {['Super Admin'].includes(role) ? (
              <Stack direction="row" alignItems="center" spacing={2}>
                <IconButton
                  component={Link}
                  to={`/stock/edit/${row?.original?.id}`}
                  aria-label="Edit stock entry"
                  className="Table_icon_btn">
                  <BorderColorIcon />
                </IconButton>

                <IconButton
                  onClick={() => handleDeleteShow(row.original.id)}
                  className="Table_icon_btn delete">
                  <DeleteIcon />
                </IconButton>
              </Stack>
            ) : null}
          </>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <CustomerProfileHeader cID={cID} />
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <Table
          data={data}
          columns={COLUMNS}
          filename="Stock"
          addButtonTooltip="Add Stock"
          onAddButtonClick={() => {
            setAddModalShow(true);
          }}
          expandRow={StockExpandRow}
        />
      )}

      <Dialog
        open={addModalShow}
        onClose={() => setAddModalShow(false)}
        aria-labelledby="stock-alert-dialog-title">
        <DialogTitle id="stock-alert-dialog-title">
          Select stock entry type
        </DialogTitle>
        <DialogContent>
          <List>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={`/stock/add?customer=${cID}&type=opening`}>
                <ListItemText primary="Opening stock" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={`/stock/add?customer=${cID}&type=physical`}>
                <ListItemText primary="Physical stock" />
              </ListItemButton>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddModalShow(false)}>cancel</Button>
        </DialogActions>
      </Dialog>

      <ConfirmModal
        title="Are you sure?"
        subtitle="You won't be able to revert this!"
        show={deleteShow}
        onSave={() => handleDelete()}
        onHide={() => setDeleteShow(false)}
        actionButtonLabel="Delete"
        actionButtonColor="error"
      />
    </>
  );
}

export default CustomerStock;
