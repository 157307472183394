import React, { useMemo, useState } from 'react';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FactoryIcon from '@mui/icons-material/Factory';
import GroupsIcon from '@mui/icons-material/Groups';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import StoreIcon from '@mui/icons-material/Store';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Backdrop, Box, useMediaQuery } from '@mui/material';
import { LayoutContainer } from '../assets/style/Layout.jsx';
import useAuth from '../hooks/useAuth';
import useLayout from '../hooks/useLayout';
import Header from './Header';
import MobileNavigation from './MobileNavigation';
import Sidebar from './Sidebar';

export default function Layout({ children }) {
  const matches = useMediaQuery('(max-width:900px)');
  const { collapsemenu, setBackDrop, backDrop, left, setLeft } = useLayout();
  const { role, Admin } = useAuth();
  // states
  const [filterMenu, setFilterMenu] = useState([{}]);

  const menuOptions = [
    {
      title: ['ASM', 'RSM', 'DSM', 'SO/SR'].includes(role)
        ? 'Forms'
        : 'Reports',
      icon: <DashboardIcon />,
      url: '/',
      unaccessible: ['Accounts', 'DO', 'IMS', 'Factory'],
    },
    {
      title: 'Factory',
      icon: <FactoryIcon />,
      url: '/factory',
      unaccessible: [
        'ASM',
        'RSM',
        'DSM',
        'SO/SR',
        'Accounts',
        'DO',
        'IMS',
        'NSM',
        'DSR',
      ],
    },
    {
      title: 'Geo Setup',
      icon: <LocationOnIcon />,
      url: '/territories',
      unaccessible: [
        'ASM',
        'RSM',
        'DSM',
        'SO/SR',
        'Accounts',
        'DO',
        'IMS',
        'Factory',
      ],
    },
    {
      title: 'Authorization',
      icon: <AdminPanelSettingsIcon />,
      url: '/auth',
      unaccessible: [
        ...Admin,
        'ASM',
        'RSM',
        'DSM',
        'SO/SR',
        'Accounts',
        'DO',
        'IMS',
        'Factory',
      ],
    },
    {
      title: 'Employees',
      icon: <GroupsIcon />,
      url: '/employees',
      unaccessible: [
        'ASM',
        'RSM',
        'DSM',
        'SO/SR',
        'Accounts',
        'DO',
        'IMS',
        'Factory',
      ],
    },
    {
      title: 'Target',
      icon: <TrendingUpIcon />,
      url: '/target',
      unaccessible: ['Accounts', 'Factory'],
    },
    {
      title: 'SR Report',
      icon: <AssignmentIndIcon />,
      url: '/sr-report-entries',
      unaccessible: ['Accounts', 'Factory'],
    },
    {
      title: 'Payroll',
      icon: <AccountBalanceWalletIcon />,
      url: '/payroll-ledger',
      unaccessible: ['Accounts', 'Factory'],
    },
    {
      title: 'Customers',
      icon: <StoreIcon />,
      url: '/customers',
      unaccessible: ['SO/SR', 'IMS', 'Factory'],
    },
    {
      title: 'Ph Stock',
      icon: <InventoryIcon />,
      url: '/physical-stocks',
      unaccessible: ['Accounts', 'Factory'],
    },
    {
      title: 'Collections',
      icon: <MonetizationOnIcon />,
      url: '/collections',
      unaccessible: ['SO/SR', 'IMS', 'Factory'],
    },
    {
      title: 'Lifting Plan',
      icon: <PriceCheckIcon />,
      url: '/lifting-plan-details',
      unaccessible: ['Accounts', 'Factory'],
    },
    {
      title: 'Damages',
      icon: <BrokenImageIcon />,
      url: '/damages',
      unaccessible: ['SO/SR', 'IMS', 'Factory'],
    },
    {
      title: 'Products',
      icon: <LocalMallIcon />,
      url: '/products',
      unaccessible: [
        'ASM',
        'RSM',
        'DSM',
        'SO/SR',
        'Accounts',
        'DO',
        'IMS',
        'Factory',
      ],
    },
    {
      title: 'Transactions',
      icon: <ShoppingCartCheckoutIcon />,
      url: '/transactions',
      unaccessible: ['SO/SR', 'IMS', 'Factory'],
    },
    /* {
      title: 'Transfer',
      icon: <ShoppingCartCheckoutIcon />,
      url: '/transfer',
      unaccessible: [
        'ASM',
        'RSM',
        'DSM',
        'SO/SR',
        'Accounts',
        'DO',
        'IMS',
        'Factory',
      ],
    }, */
    {
      title: 'Settings',
      icon: <SettingsIcon />,
      url: '/settings',
      unaccessible: [
        ...Admin,
        'ASM',
        'RSM',
        'DSM',
        'SO/SR',
        'Accounts',
        'DO',
        'IMS',
        'Factory',
      ],
    },
  ];

  useMemo(() => {
    const results = menuOptions.filter(
      (menu) => !menu?.unaccessible?.includes(role),
    );
    setFilterMenu(results);
  }, []);

  return (
    <>
      <LayoutContainer left={left}>
        <Backdrop
          sx={{ color: '#fff', zIndex: 92, display: { md: 'none' } }}
          open={backDrop}
          onClick={() => {
            setBackDrop(false);
            setLeft(-1000);
          }}
        />
        <Box
          className="leftBox"
          sx={{
            minWidth: `${collapsemenu ? 'initial' : '170px'}`,
            width: `${collapsemenu ? '55px' : 'initial'}`,
          }}>
          <Sidebar options={filterMenu} />
        </Box>

        {/* mobile menu start */}
        {matches && <MobileNavigation options={filterMenu} />}

        {/* mobile menu end */}

        <Box className="rightBox" sx={{ paddingBottom: { md: 0, xs: 8 } }}>
          <Header />
          <Box className="childrenContainer">
            <Box>{children}</Box>
          </Box>
        </Box>
      </LayoutContainer>
    </>
  );
}
