import * as React from 'react';
import Box from '@mui/material/Box';
import { NavLink, Outlet } from 'react-router-dom';
import { SettingsContainer } from '../../assets/style/SettingsContainer';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
} from '@mui/material';
import useHeader from '../../hooks/useHeader';

export default function Settings() {
  const { setHeader } = useHeader();
  React.useEffect(() => {
    setHeader({ title: 'Settings' });
  }, []);

  const menus = [
    { label: 'Geo Hierarchy', to: '/settings/territoy-type' },
    { label: 'Employee Role', to: '/settings/employee-role' },
    { label: 'Customer Type', to: '/settings/customer-type' },
    { label: 'Customer Class', to: '/settings/customer-class' },
    { label: 'Product Category', to: '/settings/product-category' },
    { label: 'Transaction Type', to: '/settings/transaction-type' },
  ];

  return (
    <>
      <SettingsContainer component={Paper}>
        <Box className="SettingsLeft">
          <List>
            {menus.map((item, index) => (
              <ListItem key={index} component={NavLink} to={item.to}>
                <ListItemButton>
                  <ListItemText
                    primary={item.label}
                    sx={{
                      whiteSpace: 'nowrap',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box className="SettingsRight">
          <Outlet />
        </Box>
      </SettingsContainer>
    </>
  );
}
