import { useEffect, useMemo, useState } from 'react';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { IconButton, Stack, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmModal from '../../component/ConfirmModal';
import TableSkeleton from '../../component/Skeleton/TableSkeleton';
import { DateRangeColumnFilter } from '../../component/Table/SelectColumnFilter';
import Table from '../../component/Table/Table';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import StockExpandRow from './ExpandRow';
import useHeader from '../../hooks/useHeader';

export default function PhysicalStocks() {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { setHeader } = useHeader();
  const { role, userId } = useAuth();
  const expired = useExpiredSession();

  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  useEffect(() => {
    setHeader({ title: 'Physical Stocks' });
  }, []);

  const handleDeleteShow = (id) => {
    setDeleteId(id);
    setDeleteShow(true);
  };

  const handleDelete = () => {
    axiosPrivate
      .delete(`/api/v1/stock/${deleteId}`)
      .then((res) => {
        if (res.status === 204) {
          toast.success('Document was deleted');
          refetch();
        }
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      })
      .finally(() => {
        setDeleteShow(false);
      });
  };

  // get physical Stocks
  const {
    data: allStocks,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
  } = useQuery('Cust-physical-stocks', () => {
    let url =
      '/api/v1/stock?populate=yes&limit=none&sort=-recordedAt&type=physical';
    if (['Default', 'DSR', 'SO/SR', 'ASM', 'RSM', 'DSM'].includes(role)) {
      url += `&recordedBy=${userId}`;
    }

    return axiosPrivate.get(url).then((res) => {
      const d = [];
      res?.data?.data?.forEach((i) => {
        const item = { ...i };

        let cartonsI = 0;
        let packetsI = 0;
        let cartonsD = 0;
        let packetsD = 0;

        i?.products?.forEach((p) => {
          cartonsI += Math.floor(
            p?.intactQuantity / p?.product?.quantityPerCarton,
          );
          packetsI += p?.intactQuantity % p?.product?.quantityPerCarton;

          cartonsD += Math.floor(
            p?.damagedQuantity / p?.product?.quantityPerCarton,
          );
          packetsD += p?.damagedQuantity % p?.product?.quantityPerCarton;
        });

        let intactQuantityDisplay = `${cartonsI} ctn`;
        if (packetsI) intactQuantityDisplay += ` ${packetsI} pcs`;

        let damagedQuantityDisplay = `${cartonsD} ctn`;
        if (packetsD) damagedQuantityDisplay += ` ${packetsD} pcs`;

        item.intactSum = intactQuantityDisplay;
        item.damageSum = damagedQuantityDisplay;

        d.push(item);
      });

      return d;
    });
  });

  const COLUMNS = useMemo(
    () => [
      {
        Header: () => null,
        id: 'expander',
        Cell: ({ row }) => {
          return (
            <>
              {row.isExpanded ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </>
          );
        },
      },
      {
        Header: 'Entry Time',
        accessor: 'createdAt',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY hh:mm A');
          return value;
        },
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Stock Time',
        accessor: 'recordedAt',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY hh:mm A');
          return value;
        },
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Customer',
        accessor: 'customer.nameCode',
      },
      {
        Header: 'Location',
        accessor: 'customer.territory.code',
      },
      {
        Header: 'Intact Stock',
        accessor: 'intactSum',
        aggregateCtn: 'sum',
      },
      {
        Header: 'Damaged Stock',
        accessor: 'damageSum',
        aggregateCtn: 'sum',
      },
      {
        Header: 'Entered By',
        accessor: 'recordedBy.nameCode',
      },
      {
        Header: 'Action',
        id: 'PhysicalStock_Action',
        Cell: ({ row }) => {
          return (
            <>
              {[
                'Default',
                'DSR',
                'SO/SR',
                'ASM',
                'RSM',
                'DSM',
                'Factory',
                'Admin',
              ].includes(role) ? (
                userId === row?.original?.recordedBy?.id ? (
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <IconButton
                      component={Link}
                      to={`/stock/edit/${row?.original?.id}`}
                      aria-label="Edit stock entry"
                      className="Table_icon_btn">
                      <BorderColorIcon />
                    </IconButton>

                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => handleDeleteShow(row.original.id)}
                        className="Table_icon_btn delete">
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                ) : null
              ) : (
                <Stack direction="row" alignItems="center" spacing={2}>
                  <IconButton
                    component={Link}
                    to={`/stock/edit/${row?.original?.id}`}
                    aria-label="Edit stock entry"
                    className="Table_icon_btn">
                    <BorderColorIcon />
                  </IconButton>

                  <Tooltip title="Delete">
                    <IconButton
                      onClick={() => handleDeleteShow(row.original.id)}
                      className="Table_icon_btn delete">
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              )}
            </>
          );
        },
      },
    ],
    [],
  );

  if (isError) {
    expired(error);
  }

  return (
    <>
      <Table
        columns={COLUMNS}
        data={allStocks || [{}]}
        filename="PhysicalStocks"
        addButtonTooltip="Add Physical Stocks"
        onAddButtonClick={() =>
          navigate('/stock/add?type=physical', { state: { type: 'physical' } })
        }
        isLoading={isLoading || isFetching}
        expandRow={StockExpandRow}
        fullHeight={true}
      />

      <ConfirmModal
        title="Are you sure?"
        subtitle="You won't be able to revert this!"
        show={deleteShow}
        onSave={() => handleDelete()}
        onHide={() => setDeleteShow(false)}
        actionButtonLabel="Delete"
        actionButtonColor="error"
      />
    </>
  );
}
