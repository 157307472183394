import React, { useEffect, useMemo, useState } from 'react';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {
  Button,
  Divider,
  Icon,
  IconButton,
  Link,
  MenuItem,
  Table as MuiTable,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import ConfirmModal from '../../../../component/ConfirmModal';
import Modals from '../../../../component/Modals';
import {
  DateRangeColumnFilter,
  MultiSelectColumnFilter,
  SelectColumnFilterBool,
} from '../../../../component/Table/SelectColumnFilter';
import Table from '../../../../component/Table/Table';
import useAuth from '../../../../hooks/useAuth';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import useExpiredSession from '../../../../hooks/useExpiredSession';
import useHeader from '../../../../hooks/useHeader';
import roundToDigit from '../../../../utils/roundToDigit';
import TaDaPrintModal from '../../../../component/form/TaDaPrintModal';
import getRandomColorName from '../../../../utils/getRandomColorName';

export default function TaDaDetails() {
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const { setHeader } = useHeader();
  const { role, userId, Admin } = useAuth();

  const [editData, setEditData] = useState(null);
  const [approveTaDaShow, setApproveTaDaShow] = useState(false);
  const [approveOrderShow, setApproveOrderShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [tadaPrintModalshow, setTadaPrintModalShow] = useState(false);
  const [counter, setCounter] = useState({});

  const [entryStartDate, setEntryStartDate] = useState(
    dayjs().subtract(1, 'month').startOf('month'),
  );
  const [entryEndDate, setEntryEndDate] = useState(dayjs().endOf('month'));

  useEffect(() => {
    setHeader({ title: 'TA DA Info' });
  }, []);

  const { data, isLoading, isError, error, refetch } = useQuery(
    ['ta-da-info', entryStartDate, entryEndDate],
    () => {
      let url = '/api/v1/travel-allowance?populate=yes&limit=none&sort=-code';
      if (
        ['Default', 'DSR', 'SO/SR', 'ASM', 'RSM', 'DSM', 'NSM'].includes(role)
      ) {
        url += `&employee=${userId}`;
      }
      if (entryStartDate?.isValid()) {
        url += `&createdAt[gte]=${entryStartDate.toISOString()}`;
      }
      if (entryEndDate?.isValid()) {
        url += `&createdAt[lte]=${entryEndDate.toISOString()}`;
      }

      return axiosPrivate.get(url).then((res) => {
        const countTracker = {};

        res?.data?.data?.forEach((d) => {
          if (
            d?.code?.endsWith('-1') &&
            d?.reasonForTravel?.includes('Work with SO/SR')
          ) {
            if (countTracker[d?.employee?.id]?.count) {
              countTracker[d?.employee?.id].count += 1;
            } else {
              countTracker[d?.employee?.id] = {
                count: 1,
                color: getRandomColorName(),
              };
            }
          }
        });

        setCounter(countTracker);
        return res?.data?.data;
      });
    },
    { refetchInterval: 120000 },
  );

  const handleApproveTaDaShow = (data) => {
    setEditData(data);
    setApproveTaDaShow(true);
  };

  const handleApproveOrderShow = (data) => {
    setEditData(data);
    setApproveOrderShow(true);
  };

  const handleEditShow = (data) => {
    setEditData(data);
    setEditShow(true);
  };

  const handleDeleteShow = (data) => {
    setEditData(data);
    setDeleteShow(true);
  };

  const handleClose = () => {
    setEditData(null);
    setApproveTaDaShow(false);
    setApproveOrderShow(false);
    setEditShow(false);
    setDeleteShow(false);
  };

  const handleTaDaApprove = () => {
    axiosPrivate
      .patch(`/api/v1/travel-allowance/${editData?.id}/approve`, editData)
      .then((res) => {
        toast.success(res?.data?.message);
        handleClose();
        refetch();
      })
      .catch((err) => expired(err));
  };

  const handleOrderApprove = (data) => {
    const code = data?.code?.split('-')[0];
    axiosPrivate
      .patch(`/api/v1/travel-allowance/${code}/approve-order`, data)
      .then((res) => {
        toast.success(res?.data?.message);
        handleClose();
        refetch();
      })
      .catch((err) => expired(err));
  };

  const handleDelete = () => {
    axiosPrivate
      .delete(`/api/v1/travel-allowance/${editData?.id}`)
      .then((res) => {
        if (res.status === 204) {
          toast.success('Document was deleted');
          handleClose();
          refetch();
        }
      })
      .catch((err) => expired(err));
  };

  const entryDateRangeFilter = useMemo(() => {
    return (
      <Stack>
        <input
          type="date"
          value={entryStartDate?.format('YYYY-MM-DD') || ''}
          onChange={(e) => {
            setEntryStartDate(dayjs(e.target.value).startOf('date'));
          }}
        />
        <input
          type="date"
          value={entryEndDate?.format('YYYY-MM-DD') || ''}
          onChange={(e) => {
            setEntryEndDate(dayjs(e.target.value).endOf('date'));
          }}
        />
      </Stack>
    );
  }, [entryStartDate, entryEndDate]);

  const COLUMNS = useMemo(
    () => [
      {
        Header: () => null,
        id: 'expander',
        Cell: ({ row }) => (
          <>
            {row.isExpanded ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </>
        ),
      },
      {
        Header: 'Claimed By',
        accessor: 'employee.nameCode',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Contact',
        accessor: 'employee.contactNumber',
      },
      {
        Header: 'Entry ID',
        accessor: 'code',
        Cell: ({ value }) => {
          return <b>{value?.toUpperCase()}</b>;
        },
      },
      {
        Header: 'Entry Time',
        accessor: 'createdAt',
        Filter: entryDateRangeFilter,
        Cell: ({ value }) => {
          return dayjs(value).format('DD/MM/YYYY hh:mm A');
        },
      },
      {
        Header: 'Travel Date',
        accessor: 'travelDate',
        filter: 'dateBetween',
        Filter: DateRangeColumnFilter,
        Cell: ({ value }) => {
          return dayjs(value).format('DD/MM/YYYY');
        },
      },
      {
        Header: 'Reasons For Travel',
        accessor: 'reasonForTravel',
        Cell: ({ row, value }) => {
          if (
            value?.includes('Work with SO/SR') &&
            counter[row?.original?.employee?.id]?.count > 1
          ) {
            return (
              <span>
                {value}&nbsp;
                <span
                  style={{
                    borderRadius: 8,
                    color: 'white',
                    backgroundColor:
                      counter[row?.original?.employee?.id]?.color,
                  }}>
                  &nbsp;{counter[row?.original?.employee?.id]?.count}&nbsp;
                </span>
              </span>
            );
          }

          return value;
        },
      },
      {
        Header: 'Travel From',
        accessor: 'travelStart',
        Cell: ({ value }) => (
          <Typography variant="inherit" sx={{ whiteSpace: 'nowrap' }}>
            {value}
          </Typography>
        ),
      },
      {
        Header: 'Travel To',
        accessor: 'travelEnd',
        Cell: ({ value }) => (
          <Typography variant="inherit" sx={{ whiteSpace: 'nowrap' }}>
            {value}
          </Typography>
        ),
      },
      {
        Header: 'Transport',
        accessor: 'transport',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Travel Cost',
        accessor: 'travelAllowance',
        aggregate: 'sum',
      },
      {
        Header: 'Inside HQ',
        accessor: 'isInTerritory',
        Filter: SelectColumnFilterBool,
        Cell: ({ value }) => {
          return value ? 'Yes' : 'No';
        },
      },
      {
        Header: 'DA Amount',
        accessor: 'dearnessAllowance',
        aggregate: 'sum',
      },
      {
        Header: 'Hotel Bill',
        accessor: 'hotelBill',
        aggregate: 'sum',
      },
      {
        Header: 'Hotel Name',
        accessor: 'hotelName',
      },
      {
        Header: 'Hotel Contact',
        accessor: 'hotelContact',
      },
      {
        Header: 'Other Bill',
        accessor: 'otherBill',
        aggregate: 'sum',
      },
      {
        Header: 'Other Bill Description',
        accessor: 'otherBillDescription',
      },
      {
        Header: 'Region',
        accessor: 'region.name',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
        Cell: ({ value }) => (
          <Typography variant="inherit" sx={{ whiteSpace: 'nowrap' }}>
            {value}
          </Typography>
        ),
      },
      {
        Header: 'HQ',
        accessor: 'headquarter.name',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
        Cell: ({ value }) => (
          <Typography variant="inherit" sx={{ whiteSpace: 'nowrap' }}>
            {value}
          </Typography>
        ),
      },
      {
        Header: 'Point/Town',
        accessor: 'point.name',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
        Cell: ({ value }) => (
          <Typography variant="inherit" sx={{ whiteSpace: 'nowrap' }}>
            {value}
          </Typography>
        ),
      },
      {
        Header: 'Route/Beat',
        accessor: 'route',
        Cell: ({ value }) => (
          <Typography variant="inherit" sx={{ whiteSpace: 'nowrap' }}>
            {value}
          </Typography>
        ),
      },
      {
        Header: 'Geo Location',
        Cell: ({ row }) => {
          if (!row.original?.latitude || !row.original?.longitude)
            return <Typography color="error">NO DATA</Typography>;
          return (
            <Link
              href={encodeURI(
                `https://www.google.com/maps/search/?api=1&query=${row.original?.latitude},${row.original?.longitude}`,
              )}
              rel="noopener noreferrer"
              target="_blank"
              underline="hover"
              title="Open in Google Map">
              <Icon sx={{ fontSize: 14 }}>location_on</Icon>
              {` ${row.original?.latitude}, ${row.original?.longitude}`}
            </Link>
          );
        },
      },
      {
        Header: 'Order Quantity',
        accessor: 'orderQuantity',
        Cell: ({ row, value }) => (
          <Typography variant="inherit" sx={{ whiteSpace: 'nowrap' }}>
            {value}
            <br />
            {row?.original?.orderQuantityApproved ? (
              <Typography
                variant="inherit"
                color={
                  row?.original?.orderStatus === 'approved'
                    ? 'success.main'
                    : 'error.main'
                }>
                {row?.original?.orderQuantityApproved}
              </Typography>
            ) : null}
          </Typography>
        ),
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Order Value',
        accessor: 'orderValue',
        Cell: ({ row, value }) => (
          <Typography variant="inherit" sx={{ whiteSpace: 'nowrap' }}>
            {value}
            <br />
            {row?.original?.orderValueApproved ? (
              <Typography
                variant="inherit"
                color={
                  row?.original?.orderStatus === 'approved'
                    ? 'success.main'
                    : 'error.main'
                }>
                {row?.original?.orderValueApproved}
              </Typography>
            ) : null}
          </Typography>
        ),
      },
      {
        Header: 'Working Time',
        accessor: 'workingTime',
        Cell: ({ row, value }) => (
          <Typography variant="inherit" sx={{ whiteSpace: 'nowrap' }}>
            {value}
            <br />
            {row?.original?.workingTimeApproved ? (
              <Typography
                variant="inherit"
                color={
                  row?.original?.orderStatus === 'approved'
                    ? 'success.main'
                    : 'error.main'
                }>
                {row?.original?.workingTimeApproved}
              </Typography>
            ) : null}
          </Typography>
        ),
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Visited Existing Dealer',
        accessor: 'exDealerVisitRef.customer.nameCode',
        Cell: ({ row, value }) =>
          value ? (
            <Typography variant="inherit" sx={{ whiteSpace: 'nowrap' }}>
              {value}
              <br />
              <Link
                href={`tel:${row?.original?.exDealerVisitRef?.customer?.contact}`}
                color="inherit"
                variant="inherit">
                {row?.original?.exDealerVisitRef?.customer?.contact}
              </Link>
            </Typography>
          ) : null,
      },
      {
        Header: "Existing Dealer's SO/SR",
        accessor: 'exDealerVisitRef.srName.nameCode',
        Cell: ({ row, value }) =>
          value ? (
            <Typography variant="inherit" sx={{ whiteSpace: 'nowrap' }}>
              {value}
              <br />
              <Link
                href={`tel:${row?.original?.exDealerVisitRef?.srName?.contactNumber}`}
                color="inherit"
                variant="inherit">
                {row?.original?.exDealerVisitRef?.srName?.contactNumber}
              </Link>
            </Typography>
          ) : null,
      },
      {
        Header: 'Visited Searched Dealer',
        accessor: 'dealerSearchRef.dealerName',
        Cell: ({ row, value }) =>
          value ? (
            <Typography variant="inherit" sx={{ whiteSpace: 'nowrap' }}>
              {value}
              <br />
              <Link
                href={`tel:${row?.original?.dealerSearchRef?.primaryNo}`}
                color="inherit"
                variant="inherit">
                {row?.original?.dealerSearchRef?.primaryNo}
              </Link>
            </Typography>
          ) : null,
      },
      {
        Header: 'Claimed Amount',
        accessor: 'totalBill',
        aggregate: 'sum',
      },
      {
        Header: 'Approved Amount',
        accessor: 'approvedBill',
        aggregate: 'sum',
      },
      {
        Header: 'Remarks',
        accessor: 'remarks',
      },
      {
        Header: 'TA DA Status',
        accessor: 'status',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
        Cell: ({ value }) => {
          if (value === 'approved')
            return (
              <Tooltip title="Approved">
                <CheckCircleIcon color="success" />
              </Tooltip>
            );
          if (value === 'rejected')
            return (
              <Tooltip title="Rejected">
                <CancelIcon color="error" />
              </Tooltip>
            );
          return (
            <Tooltip title="Pending">
              <HelpOutlineIcon color="primary" />
            </Tooltip>
          );
        },
      },
      {
        Header: 'Order Status',
        accessor: 'orderStatus',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
        Cell: ({ value }) => {
          if (value === 'approved')
            return (
              <Tooltip title="Approved">
                <CheckCircleIcon color="success" />
              </Tooltip>
            );
          if (value === 'rejected')
            return (
              <Tooltip title="Rejected">
                <CancelIcon color="error" />
              </Tooltip>
            );
          return (
            <Tooltip title="Pending">
              <HelpOutlineIcon color="primary" />
            </Tooltip>
          );
        },
      },
      {
        Header: 'Action',
        id: 'Action',
        Cell: ({ row }) => {
          return (
            <Stack direction="row" gap={1}>
              {['Super Admin'].includes(role) ? (
                <Tooltip title="Approve TA DA">
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => handleApproveTaDaShow(row.original)}>
                    <BeenhereIcon />
                  </IconButton>
                </Tooltip>
              ) : null}

              {['Super Admin'].includes(role) ? (
                <Tooltip title="Approve Order">
                  <IconButton
                    disabled={
                      !row?.original?.reasonForTravel?.includes(
                        'Work with SO/SR',
                      )
                    }
                    size="small"
                    color="secondary"
                    onClick={() => handleApproveOrderShow(row.original)}>
                    <ShoppingCartIcon />
                  </IconButton>
                </Tooltip>
              ) : null}

              {['Super Admin'].includes(role) ||
              userId === row?.original?.employee?.id ? (
                <>
                  {/* <Tooltip title="Edit">
                    <IconButton
                      size="small"
                      onClick={() => handleEditShow(row.original)}>
                      <BorderColorIcon />
                    </IconButton>
                  </Tooltip> */}

                  <Tooltip title="Delete">
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => handleDeleteShow(row.original)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ) : null}
            </Stack>
          );
        },
      },
    ],
    [counter],
  );

  if (isError) {
    expired(error);
  }

  return (
    <>
      <Table
        columns={COLUMNS}
        data={data || [{}]}
        filename="TA DA Info"
        hideAddIcon
        setPrintModal={setTadaPrintModalShow}
        expandRow={ExpandRow}
        isLoading={isLoading}
        expandRowProps={{
          Admin,
          role,
          id: userId,
          onTaDaApprove: handleApproveTaDaShow,
          onOrderApprove: handleApproveOrderShow,
          onEdit: handleEditShow,
          onDelete: handleDeleteShow,
        }}
        hasDrawer
      />

      {approveTaDaShow ? (
        <ApproveTaDaModal
          data={editData}
          setData={setEditData}
          show={approveTaDaShow}
          onHide={handleClose}
          onAccept={handleTaDaApprove}
        />
      ) : null}

      {approveOrderShow ? (
        <ApproveOrderModal
          data={editData}
          setData={setEditData}
          show={approveOrderShow}
          onHide={handleClose}
          onAccept={handleOrderApprove}
        />
      ) : null}

      {deleteShow ? (
        <ConfirmModal
          title="Are you sure?"
          subtitle="You won't be able to revert this!"
          show={deleteShow}
          onSave={handleDelete}
          onHide={handleClose}
          actionButtonLabel="Delete"
          actionButtonColor="error"
        />
      ) : null}

      {tadaPrintModalshow ? (
        <Modals
          title="Filter TA DA Information"
          show={tadaPrintModalshow}
          onHide={() => setTadaPrintModalShow(false)}
          form
          fullWidth={true}
          width="xs">
          <TaDaPrintModal />
        </Modals>
      ) : null}
    </>
  );
}

function ApproveTaDaModal({ data, setData, show, onHide, onAccept }) {
  return (
    <Modals title="Approve TA DA" show={show} onHide={onHide} form width="lg">
      <Stack gap={2}>
        <TableContainer>
          <MuiTable size="small">
            <TableBody>
              <TableRow>
                <TableCell>Claimed By</TableCell>
                <TableCell>{data?.employee?.nameCode}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Contact</TableCell>
                <TableCell>{data?.employee?.contactNumber}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Entry ID</TableCell>
                <TableCell>
                  <b>{data?.code?.toUpperCase()}</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Entry Time</TableCell>
                <TableCell>
                  {dayjs(data?.createdAt).format('DD/MM/YYYY hh:mm A')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Travel Date</TableCell>
                <TableCell>
                  {dayjs(data?.travelDate).format('DD/MM/YYYY')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Reasons For Travel</TableCell>
                <TableCell>{data?.reasonForTravel}</TableCell>
              </TableRow>
              {data?.travelAllowance > 0 ? (
                <>
                  <TableRow>
                    <TableCell>Travel From</TableCell>
                    <TableCell>{data?.travelStart}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Travel To</TableCell>
                    <TableCell>{data?.travelEnd}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Transport</TableCell>
                    <TableCell>{data?.transport}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Travel Cost</TableCell>
                    <TableCell>{data?.travelAllowance}</TableCell>
                  </TableRow>
                </>
              ) : null}
              {data?.dearnessAllowance > 0 ? (
                <>
                  <TableRow>
                    <TableCell>Inside HQ</TableCell>
                    <TableCell>{data?.isInTerritory ? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>DA Amount</TableCell>
                    <TableCell>{data?.dearnessAllowance}</TableCell>
                  </TableRow>
                </>
              ) : null}
              {data?.hotelBill > 0 ? (
                <>
                  <TableRow>
                    <TableCell>Hotel Bill</TableCell>
                    <TableCell>{data?.hotelBill}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Hotel Name</TableCell>
                    <TableCell>{data?.hotelName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Hotel Contact</TableCell>
                    <TableCell>{data?.hotelContact}</TableCell>
                  </TableRow>
                </>
              ) : null}
              {data?.otherBill > 0 ? (
                <>
                  <TableRow>
                    <TableCell>Other Bill</TableCell>
                    <TableCell>{data?.otherBill}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Other Bill Description</TableCell>
                    <TableCell>{data?.otherBillDescription}</TableCell>
                  </TableRow>
                </>
              ) : null}
              <TableRow>
                <TableCell>Region</TableCell>
                <TableCell>{data?.region?.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>HQ</TableCell>
                <TableCell>{data?.headquarter?.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Point/Town</TableCell>
                <TableCell>{data?.point?.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Route/Beat</TableCell>
                <TableCell>{data?.route}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Geo Location</TableCell>
                <TableCell>
                  {data?.latitude && data?.longitude ? (
                    <Link
                      href={encodeURI(
                        `https://www.google.com/maps/search/?api=1&query=${data?.latitude},${data?.longitude}`,
                      )}
                      rel="noopener noreferrer"
                      target="_blank"
                      underline="hover"
                      title="Open in Google Map">
                      <Icon sx={{ fontSize: 14 }}>location_on</Icon>
                      {` ${data?.latitude}, ${data?.longitude}`}
                    </Link>
                  ) : (
                    <Typography color="error">NO DATA</Typography>
                  )}
                </TableCell>
              </TableRow>
              {data?.orderValue > 0 ? (
                <>
                  <TableRow>
                    <TableCell>Order Quantity</TableCell>
                    <TableCell>{data?.orderQuantity}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Order Value</TableCell>
                    <TableCell>{data?.orderValue}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Working Time</TableCell>
                    <TableCell>{data?.workingTime}</TableCell>
                  </TableRow>
                </>
              ) : null}
              {data?.exDealerVisitRef?.customer?.nameCode ? (
                <>
                  <TableRow>
                    <TableCell>Visited Existing Dealer</TableCell>
                    <TableCell>
                      {data?.exDealerVisitRef?.customer?.nameCode}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Dealer Contact</TableCell>
                    <TableCell>
                      <Link
                        href={`tel:${data?.exDealerVisitRef?.customer?.contact}`}
                        color="inherit"
                        variant="inherit">
                        {data?.exDealerVisitRef?.customer?.contact}
                      </Link>
                    </TableCell>
                  </TableRow>
                </>
              ) : null}
              {data?.exDealerVisitRef?.srName?.nameCode ? (
                <>
                  <TableRow>
                    <TableCell>Existing Dealer's SO/SR</TableCell>
                    <TableCell>
                      {data?.exDealerVisitRef?.srName?.nameCode}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>SO/SR Contact</TableCell>
                    <TableCell>
                      <Link
                        href={`tel:${data?.exDealerVisitRef?.srName?.contactNumber}`}
                        color="inherit"
                        variant="inherit">
                        {data?.exDealerVisitRef?.srName?.contactNumber}
                      </Link>
                    </TableCell>
                  </TableRow>
                </>
              ) : null}
              {data?.dealerSearchRef?.dealerName ? (
                <>
                  <TableRow>
                    <TableCell>Visited Searched Dealer</TableCell>
                    <TableCell>{data?.dealerSearchRef?.dealerName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Dealer Contact</TableCell>
                    <TableCell>
                      <Link
                        href={`tel:${data?.dealerSearchRef?.primaryNo}`}
                        color="inherit"
                        variant="inherit">
                        {data?.dealerSearchRef?.primaryNo}
                      </Link>
                    </TableCell>
                  </TableRow>
                </>
              ) : null}
              <TableRow>
                <TableCell>Claimed Amount</TableCell>
                <TableCell>
                  <b>{data?.totalBill}</b>
                </TableCell>
              </TableRow>
            </TableBody>
          </MuiTable>
        </TableContainer>

        <TextField
          fullWidth
          label="Approved Amount"
          type="number"
          value={data?.approvedBill || ''}
          onChange={(e) => {
            const v = roundToDigit(e?.target?.value);
            setData({ ...data, approvedBill: v });
          }}
          onKeyUp={(e) => e?.key === 'Enter' && onAccept()}
          onWheel={(e) => e.target.blur()}
        />

        <TextField
          fullWidth
          label="Remarks"
          type="text"
          multiline
          rows={2}
          value={data?.remarks}
          onChange={(e) => setData({ ...data, remarks: e.target.value })}
        />

        <Button fullWidth variant="contained" size="large" onClick={onAccept}>
          Save
        </Button>
      </Stack>
    </Modals>
  );
}

function ApproveOrderModal({ data, setData, show, onHide, onAccept }) {
  return (
    <Modals title="Approve Order" show={show} onHide={onHide} form width="lg">
      <Stack gap={2}>
        <TableContainer>
          <MuiTable size="small">
            <TableBody>
              <TableRow>
                <TableCell>Claimed By</TableCell>
                <TableCell>{data?.employee?.nameCode}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Contact</TableCell>
                <TableCell>{data?.employee?.contactNumber}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Entry Time</TableCell>
                <TableCell>
                  {dayjs(data?.createdAt).format('DD/MM/YYYY hh:mm A')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Region</TableCell>
                <TableCell>{data?.region?.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>HQ</TableCell>
                <TableCell>{data?.headquarter?.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Point/Town</TableCell>
                <TableCell>{data?.point?.name}</TableCell>
              </TableRow>
              {data?.exDealerVisitRef?.customer?.nameCode ? (
                <>
                  <TableRow>
                    <TableCell>Visited Existing Dealer</TableCell>
                    <TableCell>
                      {data?.exDealerVisitRef?.customer?.nameCode}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Dealer Contact</TableCell>
                    <TableCell>
                      <Link
                        href={`tel:${data?.exDealerVisitRef?.customer?.contact}`}
                        color="inherit"
                        variant="inherit">
                        {data?.exDealerVisitRef?.customer?.contact}
                      </Link>
                    </TableCell>
                  </TableRow>
                </>
              ) : null}
              {data?.exDealerVisitRef?.srName?.nameCode ? (
                <>
                  <TableRow>
                    <TableCell>Existing Dealer's SO/SR</TableCell>
                    <TableCell>
                      {data?.exDealerVisitRef?.srName?.nameCode}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>SO/SR Contact</TableCell>
                    <TableCell>
                      <Link
                        href={`tel:${data?.exDealerVisitRef?.srName?.contactNumber}`}
                        color="inherit"
                        variant="inherit">
                        {data?.exDealerVisitRef?.srName?.contactNumber}
                      </Link>
                    </TableCell>
                  </TableRow>
                </>
              ) : null}
            </TableBody>
          </MuiTable>
        </TableContainer>

        <TextField
          fullWidth
          select
          label="Order Quantity"
          value={data?.orderQuantity}
          onChange={(e) => setData({ ...data, orderQuantity: e.target.value })}>
          <MenuItem value="10 ctn">10 ctn</MenuItem>
          <MenuItem value="20 ctn">20 ctn</MenuItem>
          <MenuItem value="30 ctn">30 ctn</MenuItem>
          <MenuItem value="40 ctn">40 ctn</MenuItem>
          <MenuItem value="50 ctn or above">50 ctn or above</MenuItem>
        </TextField>

        <TextField
          fullWidth
          label="Order Value (in taka)"
          type="number"
          value={data?.orderValue || ''}
          onChange={(e) => {
            const v = roundToDigit(e?.target?.value);
            setData({ ...data, orderValue: v });
          }}
          onWheel={(e) => e.target.blur()}
        />

        <TextField
          fullWidth
          select
          label="Working Time"
          value={data?.workingTime}
          onChange={(e) => setData({ ...data, workingTime: e.target.value })}>
          <MenuItem value="1 hr">1 hr</MenuItem>
          <MenuItem value="2 hr">2 hr</MenuItem>
          <MenuItem value="3 hr">3 hr</MenuItem>
          <MenuItem value="4 hr">4 hr</MenuItem>
          <MenuItem value="5 hr or above">5 hr or above</MenuItem>
        </TextField>

        <Stack direction="row" sx={{ minWidth: 240, gap: 2 }}>
          <Button
            variant="contained"
            size="large"
            color="error"
            onClick={() => onAccept({ ...data, orderStatus: 'rejected' })}
            sx={{ flexGrow: 1 }}>
            Reject
          </Button>

          <Button
            variant="contained"
            size="large"
            color="success"
            onClick={() => onAccept({ ...data, orderStatus: 'approved' })}
            sx={{ flexGrow: 1 }}>
            Accept
          </Button>
        </Stack>
      </Stack>
    </Modals>
  );
}

function ExpandRow({
  data,
  role,
  id,
  onTaDaApprove,
  onOrderApprove,
  onDelete,
}) {
  let status = null;
  let orderStatus = null;

  switch (data?.status) {
    case 'approved':
      status = (
        <>
          <CheckCircleIcon color="success" />
          &nbsp;Approved
        </>
      );
      break;
    case 'rejected':
      status = (
        <>
          <CancelIcon color="error" />
          &nbsp;Rejected
        </>
      );
      break;
    default:
      status = (
        <>
          <HelpOutlineIcon color="primary" />
          &nbsp;Pending
        </>
      );
      break;
  }

  switch (data?.orderStatus) {
    case 'approved':
      orderStatus = (
        <>
          <CheckCircleIcon color="success" />
          &nbsp;Approved
        </>
      );
      break;
    case 'rejected':
      orderStatus = (
        <>
          <CancelIcon color="error" />
          &nbsp;Rejected
        </>
      );
      break;
    default:
      orderStatus = (
        <>
          <HelpOutlineIcon color="primary" />
          &nbsp;Pending
        </>
      );
      break;
  }

  return (
    <TableContainer>
      <MuiTable>
        <TableBody>
          <TableRow>
            <TableCell>Claimed By</TableCell>
            <TableCell>{data?.employee?.nameCode}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Contact</TableCell>
            <TableCell>{data?.employee?.contactNumber}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Entry ID</TableCell>
            <TableCell>
              <b>{data?.code?.toUpperCase()}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Entry Time</TableCell>
            <TableCell>
              {dayjs(data?.createdAt).format('DD/MM/YYYY hh:mm A')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Travel Date</TableCell>
            <TableCell>
              {dayjs(data?.travelDate).format('DD/MM/YYYY')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Reasons For Travel</TableCell>
            <TableCell>{data?.reasonForTravel}</TableCell>
          </TableRow>
          {data?.travelAllowance > 0 ? (
            <>
              <TableRow>
                <TableCell>Travel From</TableCell>
                <TableCell>{data?.travelStart}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Travel To</TableCell>
                <TableCell>{data?.travelEnd}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Transport</TableCell>
                <TableCell>{data?.transport}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Travel Cost</TableCell>
                <TableCell>{data?.travelAllowance}</TableCell>
              </TableRow>
            </>
          ) : null}
          {data?.dearnessAllowance > 0 ? (
            <>
              <TableRow>
                <TableCell>Inside HQ</TableCell>
                <TableCell>{data?.isInTerritory ? 'Yes' : 'No'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>DA Amount</TableCell>
                <TableCell>{data?.dearnessAllowance}</TableCell>
              </TableRow>
            </>
          ) : null}
          {data?.hotelBill > 0 ? (
            <>
              <TableRow>
                <TableCell>Hotel Bill</TableCell>
                <TableCell>{data?.hotelBill}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Hotel Name</TableCell>
                <TableCell>{data?.hotelName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Hotel Contact</TableCell>
                <TableCell>{data?.hotelContact}</TableCell>
              </TableRow>
            </>
          ) : null}
          {data?.otherBill > 0 ? (
            <>
              <TableRow>
                <TableCell>Other Bill</TableCell>
                <TableCell>{data?.otherBill}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Other Bill Description</TableCell>
                <TableCell>{data?.otherBillDescription}</TableCell>
              </TableRow>
            </>
          ) : null}
          <TableRow>
            <TableCell>Region</TableCell>
            <TableCell>{data?.region?.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>HQ</TableCell>
            <TableCell>{data?.headquarter?.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Point/Town</TableCell>
            <TableCell>{data?.point?.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Route/Beat</TableCell>
            <TableCell>{data?.route}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Geo Location</TableCell>
            <TableCell>
              {data?.latitude && data?.longitude ? (
                <Link
                  href={encodeURI(
                    `https://www.google.com/maps/search/?api=1&query=${data?.latitude},${data?.longitude}`,
                  )}
                  rel="noopener noreferrer"
                  target="_blank"
                  underline="hover"
                  title="Open in Google Map">
                  <Icon sx={{ fontSize: 14 }}>location_on</Icon>
                  {` ${data?.latitude}, ${data?.longitude}`}
                </Link>
              ) : (
                <Typography color="error">NO DATA</Typography>
              )}
            </TableCell>
          </TableRow>
          {data?.orderValue > 0 ? (
            <>
              <TableRow>
                <TableCell>Order Quantity</TableCell>
                <TableCell>
                  <Typography
                    variant="inherit"
                    sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    {data?.orderQuantity}
                    {data?.orderQuantityApproved ? (
                      <>
                        <Divider orientation="vertical" flexItem />
                        <Typography
                          variant="inherit"
                          color={
                            data?.orderStatus === 'approved'
                              ? 'success.main'
                              : 'error.main'
                          }>
                          {data?.orderQuantityApproved}
                        </Typography>
                      </>
                    ) : null}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Order Value</TableCell>
                <TableCell>
                  <Typography
                    variant="inherit"
                    sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    {data?.orderValue}
                    {data?.orderValueApproved ? (
                      <>
                        <Divider orientation="vertical" flexItem />
                        <Typography
                          variant="inherit"
                          color={
                            data?.orderStatus === 'approved'
                              ? 'success.main'
                              : 'error.main'
                          }>
                          {data?.orderValueApproved}
                        </Typography>
                      </>
                    ) : null}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Working Time</TableCell>
                <TableCell>
                  <Typography
                    variant="inherit"
                    sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    {data?.workingTime}
                    {data?.workingTimeApproved ? (
                      <>
                        <Divider orientation="vertical" flexItem />
                        <Typography
                          variant="inherit"
                          color={
                            data?.orderStatus === 'approved'
                              ? 'success.main'
                              : 'error.main'
                          }>
                          {data?.workingTimeApproved}
                        </Typography>
                      </>
                    ) : null}
                  </Typography>
                </TableCell>
              </TableRow>
            </>
          ) : null}
          {data?.exDealerVisitRef?.customer?.nameCode ? (
            <>
              <TableRow>
                <TableCell>Visited Existing Dealer</TableCell>
                <TableCell>
                  {data?.exDealerVisitRef?.customer?.nameCode}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Dealer Contact</TableCell>
                <TableCell>
                  <Link
                    href={`tel:${data?.exDealerVisitRef?.customer?.contact}`}
                    color="inherit"
                    variant="inherit">
                    {data?.exDealerVisitRef?.customer?.contact}
                  </Link>
                </TableCell>
              </TableRow>
            </>
          ) : null}
          {data?.exDealerVisitRef?.srName?.nameCode ? (
            <>
              <TableRow>
                <TableCell>Existing Dealer's SO/SR</TableCell>
                <TableCell>
                  {data?.exDealerVisitRef?.srName?.nameCode}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>SO/SR Contact</TableCell>
                <TableCell>
                  <Link
                    href={`tel:${data?.exDealerVisitRef?.srName?.contactNumber}`}
                    color="inherit"
                    variant="inherit">
                    {data?.exDealerVisitRef?.srName?.contactNumber}
                  </Link>
                </TableCell>
              </TableRow>
            </>
          ) : null}
          {data?.dealerSearchRef?.dealerName ? (
            <>
              <TableRow>
                <TableCell>Visited Searched Dealer</TableCell>
                <TableCell>{data?.dealerSearchRef?.dealerName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Dealer Contact</TableCell>
                <TableCell>
                  <Link
                    href={`tel:${data?.dealerSearchRef?.primaryNo}`}
                    color="inherit"
                    variant="inherit">
                    {data?.dealerSearchRef?.primaryNo}
                  </Link>
                </TableCell>
              </TableRow>
            </>
          ) : null}
          <TableRow>
            <TableCell>Claimed Amount</TableCell>
            <TableCell>
              <b>{data?.totalBill}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Approved Amount</TableCell>
            <TableCell>
              <b>{data?.approvedBill}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Remarks</TableCell>
            <TableCell>{data?.remarks}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>TA DA Status</TableCell>
            <TableCell>
              <Stack direction="row" alignItems="center">
                {status}
              </Stack>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Order Status</TableCell>
            <TableCell>
              <Stack direction="row" alignItems="center">
                {orderStatus}
              </Stack>
            </TableCell>
          </TableRow>
        </TableBody>
      </MuiTable>

      <Stack direction="row-reverse" sx={{ py: 3, gap: 2 }}>
        {['Super Admin'].includes(role) ? (
          <Button
            variant="contained"
            color="primary"
            startIcon={<BeenhereIcon />}
            onClick={() => onTaDaApprove(data)}>
            Approve TA DA
          </Button>
        ) : null}

        {['Super Admin'].includes(role) ? (
          <Button
            disabled={!data?.reasonForTravel?.includes('Work with SO/SR')}
            variant="contained"
            color="secondary"
            startIcon={<ShoppingCartIcon />}
            onClick={() => onOrderApprove(data)}>
            Approve Order
          </Button>
        ) : null}

        {['Super Admin'].includes(role) || id === data?.employee?.id ? (
          <>
            {/* <Button
              variant="contained"
              color="inherit"
              startIcon={<BorderColorIcon />}
              onClick={() => onEdit(data)}>
              Edit
            </Button> */}

            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => onDelete(data)}>
              Delete
            </Button>
          </>
        ) : null}
      </Stack>
    </TableContainer>
  );
}
