import React, { useEffect } from 'react';
import Layout from '../component/Layout';
// import DashCard from '../component/dashBoard/DashCard';
import ReportCard from '../component/dashBoard/ReportCard';
import useAuth from '../hooks/useAuth';
import AllForms from '../component/dashBoard/AllForms';
import InfoCard from '../component/dashBoard/InfoCard';
import useHeader from '../hooks/useHeader';

export default function Home() {
  const { role, Admin } = useAuth();
  const { setHeader } = useHeader();

  useEffect(() => {
    setHeader({ title: 'Reports' });
  }, []);

  return (
    <Layout>
      {/* <DashCard /> */}
      {['SO/SR', 'ASM', 'RSM', 'DSM'].includes(role) ? null : <ReportCard />}
      <br />
      {['Super Admin', ...Admin, 'ASM', 'RSM', 'DSM', 'SO/SR'].includes(
        role,
      ) && <AllForms />}
      {['Super Admin', ...Admin, 'ASM', 'RSM', 'DSM', 'SO/SR'].includes(
        role,
      ) && <InfoCard />}
      <br />
      <br />
      <br />
    </Layout>
  );
}
