import { useMemo } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
  Box,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import {
  ExpTable,
  ExpandTableCaption,
  ExpandTableContainer,
} from '../../assets/style/table/customTable';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';

export default function ExpandRow({ data }) {
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();

  const {
    data: transactions,
    isLoading,
    isError,
    error,
  } = useQuery(['collection-linked-transactions', data?.id], () => {
    const url = `/api/v1/transaction?populate=yes&limit=none&sort=-orderedAt&collectionRefs[elemMatch][eq]=${data?.id}`;
    return axiosPrivate.get(url).then((res) => {
      return res?.data?.data?.map((item) => {
        let cartonsO = 0;
        let packetsO = 0;

        let cartonsF = 0;
        let packetsF = 0;

        let cartonsT = 0;
        let packetsT = 0;

        item?.products?.forEach((p) => {
          cartonsO += Math.floor(p?.quantity / p?.quantityPerCarton);
          packetsO += p?.quantity % p?.quantityPerCarton;

          cartonsF += Math.floor(p?.freeQuantity / p?.quantityPerCarton);
          packetsF += p?.freeQuantity % p?.quantityPerCarton;

          const tq = p?.quantity + p?.freeQuantity;
          cartonsT += Math.floor(tq / p?.quantityPerCarton);
          packetsT += tq % p?.quantityPerCarton;
        });

        let orderedQuantity = `${cartonsO} ctn`;
        if (packetsO) orderedQuantity += ` ${packetsO} pcs`;

        let freeQuantity = `${cartonsF} ctn`;
        if (packetsF) freeQuantity += ` ${packetsF} pcs`;

        let totalQuantity = `${cartonsT} ctn`;
        if (packetsT) totalQuantity += ` ${packetsT} pcs`;

        item.orderedQuantity = orderedQuantity;
        item.freeQuantity = freeQuantity;
        item.totalQuantity = totalQuantity;

        let collectionIds = '';
        item?.collectionRefs?.forEach((i, index) => {
          if (index !== 0) collectionIds += ', ';
          collectionIds += i.substring(5, 8);
          collectionIds += i.substring(i.length - 3);
        });

        item.collectionIds = collectionIds.toLocaleUpperCase();

        return item;
      });
    });
  });

  const transactionsView = useMemo(() => {
    if (isLoading) {
      return (
        <Typography align="center" color="text.disabled" sx={{ py: 3 }}>
          Loading...
        </Typography>
      );
    }

    if (transactions?.length < 1) {
      return (
        <Typography align="center" sx={{ py: 3 }}>
          No linked transactions
        </Typography>
      );
    }

    return (
      <ExpandTableContainer>
        <ExpTable cellSpacing="0">
          <TableHead>
            <TableRow>
              <TableCell>Order Date</TableCell>
              <TableCell>Delivery Date</TableCell>
              <TableCell>Transaction Type</TableCell>
              <TableCell>Sender</TableCell>
              <TableCell>Receiver</TableCell>
              <TableCell>Payable Amount</TableCell>
              <TableCell>Ordered Qty.</TableCell>
              <TableCell>Free Qty.</TableCell>
              <TableCell>Total Qty.</TableCell>
              <TableCell>DO No.</TableCell>
              <TableCell>Trx. ID</TableCell>
              <TableCell>Linked Collections</TableCell>
              <TableCell>Check Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions?.map((t) => (
              <TableRow key={t?.id}>
                <TableCell>
                  {t?.orderedAt
                    ? dayjs(t?.orderedAt).format('DD/MM/YYYY')
                    : '-'}
                </TableCell>
                <TableCell>
                  {t?.deliveredAt
                    ? dayjs(t?.deliveredAt).format('DD/MM/YYYY')
                    : '-'}
                </TableCell>
                <TableCell>{t?.transactionType?.transactionType}</TableCell>
                <TableCell>{t?.sender?.nameCode}</TableCell>
                <TableCell>{t?.receiver?.nameCode}</TableCell>
                <TableCell>{t?.payableAmount}</TableCell>
                <TableCell>{t?.orderedQuantity}</TableCell>
                <TableCell>{t?.freeQuantity}</TableCell>
                <TableCell>{t?.totalQuantity}</TableCell>
                <TableCell>{t?.doNumber}</TableCell>
                <TableCell>{t?.shortId}</TableCell>
                <TableCell>{t?.collectionIds}</TableCell>
                <TableCell>
                  {t?.isProductsConfirmed ? (
                    <Tooltip title="Products Confirmed">
                      <CheckCircleIcon color="primary" />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Products Pending">
                      <RadioButtonUncheckedIcon color="primary" />
                    </Tooltip>
                  )}
                  {t?.isPaymentsConfirmed ? (
                    <Tooltip title="Payments Confirmed">
                      <CheckCircleIcon color="secondary" />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Payments Pending">
                      <RadioButtonUncheckedIcon color="secondary" />
                    </Tooltip>
                  )}
                  {t?.isDelivered ? (
                    <Tooltip title="Delivery Confirmed">
                      <CheckCircleIcon color="success" />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Delivery Pending">
                      <RadioButtonUncheckedIcon color="success" />
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </ExpTable>
      </ExpandTableContainer>
    );
  }, [transactions?.length, isLoading]);

  if (isError) {
    expired(error);
  }

  return (
    <>
      {/* <Stack
        direction={{ xs: 'column', md: 'row' }}
        p={2}
        justifyContent={'center'}
        gap={2}>
        <Box flexGrow={1} component={Paper} sx={{ width: '100%' }}>
          {caption ? (
            <ExpandTableCaption>General Details</ExpandTableCaption>
          ) : null}
          <ExpandTableContainer>
            <ExpTable>
              <TableRow>
                <TableCell
                  component="th"
                  style={{ background: 'grey', color: '#fff' }}>
                  Depositor
                </TableCell>
                <TableCell>
                  {data?.customer?.name} ({data?.customer?.code})
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  style={{ background: 'grey', color: '#fff' }}>
                  Depositor
                </TableCell>
                <TableCell>
                  {data?.customer?.name} ({data?.customer?.code})
                </TableCell>
              </TableRow>
            </ExpTable>
          </ExpandTableContainer>
        </Box>
      </Stack> */}
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        p={2}
        justifyContent={'center'}
        gap={2}
        className="ExpandTableMain">
        <Box flexGrow={1} component={Paper}>
          <caption style={caption}>General Details</caption>
          <table border="0" cellSpacing="0">
            <tr>
              <td>Collection ID</td>
              <td>:</td>
              <td>{data?.shortId}</td>
            </tr>
            <tr>
              <td>Depositor</td>
              <td>:</td>
              <td>
                {data?.customer?.name} ({data?.customer?.code})
              </td>
            </tr>
            <tr>
              <td>Post Under</td>
              <td>:</td>
              <td>
                {data?.postUnder?.name
                  ? `${data?.postUnder?.name} (${data?.postUnder?.code})`
                  : ''}
              </td>
            </tr>
            <tr>
              <td>Entry Amount</td>
              <td>:</td>
              <td>{data?.entryAmount}</td>
            </tr>
            <tr>
              <td>Approved Collection</td>
              <td>:</td>
              <td>{data?.approvedAmount}</td>
            </tr>
            <tr>
              <td>Incentive Amount</td>
              <td>:</td>
              <td>{data?.bonusAmount}</td>
            </tr>
            <tr>
              <td>Online Charge</td>
              <td>:</td>
              <td>{data?.costAmount}</td>
            </tr>
            <tr>
              <td>Total (Approved + Incentive + Charge)</td>
              <td>:</td>
              <td>{data?.totalAmount}</td>
            </tr>
            <tr>
              <td>Remarks</td>
              <td>:</td>
              <td>
                <p>{data?.remarks}</p>
              </td>
            </tr>
            <tr>
              <td>Full ID</td>
              <td>:</td>
              <td style={{ wordBreak: 'break-all' }}>{data?.id}</td>
            </tr>
          </table>
        </Box>
        <Box flexGrow={1} component={Paper}>
          <caption style={caption}>Payment Details</caption>
          <table border="0" cellSpacing="0">
            <tr>
              <td>Entry Time</td>
              <td>:</td>
              <td>
                {data?.dateOfEntry
                  ? dayjs(data.dateOfEntry).format('DD/MM/YYYY hh:mm A')
                  : ''}
              </td>
            </tr>
            <tr>
              <td>Deposit Date</td>
              <td>:</td>
              <td>
                {data?.dateOfDeposit
                  ? dayjs(data.dateOfDeposit).format('DD/MM/YYYY')
                  : ''}
              </td>
            </tr>
            <tr>
              <td>Clearance Date</td>
              <td>:</td>
              <td>
                {data?.dateOfClearance
                  ? dayjs(data.dateOfClearance).format('DD/MM/YYYY')
                  : ''}
              </td>
            </tr>
            <tr>
              <td>Payment Mode</td>
              <td>:</td>
              <td>{data?.paymentMode}</td>
            </tr>
            <tr>
              <td>Bank Name</td>
              <td>:</td>
              <td>{data?.bankName}</td>
            </tr>
            {data?.paymentMode === 'Bank' ? (
              <>
                <tr>
                  <td>Bank Branch</td>
                  <td>:</td>
                  <td>{data?.bankBranch}</td>
                </tr>
                <tr>
                  <td>Account Number</td>
                  <td>:</td>
                  <td>{data?.accountNumber}</td>
                </tr>
                <tr>
                  <td>Transaction Code</td>
                  <td>:</td>
                  <td>{data?.transactionID}</td>
                </tr>
              </>
            ) : (
              <>
                <tr>
                  <td>Account Number</td>
                  <td>:</td>
                  <td>{data?.accountNumber}</td>
                </tr>
                <tr>
                  <td>Transaction ID</td>
                  <td>:</td>
                  <td>{data?.transactionID}</td>
                </tr>
              </>
            )}
            <tr>
              <td>Reference</td>
              <td>:</td>
              <td>{data?.reference}</td>
            </tr>
          </table>
        </Box>
      </Stack>
      <Box sx={{ p: 2, width: '100%', flexGrow: 1 }}>
        <Box component={Paper}>
          <ExpandTableCaption>Linked Transactions</ExpandTableCaption>
          {transactionsView}
        </Box>
      </Box>
    </>
  );
}

const caption = {
  textAlign: 'center',
  display: 'block',
  color: '#fff',
  background: '#1976d2',
  padding: '5px',
};
