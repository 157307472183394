import React from 'react';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IconButton, Stack } from '@mui/material';
import { toast } from 'react-toastify';
import ConfirmModal from '../../../component/ConfirmModal';
import { SelectColumnFilterBool } from '../../../component/Table/SelectColumnFilter';
import Table from '../../../component/Table/Table';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useExpiredSession from '../../../hooks/useExpiredSession';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

function CustomerClass() {
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const location = useLocation();
  const navigate = useNavigate();

  const [deleteShow, setDeleteShow] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState('');

  const handleAddCustomerClass = () => {
    navigate('/settings/customer-class/add');
  };
  const handleEditCustomerClass = (id) => {
    navigate(`/settings/customer-class/edit/${id}`);
  };

  const handleDelete = () => {
    axiosPrivate
      .delete(`/api/v1/customer/class/${deleteId}`)
      .then((res) => {
        if (res.status === 204) {
          toast.success('Document was deleted');
          refetch();
        }
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      })
      .finally(() => {
        setDeleteId('');
        setDeleteShow(false);
      });
  };

  // get product category
  const { data, isLoading, isFetching, isError, error, refetch } = useQuery(
    'customer-type',
    () => {
      return axiosPrivate.get('/api/v1/customer/class');
    },
  );

  const COLUMNS = React.useMemo(
    () => [
      {
        Header: 'Grade',
        accessor: 'customerClass',
      },
      {
        Header: 'Discount %',
        accessor: 'discountPercent',
      },
      {
        Header: 'Gift Description',
        accessor: 'giftDescription',
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ value, row }) => {
          if (value.length > 40) {
            return `${row.original.description.slice(0, 40)}...`;
          }
          return row.original.description;
        },
      },
      {
        Header: 'Active',
        accessor: 'isActive',
        Cell: ({ value, row }) => {
          if (value) {
            return <CheckCircleIcon color="success" />;
          }
          return <HighlightOffIcon color="error" />;
        },
        Filter: SelectColumnFilterBool,
      },
      {
        Header: 'Action',
        id: 'Action',
        Cell: ({ row }) => {
          const handleDeleteShow = () => {
            setDeleteId(row.original.id);
            setDeleteShow(true);
          };
          return (
            <Stack direction="row" alignItems="center" spacing={2}>
              <IconButton
                onClick={() => handleEditCustomerClass(row.original.id)}
                className="Table_icon_btn">
                <BorderColorIcon />
              </IconButton>
              <IconButton
                onClick={handleDeleteShow}
                className="Table_icon_btn delete">
                <DeleteIcon />
              </IconButton>
            </Stack>
          );
        },
      },
    ],
    [],
  );

  if (isError) {
    expired(error);
  }

  return (
    <>
      {location?.pathname !== '/settings/customer-class' ? (
        <Outlet context={[refetch]} />
      ) : (
        <>
          <Table
            columns={COLUMNS}
            data={data?.data?.data || [{}]}
            filename="Customer_Class"
            addButtonTooltip="Add Customer Class"
            isLoading={isLoading || isFetching}
            onAddButtonClick={handleAddCustomerClass}
          />
          <ConfirmModal
            title="Are you sure?"
            subtitle="You won't be able to revert this!"
            show={deleteShow}
            onSave={handleDelete}
            onHide={() => {
              setDeleteId('');
              setDeleteShow(false);
            }}
            actionButtonLabel="Delete"
            actionButtonColor="error"
          />
        </>
      )}
    </>
  );
}

export default CustomerClass;
