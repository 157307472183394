import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Link, Outlet, useLocation } from 'react-router-dom';

export default function SrReport() {
  const location = useLocation();
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    if (location?.pathname === '/sr-report-entries') {
      setValue(0);
    }
    if (location?.pathname === '/sr-report-attendance') {
      setValue(1);
    }
  }, [location?.pathname]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value}>
          <Tab label="Entries" component={Link} to={'/sr-report-entries'} />
          <Tab
            label="Attendance"
            component={Link}
            to={'/sr-report-attendance'}
          />
        </Tabs>
      </Box>
      <Box pt={3}>
        <Outlet />
      </Box>
    </Box>
  );
}
