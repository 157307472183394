import { useQuery } from 'react-query';
import useAxiosPrivate from './useAxiosPrivate';
import { useState } from 'react';
import getRandomColorName from '../utils/getRandomColorName';

const useCollections = ({
  depositStartDate,
  depositEndDate,
  entryStartDate,
  entryEndDate,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const [pendingCount, setPendingCount] = useState(0);
  const {
    data: res,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
  } = useQuery(
    [
      'collections',
      depositStartDate,
      depositEndDate,
      entryStartDate,
      entryEndDate,
    ],
    () => {
      let url = `/api/v1/ledger?populate=yes&limit=none&type=collection&sort=-dateOfDeposit`;
      if (depositStartDate) url += `&dateOfDeposit[gte]=${depositStartDate}`;
      if (depositEndDate) url += `&dateOfDeposit[lte]=${depositEndDate}`;
      if (entryStartDate) url += `&dateOfEntry[gte]=${entryStartDate}`;
      if (entryEndDate) url += `&dateOfEntry[lte]=${entryEndDate}`;

      return axiosPrivate.get(url).then((res) => {
        const d = [];
        const depoCount = {};
        let pending = 0;

        res?.data?.data?.forEach((item) => {
          if (item?.approvedAmount <= 0) {
            pending += 1;
          }

          const depositor = item?.customer?.nameCode;

          if (depoCount[depositor]?.count) {
            depoCount[depositor].count += 1;
          } else {
            depoCount[depositor] = {
              count: 1,
              color: getRandomColorName(),
            };
          }

          if (typeof item.bankName === 'string' && item.bankName.length > 10) {
            item.bankNameX = item.bankName.match(/\b([A-Z])/g).join('');
          }

          // item.depositorName = `${depositor} <${depoCount[depositor]?.count}>`;

          d.push(item);
        });

        res?.data?.data?.forEach((item) => {
          const depositor = item?.customer?.nameCode;

          item.depositorName = `${depositor} <${depoCount[depositor]?.count}>`;
        });

        setPendingCount(pending);
        return { res: d, depoCount };
      });
    },
    { refetchInterval: 120000 },
  );

  return { res, isLoading, isFetching, isError, error, refetch, pendingCount };
};
export default useCollections;
