import React from 'react';
import {
  Box,
  Paper,
  Stack,
  Table,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';

export default function ExpandRowInfo({ data }) {
  return (
    <>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        p={2}
        justifyContent={'center'}
        gap={2}
        className="ExpandTableMain">
        <Box flexGrow={1} component={Paper}>
          <Typography sx={caption}>Basic Information</Typography>
          <Table cellPadding={0} cellSpacing={0} border={0}>
            <TableRow>
              <TableCell>
                <b>Status</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.isActive ? 'Active' : 'Inactive'} </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Name</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.name} </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Code</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.code} </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Remarks</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.remarks} </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>customerType</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.customerType?.customerType}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Location</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>
                {data?.territory?.name} ({data?.territory?.code})
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Customer Class</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.customerClass?.customerClass}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Starting Date</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>
                {data?.dateOfStarting
                  ? dayjs(data.dateOfStarting).format('DD/MM/YYYY')
                  : ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Closing Date</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>
                {data?.dateOfClosing
                  ? dayjs(data.dateOfClosing).format('DD/MM/YYYY')
                  : ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Description</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.description}</TableCell>
            </TableRow>
          </Table>
        </Box>
        <Box flexGrow={1} component={Paper}>
          <Typography sx={caption}>Contact Information</Typography>
          <Table cellPadding={0} cellSpacing={0} border={0}>
            <TableRow>
              <TableCell>
                <b>Personal Contact</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.personalContact} </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Email</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.email} </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Social Media</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>
                {data?.contact && data?.socialMedia
                  ? `${data?.contact} (${data?.socialMedia})`
                  : data?.contact && !data?.socialMedia
                  ? `${data?.contact}`
                  : ''}
              </TableCell>
            </TableRow>
          </Table>
        </Box>
      </Stack>
    </>
  );
}

const caption = {
  textAlign: 'center',
  display: 'block',
  color: '#fff',
  background: (theme) => theme.palette.primary.main,
  padding: '5px',
  fontSize: 14,
};
