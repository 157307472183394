import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from 'react';
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useHeader from '../../hooks/useHeader';
import useAuth from '../../hooks/useAuth';
import { toast } from 'react-toastify';
import {
  Autocomplete,
  Button,
  Container,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

function ModifySReportrDetails() {
  const navigate = useNavigate();
  const params = useParams();
  const [refetch] = useOutletContext();
  const axiosPrivate = useAxiosPrivate();
  const { setHeader } = useHeader();
  const { user, userId } = useAuth();

  const editId = params?.reportId;

  const [isLoading, setIsLoading] = React.useState(false);
  const [employeeList, setEmployeeList] = React.useState([]);
  const [employee, setEmployee] = React.useState({});
  const [reportDate, setReportDate] = React.useState(new Date());
  const [route, setRoute] = React.useState('');
  const [outletCount, setOutletCount] = React.useState(0);
  const [orderQuantity, setOrderQuantity] = React.useState(0);
  const [memoCount, setMemoCount] = React.useState(0);
  const [deliveryQuantity, setDeliveryQuantity] = React.useState(0);
  const [liftingQuantity, setLiftingQuantity] = React.useState(0);
  const [stockQuantity, setStockQuantity] = React.useState(0);
  const [orderValue, setOrderValue] = React.useState(0);
  const [deliveryValue, setDeliveryValue] = React.useState(0);

  React.useEffect(() => {
    if (editId) {
      setHeader({ title: 'Edit SR Report' });
    } else {
      setHeader({ title: 'Add SR Report' });
    }
  }, [editId]);

  React.useEffect(() => {
    if (editId) {
      setIsLoading(true);
      axiosPrivate
        .get(`/api/v1/sales-activity/${editId}?populate=yes`)
        .then((res) => {
          setEmployee(res?.data?.data?.employee);
          setReportDate(res?.data?.data?.reportDate);
          setRoute(res?.data?.data?.route);
          setOutletCount(res?.data?.data?.outletCount);
          setOrderQuantity(res?.data?.data?.orderQuantity);
          setMemoCount(res?.data?.data?.memoCount);
          setDeliveryQuantity(res?.data?.data?.deliveryQuantity);
          setLiftingQuantity(res?.data?.data?.liftingQuantity);
          setStockQuantity(res?.data?.data?.stockQuantity);
          setOrderValue(res?.data?.data?.orderValue);
          setDeliveryValue(res?.data?.data?.deliveryValue);
        })
        .catch((error) => toast.warn(error?.response?.data?.message))
        .finally(() => setIsLoading(false));
    }
  }, [editId]);

  React.useEffect(() => {
    axiosPrivate
      .get(
        `/api/v1/employee?limit=none&isActive=true&sort=code&parentTerritory=${user?.employee?.territory?.id}`,
      )
      .then((res) => {
        setEmployeeList(res?.data?.data);
      })
      .catch((error) => toast.warn(error?.response?.data?.message));
  }, [user?.employee?.territory?.id]);

  const handleSubmit = () => {
    setIsLoading(true);

    let method = 'post';
    let url = '/api/v1/sales-activity';
    const data = {
      recordedBy: userId,
      employee: employee?.id,
      reportDate,
      route,
      outletCount,
      orderQuantity,
      memoCount,
      deliveryQuantity,
      liftingQuantity,
      stockQuantity,
      orderValue,
      deliveryValue,
    };

    if (editId) {
      method = 'patch';
      url += `/${editId}`;
    }

    axiosPrivate({ method, url, data })
      .then((res) => {
        toast.success(res?.data?.message);
        refetch();
        navigate('/sr-report-entries');
      })
      .catch((error) => toast.warn(error?.response?.data?.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <Container maxWidth="sm">
      <IconButton
        component={Link}
        to="/sr-report-entries"
        aria-label="Go back"
        edge="start"
        sx={{ mb: 1 }}>
        <ArrowBackIcon />
      </IconButton>

      <Stack component={Paper} sx={{ px: 2, py: 3, gap: 3 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Report Date"
            disabled={true}
            disableFuture
            fullWidth
            inputFormat="DD/MM/YYYY"
            views={['month', 'year', 'day']}
            value={reportDate}
            onChange={(newValue) => setReportDate(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>

        <Autocomplete
          disabled={editId || isLoading || employeeList?.length < 1}
          fullWidth
          options={employeeList}
          value={employee}
          isOptionEqualToValue={(option, value) => value?.id === option?.id}
          onChange={(event, value) => setEmployee(value)}
          getOptionLabel={(option) => option?.nameCode || ''}
          renderInput={(params) => (
            <TextField {...params} label="Select SO/SR" />
          )}
        />

        <TextField
          disabled={isLoading}
          label="Route/Beat"
          type="text"
          value={route}
          onChange={(e) => setRoute(e.target.value)}
        />

        <TextField
          disabled={isLoading}
          label="Outlets Count"
          fullWidth
          type="number"
          onWheel={(e) => e.target.blur()}
          value={outletCount}
          onChange={(e) => setOutletCount(e.target.value)}
        />

        <TextField
          disabled={isLoading}
          label="Order Quantity"
          fullWidth
          type="number"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">cartons</InputAdornment>
            ),
          }}
          onWheel={(e) => e.target.blur()}
          value={orderQuantity}
          onChange={(e) => setOrderQuantity(e.target.value)}
        />

        <TextField
          disabled={isLoading}
          label="Order Value"
          fullWidth
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">Tk</InputAdornment>,
          }}
          onWheel={(e) => e.target.blur()}
          value={orderValue}
          onChange={(e) => setOrderValue(e.target.value)}
        />

        <TextField
          disabled={isLoading}
          label="Memos Count"
          fullWidth
          type="number"
          onWheel={(e) => e.target.blur()}
          value={memoCount}
          onChange={(e) => setMemoCount(e.target.value)}
        />

        <TextField
          disabled={isLoading}
          label="Delivery Quantity"
          fullWidth
          type="number"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">cartons</InputAdornment>
            ),
          }}
          onWheel={(e) => e.target.blur()}
          value={deliveryQuantity}
          onChange={(e) => setDeliveryQuantity(e.target.value)}
        />

        <TextField
          disabled={isLoading}
          label="Delivery Value"
          fullWidth
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">Tk</InputAdornment>,
          }}
          onWheel={(e) => e.target.blur()}
          value={deliveryValue}
          onChange={(e) => setDeliveryValue(e.target.value)}
        />

        <TextField
          disabled={isLoading}
          label="Lifting Quantity"
          fullWidth
          type="number"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">cartons</InputAdornment>
            ),
          }}
          onWheel={(e) => e.target.blur()}
          value={liftingQuantity}
          onChange={(e) => setLiftingQuantity(e.target.value)}
        />

        <TextField
          disabled={isLoading}
          label="Stock Quantity"
          fullWidth
          type="number"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">cartons</InputAdornment>
            ),
          }}
          onWheel={(e) => e.target.blur()}
          value={stockQuantity}
          onChange={(e) => setStockQuantity(e.target.value)}
        />

        <Button
          disabled={isLoading}
          fullWidth
          variant="contained"
          size="large"
          onClick={handleSubmit}>
          Submit
        </Button>
      </Stack>
    </Container>
  );
}

export default ModifySReportrDetails;
