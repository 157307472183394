import React, { useState } from 'react';
import { Button, Paper, Stack, TextField, Typography } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useExpiredSession from '../../../hooks/useExpiredSession';

function ConfirmDelivery() {
  const location = useLocation();
  const data = location?.state?.data;
  const navigate = useNavigate();
  const expired = useExpiredSession();
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [doNumber, setDoNumber] = useState(data?.doNumber || '');
  const [coNumber, setCoNumber] = useState(data?.coNumber || '');
  const [deliverymanName, setDeliverymanName] = useState(
    data?.deliverymanName || '',
  );
  const [deliverymanContact, setDeliverymanContact] = useState(
    data?.deliverymanContact || '',
  );
  const [vehicleNumber, setVehicleNumber] = useState(data?.vehicleNumber || '');
  const [deliveredAt, setDeliveredAt] = useState(
    data?.deliveredAt ? dayjs(data?.deliveredAt) : dayjs(),
  );

  const handleSubmit = () => {
    if (!doNumber) {
      toast.warn('DO Number is required');
      return;
    }
    setIsLoading(true);
    axiosPrivate
      .patch(`/api/v1/transaction/${data?.id}/confirm-delivery`, {
        doNumber,
        coNumber,
        deliverymanName,
        deliverymanContact,
        vehicleNumber,
        deliveredAt,
      })
      .then(() => {
        navigate('/transactions');
      })
      .catch((error) => {
        expired(error);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Stack
      component={Paper}
      direction="column"
      gap={3}
      p={2}
      sx={{ maxWidth: 400, margin: 'auto' }}>
      <Typography variant="h5">Confirm Delivery</Typography>
      <TextField
        type="text"
        label="DO Number"
        fullWidth
        value={doNumber}
        onChange={(e) => setDoNumber(e.target.value)}
      />
      <TextField
        type="text"
        label="CO Number"
        fullWidth
        value={coNumber}
        onChange={(e) => setCoNumber(e.target.value)}
      />
      <TextField
        type="text"
        label="Driver Name"
        fullWidth
        value={deliverymanName}
        onChange={(e) => setDeliverymanName(e.target.value)}
      />
      <TextField
        type="number"
        label="Driver Contact"
        fullWidth
        onWheel={(e) => e.target.blur()}
        value={deliverymanContact}
        onChange={(e) => setDeliverymanContact(e.target.value)}
      />
      <TextField
        type="text"
        label="Vehicle Number"
        fullWidth
        value={vehicleNumber}
        onChange={(e) => setVehicleNumber(e.target.value)}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          disableFuture
          fullWidth
          label="Delivery Time"
          inputFormat="DD/MM/YYYY hh:mm A"
          views={['month', 'year', 'day', 'hours', 'minutes']}
          value={deliveredAt}
          onChange={(newValue) => setDeliveredAt(newValue)}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      </LocalizationProvider>

      <Button
        fullWidth
        size="large"
        variant="contained"
        disabled={isLoading}
        onClick={handleSubmit}>
        Confirm Delivery
      </Button>
    </Stack>
  );
}

export default ConfirmDelivery;
