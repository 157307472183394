import React, { useCallback, useEffect, useMemo, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, IconButton, Stack, TextField, Typography } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import TableSkeleton from '../../component/Skeleton/TableSkeleton';
import ExpandTable from '../../component/Table/ExpandTable/ExpandTable';
import Table from '../../component/Table/Table';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import useHeader from '../../hooks/useHeader';

export default function FactoryStock() {
  const navigate = useNavigate();
  const { setHeader } = useHeader();
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const { role } = useAuth();

  const [startDate, setStartDate] = useState(
    dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'));

  useEffect(() => {
    setHeader({ title: 'Factory' });
  }, []);

  // get all factory Stock
  const { data, isLoading, isFetching, isError, error } = useQuery(
    ['factory_stock', startDate, endDate],
    () => {
      return axiosPrivate
        .get(
          `/api/v1/report/factory-stock?factoryId=6423ee3fdd3750601b1a1cf1&startDate=${startDate}&endDate=${endDate}`,
        )
        .then((res) =>
          res?.data?.data?.map((d) => {
            const startQuantity =
              d.availableStock.quantity +
              d.confirmedDeliveries.quantity -
              d.returns.quantity -
              d.productions.quantity;

            const endQuantity =
              d.pendingDeliveries.quantity - d.availableStock.quantity;

            d.startingStock = {
              quantity: startQuantity > 0 ? startQuantity : 0,
            };
            d.endingStock = {
              quantity: endQuantity > 0 ? endQuantity : 0,
            };

            const qpc = d.quantityPerCarton;

            const dx = { nameCodeQPC: `${d.name} (${d.code}) [${qpc}]` };

            Object.entries(d).forEach(([key, value]) => {
              if (typeof value !== 'object') {
                dx[key] = value;
              } else {
                dx[key] = { id: value.id || '' };

                let quantityDisplay = '-';

                if (value.quantity) {
                  const cartons = Math.floor(value.quantity / qpc);
                  const pieces = value.quantity % qpc;

                  quantityDisplay = `${cartons} ctn`;
                  if (pieces) quantityDisplay += ` ${pieces} pcs`;
                }

                dx[key].quantityDisplay = quantityDisplay;

                if (value.individual) {
                  dx[key].individual = Object.entries(value.individual).map(
                    ([id, i]) => {
                      const totalCartons = Math.floor(i.quantity / qpc);
                      const totalPieces = i.quantity % qpc;

                      const orderedCartons = Math.floor(
                        i.orderedQuantity / qpc,
                      );
                      const orderedPieces = i.orderedQuantity % qpc;

                      const freeCartons = Math.floor(i.freeQuantity / qpc);
                      const freePieces = i.freeQuantity % qpc;

                      let qd = '-';
                      let oQd = '-';
                      let fQd = '-';

                      if (totalCartons || totalPieces) {
                        qd = `${totalCartons} ctn`;
                        if (totalPieces) qd += ` ${totalPieces} pcs`;
                      }

                      if (orderedCartons || orderedPieces) {
                        oQd = `${orderedCartons} ctn`;
                        if (orderedPieces) oQd += ` ${orderedPieces} pcs`;
                      }

                      if (freeCartons || freePieces) {
                        fQd = `${freeCartons} ctn`;
                        if (freePieces) fQd += ` ${freePieces} pcs`;
                      }

                      return {
                        id,
                        ...i,
                        quantityDisplay: qd,
                        ordredQuantityDisplay: oQd,
                        freeQuantityDisplay: fQd,
                      };
                    },
                  );
                }
              }
            });

            return dx;
          }),
        );
    },
    { refetchInterval: 120000 },
  );

  const handleAddShow = () => {
    navigate(`/stock/add?customer=6423ee3fdd3750601b1a1cf1&type=production`);
  };

  if (isError) {
    expired(error);
  }

  const linkedCellView = useCallback(
    (id, value) =>
      ['Super Admin'].includes(role) ? (
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            '&:hover > .MuiIconButton-root': { visibility: 'visible' },
          }}>
          {value}

          <IconButton
            component={Link}
            to={`/stock/edit/${id}`}
            aria-label="edit stock"
            size="small"
            edge="end"
            sx={{ visibility: 'hidden' }}>
            <EditIcon fontSize="inherit" />
          </IconButton>
        </Stack>
      ) : (
        value
      ),
    [role],
  );

  const COLUMNS = useMemo(
    () => [
      {
        Header: () => null,
        id: 'expander',
        Cell: ({ row }) => (
          <>
            {row.isExpanded ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </>
        ),
      },
      {
        Header: 'Product',
        accessor: 'nameCodeQPC',
        Cell: ({ row, value }) => {
          const r = row?.original;
          return (
            <span
              title={`Name : ${r?.name}\nCode : ${r?.code}\nCategory : ${r?.productCategory}\nCarton size : ${r?.quantityPerCarton} pcs`}>
              {value}
            </span>
          );
        },
      },
      {
        Header: 'Opening Stock',
        accessor: 'startingStock.quantityDisplay',
        aggregateCtn: 'sum',
      },
      {
        Header: 'Productions',
        accessor: 'productions.quantityDisplay',
        aggregateCtn: 'sum',
      },
      {
        Header: 'Fresh Returns',
        accessor: 'returns.quantityDisplay',
        aggregateCtn: 'sum',
      },
      {
        Header: 'Confirmed DO',
        accessor: 'confirmedDeliveries.quantityDisplay',
        aggregateCtn: 'sum',
      },
      {
        Header: 'Available Stock',
        accessor: 'availableStock.quantityDisplay',
        aggregateCtn: 'sum',
        Cell: ({ row, value }) =>
          linkedCellView(row?.original?.availableStock?.id, value),
      },
      {
        Header: 'Pending DO',
        accessor: 'pendingDeliveries.quantityDisplay',
        aggregateCtn: 'sum',
      },
      {
        Header: 'Need Production',
        accessor: 'endingStock.quantityDisplay',
        Cell: ({ value }) => (
          <span style={{ color: value !== '-' ? 'red' : 'inherit' }}>
            {value}
          </span>
        ),
        aggregateCtn: 'sum',
      },
    ],
    [linkedCellView],
  );

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        // useFlexGap
        sx={{
          // flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'flex-end',
          py: 2,
        }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="From"
            inputFormat="DD/MM/YYYY"
            value={startDate}
            onChange={(newValue) =>
              setStartDate(dayjs(newValue).format('YYYY-MM-DD'))
            }
            renderInput={(params) => <TextField {...params} size="small" />}
          />

          <DesktopDatePicker
            label="To"
            inputFormat="DD/MM/YYYY"
            value={endDate}
            onChange={(newValue) =>
              setEndDate(dayjs(newValue).format('YYYY-MM-DD'))
            }
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </LocalizationProvider>
      </Stack>

      <Table
        columns={COLUMNS}
        data={data || [{}]}
        filename="Factory Stock"
        addButtonTooltip="Add Production"
        onAddButtonClick={handleAddShow}
        isLoading={isLoading || isFetching}
        expandRow={ExpandRowInfo}
        hasDrawer
        hidePagination
      />
    </>
  );
}

function ExpandRowInfo({ data }) {
  function Header() {
    return (
      <Typography pb={2}>
        <Typography component={'b'} fontWeight="bold">
          {data?.name} {`(${data?.code})`}
        </Typography>{' '}
        - {data?.productCategory}
        {`[${data?.quantityPerCarton}]`}
      </Typography>
    );
  }
  if (
    !data?.productions?.individual?.length &&
    !data?.productions?.returns?.length &&
    !data?.confirmedDeliveries?.individual?.length &&
    !data?.pendingDeliveries?.individual?.length
  ) {
    return (
      <>
        <Header />
        <Typography>No data Found</Typography>
      </>
    );
  }

  const productionColumns = [
    {
      label: 'Date',
      key: 'recordedAt',
      render: (value) => dayjs(value).format('DD/MM/YYYY'),
    },
    {
      label: 'Quantity',
      key: 'quantityDisplay',
    },
    {
      label: 'Batch No.',
      key: 'batchNo',
    },
    {
      label: 'Entered By',
      key: 'recordedBy',
    },
  ];
  const returnsColumns = [
    {
      label: 'deliveredAt',
      key: 'deliveredAt',
      render: (value) => dayjs(value).format('DD/MM/YYYY'),
    },
    {
      label: 'Quantity',
      key: 'quantityDisplay',
    },
    {
      label: 'Customer',
      key: 'customerNameCode',
    },
  ];
  const confirmedDeliveriesColumns = [
    {
      label: 'Order Date',
      key: 'orderedAt',
      render: (value) => dayjs(value).format('DD/MM/YYYY'),
    },
    {
      label: 'Delivery Date',
      key: 'deliveredAt',
      render: (value) => dayjs(value).format('DD/MM/YYYY'),
    },
    {
      label: 'Customer',
      key: 'customerNameCode',
    },
    {
      label: 'Ordered',
      key: 'ordredQuantityDisplay',
    },
    {
      label: 'Free',
      key: 'freeQuantityDisplay',
    },
    {
      label: 'Total',
      key: 'quantityDisplay',
    },
    {
      label: 'DO Number',
      key: 'doNumber',
    },
  ];

  const pendingDeliveriesColumns = [
    {
      label: 'Order Date',
      key: 'orderedAt',
      render: (value) => dayjs(value).format('DD/MM/YYYY'),
    },
    {
      label: 'Confirm Date',
      key: 'productsConfirmedAt',
      render: (value) => dayjs(value).format('DD/MM/YYYY'),
    },
    {
      label: 'Customer',
      key: 'customerNameCode',
    },
    {
      label: 'Ordered',
      key: 'ordredQuantityDisplay',
    },
    {
      label: 'Free',
      key: 'freeQuantityDisplay',
    },
    {
      label: 'Total',
      key: 'quantityDisplay',
    },
  ];

  const productionData = data?.productions?.individual;
  const returnsData = data?.returns?.individual;
  const confirmedDeliveriesData = data?.confirmedDeliveries?.individual;
  const pendingDeliveriesData = data?.pendingDeliveries?.individual;

  return (
    <Box>
      <Header />
      <Stack gap={3}>
        {data?.productions?.individual?.length ? (
          <ExpandTable
            caption="Productions"
            columns={productionColumns}
            data={productionData}
          />
        ) : null}

        {data?.returns?.individual?.length ? (
          <ExpandTable
            caption="Fresh Returns"
            columns={returnsColumns}
            data={returnsData}
          />
        ) : null}

        {data?.confirmedDeliveries?.individual?.length ? (
          <ExpandTable
            caption="Confirmed Deliveries"
            columns={confirmedDeliveriesColumns}
            data={confirmedDeliveriesData}
          />
        ) : null}

        {data?.pendingDeliveries?.individual?.length ? (
          <ExpandTable
            caption="Pending Deliveries"
            columns={pendingDeliveriesColumns}
            data={pendingDeliveriesData}
          />
        ) : null}
      </Stack>
    </Box>
  );
}
