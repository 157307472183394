import { useMemo, useState } from 'react';
import {
  Box,
  Button,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import {
  ExpTable,
  ExpandTableCaption,
  ExpandTableContainer,
} from '../../../assets/style/table/customTable';
import useAuth from '../../../hooks/useAuth';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useExpiredSession from '../../../hooks/useExpiredSession';
import roundToDigit from '../../../utils/roundToDigit';

export default function ExpandRowInfo({ data, refetch, hideActions }) {
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const { role, Admin } = useAuth();

  const priceType = data?.transactionType?.priceType;
  let priceTypeDisplay;
  switch (priceType) {
    case 'dealerPrice':
      priceTypeDisplay = 'DP / CTN';
      break;
    case 'dsrPrice':
      priceTypeDisplay = 'DSRP / CTN';
      break;
    case 'tradePrice':
      priceTypeDisplay = 'TP / CTN';
      break;
    case 'maxRetailPrice':
      priceTypeDisplay = 'MRP / CTN';
      break;
    default:
      priceTypeDisplay = null;
      break;
  }

  const [allOrder, setAllOrder] = useState('');
  const [allFree, setAllFree] = useState('');
  const [allTotal, setAllTotal] = useState('');
  const [allFreePrice, setAllFreePrice] = useState(0);
  const [allTotalPrice, setAllTotalPrice] = useState(0);

  const {
    data: collections,
    isLoading,
    isError,
    error,
  } = useQuery(['transaction-linked-collections', data?.collectionIds], () => {
    let url = '/api/v1/ledger?populate=yes&limit=none&sort=-dateOfDeposit';

    if (data?.collectionRefs?.length > 0) {
      data?.collectionRefs?.forEach((i, index) => {
        url += `&_id[in][${index}]=${i}`;
      });
    } else {
      url += '&type=NOTHING';
    }

    return axiosPrivate.get(url).then((res) => res?.data?.data);
  });

  const products = useMemo(() => {
    let allOrderCartons = 0;
    let allOrderPieces = 0;
    let allFreeCartons = 0;
    let allFreePieces = 0;
    let allTotalCartons = 0;
    let allTotalPieces = 0;
    let allFreePriceX = 0;
    let allTotalPriceX = 0;

    const allTotal = data?.products?.reduce(
      (a, p) => a + p?.quantity + p?.freeQuantity,
      0,
    );

    const p = data?.products?.map((p) => {
      // prices
      const freePrice = p?.freeQuantity * p[priceType];
      allFreePriceX += freePrice;
      allTotalPriceX += p?.totalPrice;

      // ordered quantity
      const orderCartons = Math.floor(p?.quantity / p?.quantityPerCarton);
      const orderPieces = p?.quantity % p?.quantityPerCarton;

      allOrderCartons += orderCartons;
      allOrderPieces += orderPieces;

      let quantityDisplay = `${orderCartons} ctn`;
      if (orderPieces > 0) quantityDisplay += ` ${orderPieces} pcs`;

      // free quantity
      const freeCartons = Math.floor(p?.freeQuantity / p?.quantityPerCarton);
      const freePieces = p?.freeQuantity % p?.quantityPerCarton;

      allFreeCartons += freeCartons;
      allFreePieces += freePieces;

      let freeQuantityDisplay = `${freeCartons} ctn`;
      if (freePieces > 0) freeQuantityDisplay += ` ${freePieces} pcs`;

      // total quantity
      const totalCartons = Math.floor(
        (p?.quantity + p?.freeQuantity) / p?.quantityPerCarton,
      );
      const totalPieces =
        (p?.quantity + p?.freeQuantity) % p?.quantityPerCarton;

      allTotalCartons += totalCartons;
      allTotalPieces += totalPieces;

      let totalQuantityDisplay = `${totalCartons} ctn`;
      if (totalPieces > 0) totalQuantityDisplay += ` ${totalPieces} pcs`;

      return (
        <TableRow key={data?.id + p?.product?.code}>
          <TableCell>{p?.product?.nameCode}</TableCell>
          {priceTypeDisplay && (
            <TableCell style={{ textAlign: 'end' }}>
              {roundToDigit(p[priceType] * p?.quantityPerCarton)}
            </TableCell>
          )}
          <TableCell>{quantityDisplay}</TableCell>
          <TableCell>{freeQuantityDisplay}</TableCell>
          <TableCell>{totalQuantityDisplay}</TableCell>
          <TableCell style={{ textAlign: 'end' }}>
            {roundToDigit(((p?.quantity + p?.freeQuantity) / allTotal) * 100)}%
          </TableCell>
          <TableCell style={{ textAlign: 'end' }}>
            {roundToDigit(p?.totalPrice)}
          </TableCell>
          <TableCell style={{ textAlign: 'end' }}>
            {roundToDigit(freePrice)}
          </TableCell>
        </TableRow>
      );
    });

    let allOrderDisplay = `${allOrderCartons} ctn`;
    if (allOrderPieces > 0) allOrderDisplay += ` ${allOrderPieces} pcs`;

    let allFreeDisplay = `${allFreeCartons} ctn`;
    if (allFreePieces > 0) allFreeDisplay += ` ${allFreePieces} pcs`;

    let allTotalDisplay = `${allTotalCartons} ctn`;
    if (allTotalPieces) allTotalDisplay += ` ${allTotalPieces} pcs`;

    setAllOrder(allOrderDisplay);
    setAllFree(allFreeDisplay);
    setAllTotal(allTotalDisplay);
    setAllFreePrice(roundToDigit(allFreePriceX));
    setAllTotalPrice(roundToDigit(allTotalPriceX));

    return p;
  }, [data?.products?.length, priceType, priceTypeDisplay]);

  const collectionsView = useMemo(() => {
    if (isLoading) {
      return (
        <Typography align="center" color="text.disabled" sx={{ py: 3 }}>
          Loading...
        </Typography>
      );
    }

    if (collections?.length < 1) {
      return (
        <Typography align="center" sx={{ py: 3 }}>
          No linked collections
        </Typography>
      );
    }

    const totalCollection = collections?.reduce(
      (accumulator, i) => accumulator + (i?.approvedAmount + i?.costAmount),
      0,
    );

    return (
      <ExpandTableContainer>
        <ExpTable cellSpacing="0">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Deposit Date</TableCell>
              <TableCell>Depositor</TableCell>
              <TableCell>Post Under</TableCell>
              <TableCell>Approved Amount</TableCell>
              <TableCell>Online Charge</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {collections?.map((i) => (
              <TableRow key={i?.id}>
                <TableCell>{i?.shortId}</TableCell>
                <TableCell>
                  {dayjs(i?.dateOfDeposit).format('DD/MM/YYYY')}
                </TableCell>
                <TableCell>{i?.customer?.nameCode}</TableCell>
                <TableCell>{i?.postUnder?.nameCode}</TableCell>
                <TableCell>{i?.approvedAmount}</TableCell>
                <TableCell>{i?.costAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableRow>
            <TableCell colSpan={4}>
              <b>Total collection</b>
            </TableCell>
            <TableCell colSpan={2}>
              <b>{totalCollection}</b>
            </TableCell>
          </TableRow>
        </ExpTable>
      </ExpandTableContainer>
    );
  }, [isLoading, collections]);

  if (isError) {
    expired(error);
  }

  return (
    <>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent={'center'}
        gap={2}
        p={2}>
        <Box flexGrow={1} component={Paper} overflow="hidden">
          <caption style={caption}>General Details</caption>
          <Box
            sx={{ width: '100%', overflow: 'auto' }}
            className="ExpandTableMain">
            <table cellSpacing="0">
              <tr>
                <td>
                  <b>Transaction ID</b>
                </td>
                <td>:</td>
                <td>{data?.shortId}</td>
              </tr>
              <tr>
                <td>
                  <b>Transaction Type</b>
                </td>
                <td>:</td>
                <td>{data?.transactionType?.transactionType}</td>
              </tr>
              <tr>
                <td>
                  <b>Function</b>
                </td>
                <td>:</td>
                <td
                  style={{
                    textTransform: 'capitalize',
                  }}>{`${data?.transactionType?.function} (${data?.transactionType?.productCondition})`}</td>
              </tr>
              <tr>
                <td>
                  <b>Sender</b>
                </td>
                <td>:</td>
                <td>{data?.sender?.nameCode}</td>
              </tr>
              <tr>
                <td>
                  <b>Receiver</b>
                </td>
                <td>:</td>
                <td>{data?.receiver?.nameCode}</td>
              </tr>
              <tr>
                <td>
                  <b>Recorded By</b>
                </td>
                <td>:</td>
                <td>{data?.recordedBy?.nameCode}</td>
              </tr>
              <tr>
                <td>
                  <b>Linked Collections ID</b>
                </td>
                <td>:</td>
                <td>{data?.collectionIds}</td>
              </tr>
              <tr>
                <td>
                  <b>Invoice Amount</b>
                </td>
                <td>:</td>
                <td>{data?.invoiceAmount}</td>
              </tr>
              <tr>
                <td>
                  <b>Discount Amount</b>
                </td>
                <td>:</td>
                <td>{data?.discountAmount}</td>
              </tr>
              <tr>
                <td>
                  <b>Discount Percent</b>
                </td>
                <td>:</td>
                <td>{data?.discountPercent}</td>
              </tr>
              <tr>
                <td>
                  <b>Payable Amount</b>
                </td>
                <td>:</td>
                <td>{data?.payableAmount}</td>
              </tr>
              <tr>
                <td>
                  <b>Paid Amount</b>
                </td>
                <td>:</td>
                <td>{data?.paidAmount}</td>
              </tr>
              <tr>
                <td>
                  <b>Order Time</b>
                </td>
                <td>:</td>
                <td>{dayjs(data?.orderedAt).format('DD/MM/YYYY hh:mm A')}</td>
              </tr>
              <tr>
                <td>
                  <b>Products Confirm Time</b>
                </td>
                <td>:</td>
                <td>
                  {data?.isProductsConfirmed
                    ? dayjs(data?.productsConfirmedAt).format(
                        'DD/MM/YYYY hh:mm A',
                      )
                    : ''}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Payments Confirm Time</b>
                </td>
                <td>:</td>
                <td>
                  {data?.isPaymentsConfirmed
                    ? dayjs(data?.paymentsConfirmedAt).format(
                        'DD/MM/YYYY hh:mm A',
                      )
                    : ''}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Delivery Time</b>
                </td>
                <td>:</td>
                <td>
                  {data?.isDelivered
                    ? dayjs(data?.deliveredAt).format('DD/MM/YYYY hh:mm A')
                    : ''}
                </td>
              </tr>
              <tr>
                <td>
                  <b>DO Number</b>
                </td>
                <td>:</td>
                <td>{data?.doNumber}</td>
              </tr>
              <tr>
                <td>
                  <b>CO Number</b>
                </td>
                <td>:</td>
                <td>{data?.coNumber}</td>
              </tr>
              <tr>
                <td>
                  <b>Driver Name</b>
                </td>
                <td>:</td>
                <td>{data?.deliverymanName}</td>
              </tr>
              <tr>
                <td>
                  <b>Driver Contact</b>
                </td>
                <td>:</td>
                <td>{data?.deliverymanContact}</td>
              </tr>
              <tr>
                <td>
                  <b>Vehicle Number</b>
                </td>
                <td>:</td>
                <td>{data?.vehicleNumber}</td>
              </tr>
              <tr>
                <td>
                  <b>Full ID</b>
                </td>
                <td>:</td>
                <td>{data?.id}</td>
              </tr>
            </table>
          </Box>
        </Box>
        <Box flexGrow={2} component={Paper} overflow="hidden">
          <Box sx={{ width: '100%', flexGrow: 1 }}>
            <ExpandTableCaption>Products Details</ExpandTableCaption>
            <ExpandTableContainer>
              <ExpTable cellSpacing="0">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    {priceTypeDisplay && (
                      <TableCell>{priceTypeDisplay}</TableCell>
                    )}
                    <TableCell>Ordered</TableCell>
                    <TableCell>Free</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>SKU %</TableCell>
                    <TableCell>Payable &#2547;</TableCell>
                    <TableCell>Free &#2547;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products}
                  <TableRow>
                    <TableCell colSpan={priceTypeDisplay ? 2 : 1}>
                      <b>Total</b>
                    </TableCell>
                    <TableCell>
                      <b>{allOrder}</b>
                    </TableCell>
                    <TableCell>
                      <b>{allFree}</b>
                    </TableCell>
                    <TableCell>
                      <b>{allTotal}</b>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell style={{ textAlign: 'end' }}>
                      <b>{allTotalPrice}</b>
                    </TableCell>
                    <TableCell style={{ textAlign: 'end' }}>
                      <b>{allFreePrice}</b>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </ExpTable>
            </ExpandTableContainer>
          </Box>
          <Box sx={{ mt: 4, width: '100%', flexGrow: 1 }}>
            <ExpandTableCaption>Linked Collections</ExpandTableCaption>
            {collectionsView}
          </Box>
        </Box>
      </Stack>

      {hideActions ? null : (
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          sx={{ p: 2, gap: 2, alignItems: 'center', justifyContent: 'center' }}>
          {['Super Admin', ...Admin, 'DO', 'Accounts'].includes(role) ? (
            <Button
              component={Link}
              to="/transactions/link-collections"
              state={{ data: data }}
              variant="outlined"
              disabled={data?.isPaymentsConfirmed}>
              Link Collections
            </Button>
          ) : null}

          {['Super Admin', ...Admin, 'DO'].includes(role) ? (
            <Button
              component={Link}
              to={`/transactions/edit/${data?.id}`}
              variant="outlined"
              disabled={data?.isPaymentsConfirmed || data?.isDelivered}>
              Confirm Product
            </Button>
          ) : null}

          {['Super Admin', ...Admin, 'Accounts'].includes(role) ? (
            <Button
              component={Link}
              to="/transactions/confirm-payment"
              state={{ data: data }}
              variant="outlined"
              disabled={
                data?.isPaymentsConfirmed || !data?.isProductsConfirmed
              }>
              Confirm Payment
            </Button>
          ) : null}

          {['Super Admin', ...Admin, 'DO'].includes(role) ? (
            <Button
              component={Link}
              to="/transactions/confirm-delivery"
              state={{ data: data }}
              variant="outlined"
              disabled={
                data?.isDelivered ||
                !(data?.isProductsConfirmed && data?.isPaymentsConfirmed)
              }>
              Confirm Delivery
            </Button>
          ) : null}
        </Stack>
      )}
    </>
  );
}

// style
const caption = {
  textAlign: 'center',
  display: 'block',
  color: '#fff',
  background: '#1976d2',
  padding: '5px',
};
