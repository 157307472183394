import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DvrIcon from '@mui/icons-material/Dvr';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import StoreIcon from '@mui/icons-material/Store';
import { Stack } from '@mui/material';
import Card from './Card';
import DashTitle from './DashTitle';

const iconStyle = {
  fontSize: '50px',
};

const CardInfo = [
  {
    title: 'SKU Contribution',
    url: 'report/sku-contribution',
    icon: <LocalMallIcon sx={iconStyle} />,
  },
  {
    title: 'Customer Activity',
    url: 'report/cust-activity',
    icon: <DvrIcon sx={iconStyle} />,
  },
  {
    title: 'Customer Ledger',
    url: 'report/cust-ledger',
    icon: <AttachMoneyIcon sx={iconStyle} />,
  },
  {
    title: 'Cust Collection Report M-M',
    url: 'report/cust-collection',
    icon: <AttachMoneyIcon sx={iconStyle} />,
  },
  {
    title: 'DO Section',
    url: 'report/do-section',
    icon: <LocalShippingIcon sx={iconStyle} />,
  },
  {
    title: 'Cust CTN report M-M',
    url: 'report/cust-stock',
    icon: <InventoryIcon sx={iconStyle} />,
  },
  {
    title: 'HQ Wise SKU',
    url: 'report/hq-wise-sku',
    icon: <InventoryIcon sx={iconStyle} />,
  },
  {
    title: 'ASM WISE ACTIVE DEALERS',
    url: 'report/asm-wise-active-customer',
    icon: <StoreIcon sx={iconStyle} />,
  },
  {
    title: 'Sales Manager Attendance',
    url: 'report/employee-attendance',
    icon: <RecentActorsIcon sx={iconStyle} />,
  },
  {
    title: 'Sales Representative Attendance',
    url: '/sr-report-attendance',
    icon: <RecentActorsIcon sx={iconStyle} />,
  },
  {
    title: 'National IMS Report',
    url: 'report/national-ims',
    icon: <ShoppingCartCheckoutIcon sx={iconStyle} />,
  },
  {
    title: 'National Audited IMS Report',
    url: 'report/national-audited-ims',
    icon: <SpellcheckIcon sx={iconStyle} />,
  },
  {
    title: 'Competitor Category Sales',
    url: 'report/company-category-sales',
    icon: <RequestQuoteIcon sx={iconStyle} />,
  },
];

export default function ReportCard() {
  return (
    <>
      <DashTitle title={'All Reports'} />
      <Stack
        direction="row"
        justifyContent="center"
        mt={3}
        sx={{ flexWrap: 'wrap', gap: '20px' }}>
        {/* <Card /> */}
        {CardInfo.map((info) => (
          <Card key={info.url} info={info} />
        ))}
      </Stack>
    </>
  );
}
