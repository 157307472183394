import React, { useEffect, useMemo, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import dayjs from 'dayjs';
// import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import TableSkeleton from '../../../../component/Skeleton/TableSkeleton';
import {
  DateRangeColumnFilter,
  MultiSelectColumnFilter,
  SelectColumnFilter,
} from '../../../../component/Table/SelectColumnFilter';
import Table from '../../../../component/Table/Table';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import useExpiredSession from '../../../../hooks/useExpiredSession';
import ExpandRow from '../../../Collections/ExpandRow';
import CustomerProfileHeader from '../CustomerProfileHeader/CustomerProfileHeader';

function Ledger() {
  const param = useParams();
  const cID = param?.customerID;
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axiosPrivate
      .get(`/api/v1/customer/${cID}/ledger`)
      .then((res) => {
        setData(res?.data?.data || []);
      })
      .catch((error) => {
        expired(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [cID]);

  const COLUMNS = useMemo(
    () => [
      {
        Header: () => null,
        id: 'expander',
        Cell: ({ row }) => {
          return (
            <>
              {row.isExpanded ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </>
          );
        },
      },
      {
        id: 'ID',
        Header: 'Transaction Ref',
        accessor: 'transactionRef',
        hidden: true,
      },
      {
        Header: 'Time',
        accessor: 'dateOfDeposit',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY hh:mm A');
          return value;
        },
        // Filter: SelectColumnFilterDate,
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Amount',
        accessor: 'totalAmount',
        Cell: ({ value }) => (
          <span style={{ color: value < 0 ? 'red' : 'green' }}>{value}</span>
        ),
      },
      {
        Header: 'Type',
        accessor: 'type',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Bank Name',
        accessor: 'bankName',
      },
      {
        Header: 'Account Number',
        accessor: 'accountNumber',
      },
    ],
    [],
  );

  // if (isError) {
  //   expired(error);
  // }

  return (
    <>
      <CustomerProfileHeader cID={cID} />
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <Table
          columns={COLUMNS}
          data={data}
          filename="Ledger"
          addButtonTooltip="Add Collection"
          hideAddIcon
          expandRow={ExpandRow}
        />
      )}
    </>
  );
}

export default Ledger;
