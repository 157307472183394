import React, { useEffect, useMemo, useState } from 'react';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Icon,
  IconButton,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmModal from '../../component/ConfirmModal';
import Modal from '../../component/Modals';
import TableSkeleton from '../../component/Skeleton/TableSkeleton';
import {
  DateRangeColumnFilter,
  MultiSelectColumnFilter,
} from '../../component/Table/SelectColumnFilter';
import StickyTable from '../../component/Table/StickyTable';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import getRandomColorName from '../../utils/getRandomColorName';
import useHeader from '../../hooks/useHeader';

function ExitingDealerInformation() {
  const { role, userId, Admin } = useAuth();
  const { setHeader } = useHeader();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();

  const [verifyShow, setVerifyShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);

  const [status, setStatus] = useState('pending');
  const [phoneCount, setPhoneCount] = useState({});
  const [phoneCountvp, setPhoneCountvp] = useState({});

  const [verifyData, setVerifyData] = useState({});
  const [deleteId, setDeleteId] = useState('');

  const handleEditShow = (data) => {
    navigate('/exdealerform', { state: data });
  };

  const handleVerifyShow = (data) => {
    setStatus(data.status);
    setVerifyData(data);
    setVerifyShow(true);
  };

  const handleDeleteShow = (id) => {
    setDeleteId(id);
    setDeleteShow(true);
  };

  const handleVerify = () => {
    axiosPrivate
      .patch(`/api/v1/exdealer/${verifyData.id}`, { status })
      .then((res) => {
        if (res.status === 200) {
          toast.success('Updated successfully');
          refetch();
          setVerifyShow(false);
        }
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      });
  };

  const handleDelete = () => {
    axiosPrivate
      .delete(`/api/v1/exdealer/${deleteId}`)
      .then((res) => {
        if (res.status === 204) {
          toast.success('Document was deleted');
          refetch();
          setDeleteShow(false);
        }
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      });
  };

  const handleTaDa = (data) => {
    navigate('/tadaform', {
      state: {
        exDealerVisitRef: data?.id,
        travelDate: data?.visitDate,
        reasonForTravel: 'Existing dealer visit',
        region: data?.region,
        headquarter: data?.hq,
        point: data?.pointTown,
        latitude: data?.latitude,
        longitude: data?.longitude,
        from: 'FORM',
      },
    });
  };

  // get Dealer Information
  const { data, isLoading, isError, error, refetch } = useQuery(
    'exdealerinfo',
    () => {
      if (['ASM', 'RSM', 'DSM', 'SO/SR'].includes(role)) {
        return axiosPrivate.get(
          `/api/v1/exdealer?populate=yes&limit=none&sort=-createdAt&hq=${localStorage.getItem(
            'hqId',
          )}`,
        );
      }
      return axiosPrivate.get(
        '/api/v1/exdealer?populate=yes&limit=none&sort=-createdAt',
      );
    },
  );

  useEffect(() => {
    setHeader({ title: 'Existing Dealer Info' });
  }, []);

  // find duplicate phone count
  useEffect(() => {
    if (Array.isArray(data?.data?.data)) {
      const dpCount = {};
      const vpCount = {};
      data?.data?.data?.forEach((d) => {
        if (dpCount[d.dealerManagerNumber]?.count) {
          dpCount[d.dealerManagerNumber].count += 1;
        } else {
          dpCount[d.dealerManagerNumber] = {
            count: 1,
            color: getRandomColorName(),
          };
        }
      });
      data?.data?.data?.forEach((d) => {
        if (vpCount[d.vanPullerMobile]?.count) {
          vpCount[d.vanPullerMobile].count += 1;
        } else {
          vpCount[d.vanPullerMobile] = {
            count: 1,
            color: getRandomColorName(),
          };
        }
      });
      setPhoneCount(dpCount);
      setPhoneCountvp(vpCount);
    }
  }, [data?.data?.data]);

  const COLUMNS = useMemo(
    () => [
      {
        Header: 'Date Of Visit',
        accessor: 'visitDate',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY hh:mm A');
          return value;
        },
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
        width: 260,
      },
      {
        Header: "Recoder's Name",
        accessor: 'name',
        width: 200,
      },
      {
        Header: "Recoder's Mobile",
        accessor: 'mobileNo',
      },
      {
        Header: "Recoder's Type",
        accessor: 'recordingPersonType',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Region',
        accessor: 'region.name',
        Cell: ({ value, row }) => {
          return `${value} (${row.original?.region?.code})`;
        },
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'HQ',
        accessor: 'hq.name',
        Cell: ({ value, row }) => {
          return `${value} (${row.original?.hq?.code})`;
        },
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
        width: 200,
      },
      {
        Header: 'Point/Town',
        accessor: 'pointTown.name',
        Cell: ({ value, row }) => {
          return `${value} (${row.original?.pointTown?.code})`;
        },
        width: 400,
      },

      {
        Header: 'Customer',
        accessor: 'customer.name',
        Cell: ({ value, row }) => `${value} (${row.original?.customer?.code})`,
        width: 200,
      },
      {
        Header: 'SO/SR',
        accessor: 'srName.name',
        Cell: ({ value, row }) => `${value} (${row.original?.srName?.code})`,
        width: 250,
      },
      {
        Header: "Dealer's Mananger Name",
        accessor: 'dealerManagerName',
        width: 250,
      },
      {
        Header: "Dealer's Mananger Mobile",
        accessor: 'dealerManagerNumber',
        width: 220,
        Cell: ({ value }) => {
          if (phoneCount[value]?.count > 1) {
            return (
              <span style={{ color: phoneCount[value].color }}>
                <span
                  style={{
                    borderRadius: 8,
                    color: 'white',
                    backgroundColor: phoneCount[value].color,
                  }}>
                  &nbsp;{phoneCount[value].count}&nbsp;
                </span>
                {` ${value}`}
              </span>
            );
          }
          return value;
        },
      },
      {
        Header: 'Van Puller Name',
        accessor: 'vanPullerName',
      },
      {
        Header: 'Van Puller Mobile',
        accessor: 'vanPullerMobile',
        Cell: ({ value }) => {
          if (phoneCountvp[value]?.count > 1) {
            return (
              <span style={{ color: phoneCountvp[value].color }}>
                <span
                  style={{
                    borderRadius: 8,
                    color: 'white',
                    backgroundColor: phoneCountvp[value].color,
                  }}>
                  &nbsp;{phoneCountvp[value].count}&nbsp;
                </span>
                {` ${value}`}
              </span>
            );
          }
          return value;
        },
      },
      {
        Header: 'Geo Location',
        Cell: ({ row }) => {
          if (!row.original?.latitude || !row.original?.longitude)
            return <Typography color="error">NO DATA</Typography>;
          return (
            <Link
              href={encodeURI(
                `https://www.google.com/maps/search/?api=1&query=${row.original?.latitude},${row.original?.longitude}`,
              )}
              rel="noopener noreferrer"
              target="_blank"
              underline="hover"
              title="Open in Google Map">
              <Icon sx={{ fontSize: 14 }}>location_on</Icon>
              {` ${row.original?.latitude}, ${row.original?.longitude}`}
            </Link>
          );
        },
      },
      {
        Header: 'Status',
        id: 'Status',
        accessor: 'status',
        Cell: ({ value }) => {
          if (value === 'verified')
            return (
              <Tooltip title="Verified">
                <CheckCircleIcon color="success" />
              </Tooltip>
            );
          if (value === 'failed')
            return (
              <Tooltip title="Failed">
                <CancelIcon color="error" />
              </Tooltip>
            );
          return (
            <Tooltip title="Pending">
              <HelpOutlineIcon color="primary" />
            </Tooltip>
          );
        },
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Entry Time',
        accessor: 'createdAt',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY hh:mm A');
          return value;
        },
        width: 250,
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Option',
        id: 'option',
        Cell: ({ row }) =>
          row?.original?.recordedBy === userId ? (
            <Button
              disabled={row?.original?.clamiedTaDa}
              variant="outlined"
              size="small"
              onClick={() => handleTaDa(row?.original)}
              sx={{ whiteSpace: 'nowrap' }}>
              Claim TA DA
            </Button>
          ) : null,
      },
      {
        Header: 'Action',
        id: 'Action',
        Cell: ({ row }) => {
          return (
            <Stack direction="row" alignItems="center" spacing={3}>
              {['Super Admin', ...Admin].includes(role) ? (
                <Tooltip title="Update verification status">
                  <IconButton
                    onClick={() => handleVerifyShow(row.original)}
                    className="Table_icon_btn">
                    <BeenhereIcon />
                  </IconButton>
                </Tooltip>
              ) : null}

              <Tooltip title="Update Exiting Dealer information">
                <IconButton
                  onClick={() => handleEditShow(row.original)}
                  className="Table_icon_btn">
                  <BorderColorIcon />
                </IconButton>
              </Tooltip>

              {role === 'Super Admin' && (
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => handleDeleteShow(row.original.id)}
                    className="Table_icon_btn delete">
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          );
        },
      },
    ],
    [phoneCount, phoneCountvp, role],
  );

  if (isLoading) {
    return <TableSkeleton />;
  }

  if (isError) {
    expired(error);
  }

  return (
    <Box>
      <StickyTable
        columns={COLUMNS}
        data={data?.data?.data}
        filename="ExitingDealerInfo"
        addButtonTooltip="Add Customer"
        hideAddIcon
      />

      <Modal
        title="Update verification status"
        show={verifyShow}
        onSave={handleVerify}
        onHide={() => setVerifyShow(false)}
        width="lg"
        form>
        <Stack direction="column" gap={3}>
          <Typography>
            Set verification status for <strong>{verifyData.outletName}</strong>
          </Typography>

          <FormControl>
            <FormLabel id="outlet-status-label">Verification Status</FormLabel>
            <RadioGroup
              aria-labelledby="outlet-status-label"
              name="outlet-status"
              value={status}
              defaultValue="pending"
              onChange={(e) => setStatus(e.target.value)}>
              <FormControlLabel
                value="pending"
                control={<Radio />}
                label={
                  <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    Pending&nbsp;
                    <HelpOutlineIcon color="primary" fontSize="small" />
                  </Box>
                }
              />
              <FormControlLabel
                value="verified"
                control={<Radio />}
                label={
                  <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    Verified&nbsp;
                    <CheckCircleIcon color="success" fontSize="small" />
                  </Box>
                }
              />
              <FormControlLabel
                value="failed"
                control={<Radio />}
                label={
                  <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    Failed&nbsp;
                    <CancelIcon color="error" fontSize="small" />
                  </Box>
                }
              />
            </RadioGroup>
          </FormControl>
          <Button variant="contained" onClick={handleVerify}>
            Update
          </Button>
        </Stack>
      </Modal>

      <ConfirmModal
        title="Are you sure?"
        subtitle="You won't be able to revert this!"
        show={deleteShow}
        onSave={() => handleDelete()}
        onHide={() => setDeleteShow(false)}
        actionButtonLabel="Delete"
        actionButtonColor="error"
      />
    </Box>
  );
}

export default ExitingDealerInformation;
