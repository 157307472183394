import { useQuery } from 'react-query';
import useAxiosPrivate from './useAxiosPrivate';
import { useEffect, useState } from 'react';

const useDamages = () => {
  const axiosPrivate = useAxiosPrivate();
  const [unApprove, setunApprove] = useState(0);

  const { data, isLoading, isError, error, refetch } = useQuery(
    'damages',
    () => {
      return axiosPrivate.get(
        '/api/v1/damage?populate=yes&limit=none&sort=-createdAt',
      );
    },
    {
      refetchInterval: 60000,
    },
  );

  useEffect(() => {
    const total = data?.data?.data
      ?.map((val) => {
        return val.isApproved === false;
      })
      .reduce((a, c) => a + c, 0);
    setunApprove(total);
  }, [data?.data?.data]);

  return { data, isLoading, isError, error, refetch, unApprove };
};

export default useDamages;
