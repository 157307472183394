import React, { useEffect, useRef, useState } from 'react';
import {
  useTable,
  useBlockLayout,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useFilters,
  useExpanded,
} from 'react-table';
import { useSticky } from 'react-table-sticky';
import { GlobalFilter } from './GlobalFilter';
import { ColumnFilter } from './ColumnFilter';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import Pagination from './Pagination';
import { useMemo } from 'react';
import NoData from '../Skeleton/NoData';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import useAuth from '../../hooks/useAuth';
import matchSorter from 'match-sorter';
import roundToDigit from '../../utils/roundToDigit';
import { exportExcelFile } from './ExportData';

export default function StickyTable(props) {
  const { role } = useAuth();
  const {
    columns,
    data,
    filename,
    customerYear,
    expandRow,
    expandRowProps,
    leftHeader,
    monthYear,
  } = props;

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      dateBetween: dateBetweenFilterFn /*<- LIKE THIS*/,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );

  const defaultColumn = useMemo(() => {
    return {
      Filter: ColumnFilter,
    };
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    visibleColumns,
    // pagination----------------------
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    // pagination----------------------------------
  } = useTable(
    {
      columns: columns,
      data: data,
      defaultColumn: defaultColumn,
      filterTypes,
      initialState: {
        pageSize: 10,
        hiddenColumns: ['ASM', 'RSM', 'DSM', 'SO/SR'].includes(role)
          ? ['Status', 'OutletAction']
          : [''],
      },
    },
    useBlockLayout,
    useSticky,
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
  );
  const { pageIndex, pageSize, globalFilter } = state;

  const [filteredData, setFilteredData] = useState([{}]);

  const tableRef = useRef(null);

  const handleScroll = () => {
    const table = tableRef.current;
    table.scrollTo({ top: 0 });
  };

  useEffect(() => {
    const rowData = rows.map((info) => {
      return info.original;
    });
    setFilteredData(rowData);
  }, [rows]);

  // Custom aggregation function to calculate the sum of a column
  const sumAggregator = (rows, columnId) => {
    return roundToDigit(
      rows?.reduce(
        (acc, row) =>
          acc + (row.values[columnId] === undefined ? 0 : row.values[columnId]),
        0,
      ),
    );
  };

  const ctnSumAggregator = (rows, columnId) => {
    let cartons = 0;
    let packets = 0;

    rows?.forEach((row) => {
      const txt = row?.values[columnId];
      if (txt?.includes(' ctn')) {
        const t = txt.split(' ctn');

        const carton = parseInt(t[0], 10);
        cartons += carton;

        if (t[1] && t[1].includes(' pcs')) {
          const packet = parseInt(t[1].split(' pcs')[0], 10);
          packets += packet;
        }
      }
    });

    let sumDisplay = `${cartons} ctn`;
    if (packets) {
      sumDisplay += ` ${packets} pcs`;
    }

    return sumDisplay;
  };

  return (
    <>
      <Stack
        direction={'row'}
        mb={1}
        justifyContent={'space-between'}
        alignItems={'flex-end'}>
        <GlobalFilter
          rows={rows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          preGlobalFilteredRows={preGlobalFilteredRows}
        />

        {['Super Admin'].includes(role) && (
          <Tooltip title="Export Excel">
            <IconButton
              color="primary"
              className="Table_icon_btn"
              onClick={() => exportExcelFile(filename, data, customerYear)}>
              <DownloadForOfflineIcon style={{ fontSize: '36px' }} />
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      {data?.length !== 0 ? (
        <>
          <div
            className="table_div"
            style={{
              maxHeight:
                filename === 'employee_cust' ? '400px' : 'calc(100vh - 20rem)',
              borderBottom: '0px solid transparent',
            }}
            ref={tableRef}>
            {filename === 'employee_cust' && (
              <table
                cellSpacing="0"
                cellPadding="0"
                border="0"
                style={{
                  width: '100%',
                  position: 'relative',
                  left: '405px',
                  tableLayout: 'fixed',
                }}>
                <tr>
                  <th style={{ width: '255px', boxSizing: 'border-box' }}></th>
                  {monthYear.map((item, index) => (
                    <th
                      key={index}
                      style={{
                        whiteSpace: 'nowrap',
                        width: '112px',
                        boxSizing: 'border-box',
                      }}>
                      {item}
                    </th>
                  ))}
                </tr>
                <tr>
                  <th style={{ whiteSpace: 'nowrap', boxSizing: 'border-box' }}>
                    Customer Count
                  </th>
                  {leftHeader.map((item, index) => (
                    <td
                      key={index}
                      style={{
                        border: '1px solid #fff',
                      }}>
                      {item?.customerCount}
                    </td>
                  ))}
                </tr>
                <tr>
                  <th style={{ whiteSpace: 'nowrap', boxSizing: 'border-box' }}>
                    Average Per Customer Collection
                  </th>
                  {leftHeader.map((item, index) => (
                    <td
                      key={index}
                      style={{
                        border: '1px solid #fff',
                        wordBreak: 'break-word',
                      }}>
                      {item?.averagePerCustomerCollection}
                    </td>
                  ))}
                </tr>
                <tr>
                  <th style={{ whiteSpace: 'nowrap', boxSizing: 'border-box' }}>
                    Total Collection
                  </th>
                  {leftHeader.map((item, index) => (
                    <td
                      key={index}
                      style={{
                        border: '1px solid #fff',
                        wordBreak: 'break-word',
                      }}>
                      {item?.totalCollection}
                    </td>
                  ))}
                </tr>
              </table>
            )}
            <table
              cellSpacing="0"
              cellPadding="0"
              border="0"
              {...getTableProps()}>
              <thead style={{ position: 'sticky', top: '0px', zIndex: 10 }}>
                {headerGroups.map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        key={column.id}
                        className="prevent-select v-top">
                        <Box>
                          {[
                            'Territorries',
                            'Categories',
                            'Time',
                            'Products',
                          ].includes(column?.Header) ? (
                            <Box
                              sx={{
                                display: 'inline-block',
                                position: 'sticky',
                                left: {
                                  md: ['Time', 'Territorries'].includes(
                                    column?.Header,
                                  )
                                    ? 0
                                    : 610,
                                  xs: 0,
                                },
                              }}>
                              {column.render('Header')}
                            </Box>
                          ) : (
                            <Stack
                              direction="row"
                              {...column.getSortByToggleProps()}
                              sx={{ width: 'max-content' }}>
                              {column.render('Header')}
                              <span>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <ArrowDownwardIcon fontSize="small" />
                                  ) : (
                                    <ArrowUpwardIcon fontSize="small" />
                                  )
                                ) : (
                                  <SwapVertIcon fontSize="small" />
                                )}
                              </span>
                            </Stack>
                          )}

                          {column.canFilter ? column.render('Filter') : null}

                          {/* {column.aggregate === 'sum' && (
                            <Box mt={0.75} style={{ wordBreak: 'break-word' }}>
                              {sumAggregator(rows, column.id)}
                            </Box>
                          )} */}
                        </Box>
                        {column.aggregate === 'sum' && (
                          <Box mt={0.75} style={{ whiteSpace: 'nowrap' }}>
                            {sumAggregator(rows, column.id)}
                          </Box>
                        )}
                        {column.aggregateCtn === 'sum' && (
                          <Box mt={0.75} style={{ whiteSpace: 'nowrap' }}>
                            {ctnSumAggregator(rows, column.id)}
                          </Box>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  let rowProps = {};
                  if (expandRow) {
                    rowProps = {
                      ...row.getRowProps(),
                      ...row.getToggleRowExpandedProps({ title: '' }),
                    };
                  }

                  return (
                    <>
                      <tr
                        key={i}
                        {...rowProps}
                        style={{ width: 'max-content' }}
                        // ref={pageTopRef}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td key={cell.id} {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>

                      {row.isExpanded && (
                        <tr id="expand_row">
                          <td colSpan={visibleColumns.length}>
                            {React.createElement(expandRow, {
                              data: row.original,
                              ...expandRowProps,
                            })}
                          </td>
                        </tr>
                      )}
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>

          <Pagination
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            pageCount={pageCount}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            setPageSize={setPageSize}
            pageIndex={pageIndex}
            pageSize={pageSize}
            preGlobalFilteredRows={preGlobalFilteredRows}
            handleScroll={handleScroll}
          />
        </>
      ) : (
        <NoData />
      )}
    </>
  );
}

function dateBetweenFilterFn(rows, id, filterValues) {
  const sd = filterValues[0] ? new Date(filterValues[0]) : undefined;
  const ed = filterValues[1] ? new Date(filterValues[1]) : undefined;

  if (ed || sd) {
    return rows?.filter((r) => {
      const cellDate = new Date(r.values[id]);

      if (ed && sd) {
        return cellDate >= sd && cellDate <= ed;
      } else if (sd) {
        return cellDate >= sd;
      } else if (ed) {
        return cellDate <= ed;
      }
    });
  } else {
    return rows;
  }
}

dateBetweenFilterFn.autoRemove = (val) => !val;

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;
