export const BankList = [
  'Islami Bank Bangladesh Limited',
  'Dutch-Bangla Bank Limited',
  'Al-Arafah Islami Bank Limited',
  'Social Islami Bank Limited',
  'Agrani Bank Limited',
  'Bangladesh Development Bank Limited',
  'Bangladesh Krishi Bank',
  'Bank Asia Limited',
  'Basic Bank Limited',
  'BRAC Bank Limited',
  'Citibank, N.A. Bangladesh',
  'Commercial Bank of Ceylon Limited',
  'Dhaka Bank Limited',
  //   'Dutch-Bangla Bank Limited',
  'Eastern Bank Limited',
  'EXIM Bank Limited',
  'First Security Islami Bank Limited',
  'HSBC Bangladesh',
  'ICICI Bank Limited',
  //   'Islami Bank Bangladesh Limited',
  'Jamuna Bank Limited',
  'Janata Bank Limited',
  'Meghna Bank Limited',
  'Mercantile Bank Limited',
  'Midland Bank Limited',
  'Mutual Trust Bank Limited',
  'National Bank Limited',
  'National Credit and Commerce Bank Limited',
  'NRB Commercial Bank Limited',
  'One Bank Limited',
  'Prime Bank Limited',
  'Pubali Bank Limited',
  'Rupali Bank Limited',
  'Shahjalal Islami Bank Limited',
  //   'Social Islami Bank Limited',
  'Sonali Bank Limited',
  'Southeast Bank Limited',
  'Standard Bank Limited',
  'State Bank of India',
  'The City Bank Limited',
  'Trust Bank Limited',
  'Union Bank Limited',
  'United Commercial Bank Limited',
];

export const MobileBankList = ['Bkash', 'Rocket', 'Nagad', 'Upay', 'MCash'];
