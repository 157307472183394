import React, { useMemo, useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import TableSkeleton from '../../component/Skeleton/TableSkeleton';
import StickyTable from '../../component/Table/StickyTable';
import Filter from '../../component/report/Filter';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import roundToDigit from '../../utils/roundToDigit';

function EmployeeWiseCust() {
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();

  const [valueKey, setValueKey] = useState('customerCount');

  //   get hq-sku
  const { data, isLoading, isError, error } = useQuery(
    ['asm-wise-active-customer'],
    () =>
      axiosPrivate.get(
        `/api/v1/report/employee-active-customer?role=63c63fc5423d27d3abb25a6a`, //asm role id
      ),
  );

  //get per employees
  const mdata = useMemo(() => data?.data?.data.perEmployee, [data]);

  //   custom table states---------------
  const [monthYear, setMonthYear] = useState([]);
  const [leftHeader, setLeftHeader] = useState([]);

  const COLUMNS = useMemo(() => {
    const columns = [
      {
        Header: 'Region',
        accessor: 'territories.Region',
        Cell: ({ value }) => {
          if (!value)
            return (
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: 'normal',
                  textAlign: 'center',
                }}>
                -
              </Typography>
            );
          return (
            <>
              {value.length > 12 ? (
                <Tooltip title={value}>
                  <Typography sx={{ fontSize: '14px', lineHeight: 'normal' }}>
                    {value.slice(0, 12)}..
                  </Typography>
                </Tooltip>
              ) : (
                value
              )}
            </>
          );
        },
        width: 120,

        sticky: 'left',
      },
      {
        Header: 'ASM',
        accessor: 'employees.ASM',
        Cell: ({ value }) => {
          if (!value)
            return (
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: 'normal',
                  textAlign: 'center',
                }}>
                -
              </Typography>
            );
          return (
            <>
              {value.length > 30 ? (
                <Tooltip title={value}>
                  <Typography sx={{ fontSize: '14px', lineHeight: 'normal' }}>
                    {value.slice(0, 30)}...
                  </Typography>
                </Tooltip>
              ) : (
                value
              )}
            </>
          );
        },
        width: 290,
        sticky: 'left',
      },
      {
        Header: 'RSM',
        accessor: 'employees.RSM',
        Cell: ({ value }) => {
          if (!value)
            return (
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: 'normal',
                  textAlign: 'center',
                }}>
                -
              </Typography>
            );
          return (
            <>
              {value.length > 28 ? (
                <Tooltip title={value}>
                  <Typography sx={{ fontSize: '14px', lineHeight: 'normal' }}>
                    {value.slice(0, 28)}...
                  </Typography>
                </Tooltip>
              ) : (
                value
              )}
            </>
          );
        },
        width: 250,
      },
    ];

    const monthYears = data?.data?.data?.nationalTotal;
    if (typeof monthYears === 'object') {
      const monthYearX = [];
      const leftHeaderX = [];

      const sorted = Object.keys(monthYears).sort(
        (a, b) =>
          dayjs(b, 'MMM-YYYY').valueOf() - dayjs(a, 'MMM-YYYY').valueOf(),
      );

      sorted?.forEach((m) => {
        monthYearX.push(m);
        leftHeaderX.push({
          totalCollection: roundToDigit(monthYears[m].totalCollection),
          customerCount: roundToDigit(monthYears[m].customerCount),
          averagePerCustomerCollection: roundToDigit(
            monthYears[m].averagePerCustomerCollection,
          ),
        });
        columns.push({
          Header: m,
          accessor: `monthYears.${m}.${valueKey}`,
          Cell: ({ value }) => roundToDigit(value),
          width: 112,
        });
      });

      setMonthYear(monthYearX);
      setLeftHeader(leftHeaderX);
    }

    return columns;
  }, [mdata, valueKey]);

  if (isError) {
    expired(error);
  }

  return (
    <>
      <Filter
        title="ASM WISE ACTIVE DEALERS"
        valueKey={valueKey}
        setValueKey={setValueKey}
        employee_wise_active
      />

      {!isLoading ? (
        <>
          <StickyTable
            columns={COLUMNS}
            data={mdata}
            filename={`employee_cust`}
            hideAddIcon
            leftHeader={leftHeader}
            monthYear={monthYear}
          />
        </>
      ) : (
        <TableSkeleton />
      )}
    </>
  );
}

export default EmployeeWiseCust;
