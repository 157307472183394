import BorderColorIcon from '@mui/icons-material/BorderColor';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, IconButton, Stack } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmModal from '../../../component/ConfirmModal';
import {
  MultiSelectColumnFilter,
  SelectColumnFilterBool,
} from '../../../component/Table/SelectColumnFilter';
import Table from '../../../component/Table/Table';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useExpiredSession from '../../../hooks/useExpiredSession';
import AddProduct from './AddProduct';
import useHeader from '../../../hooks/useHeader';

function ViewProduct() {
  const location = useLocation();
  const { setHeader } = useHeader();
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const [addModalShow, setAddModalShow] = useState(false);

  useEffect(() => {
    setHeader({ title: 'Products' });
  }, []);

  // get All Product data
  const { data, isLoading, isFetching, isError, error, refetch } = useQuery(
    'AllProduct',
    () => {
      return axiosPrivate.get(
        '/api/v1/product?populate=yes&limit=none&sort=-createdAt',
      );
    },
  );
  // get All Product data

  const [deleteShow, setDeleteShow] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState('');
  const navigate = useNavigate();

  const handleDelete = () => {
    axiosPrivate
      .delete(`/api/v1/product/${deleteId}`)
      .then((res) => {
        if (res.status === 204) {
          toast.success('Document was deleted');
          refetch();
        }
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      })
      .finally(() => {
        setDeleteShow(false);
      });
  };

  const handleEdit = (id) => {
    navigate(`/products/edit/${id}`, {
      state: { title: 'Edit Product' },
    });
  };

  const COLUMNS = useMemo(
    () => [
      {
        Header: () => null,
        id: 'expander',
        Cell: ({ row }) => (
          <>
            {row.isExpanded ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </>
        ),
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Code',
        accessor: 'code',
      },
      {
        Header: 'Category',
        accessor: 'productCategory.productCategory',
        Cell: ({ value, row }) => {
          if (value)
            return `${value} (${row?.original?.productCategory?.codePrefix})`;
          return;
        },
      },
      {
        Header: 'Carton',
        accessor: 'quantityPerCarton',
      },
      {
        Header: 'DSRP / PCS',
        accessor: 'dsrPrice',
      },
      {
        Header: 'DP / PCS',
        accessor: 'dealerPrice',
      },
      {
        Header: 'TP / PCS',
        accessor: 'tradePrice',
      },
      {
        Header: 'MRP',
        accessor: 'maxRetailPrice',
      },
      {
        Header: 'Trade Offer',
        accessor: 'offerType',
        Cell: ({ value, row }) => {
          switch (value) {
            case 'free':
              return `${row?.original?.freeItemsPerCarton} PCS / CTN`;

            case 'gift':
              return `${row?.original?.giftItemDescription}`;

            default:
              return '';
          }
        },
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Active',
        accessor: 'isActive',
        Cell: ({ value, row }) => {
          if (value) {
            return <CheckCircleIcon color="success" />;
          }
          return <HighlightOffIcon color="error" />;
        },
        Filter: SelectColumnFilterBool,
      },
      {
        Header: 'Action',
        id: 'Action',
        Cell: ({ row }) => (
          <Stack direction="row" alignItems="center" spacing={2}>
            <IconButton
              onClick={() => {
                handleEdit(row.original.id);
              }}
              className="Table_icon_btn">
              <BorderColorIcon />
            </IconButton>
            <Box>
              <IconButton
                onClick={() => {
                  setDeleteId(row.original.id);
                  setDeleteShow(true);
                }}
                className="Table_icon_btn delete">
                <DeleteIcon />
              </IconButton>
            </Box>
          </Stack>
        ),
      },
    ],
    [],
  );
  // funciton

  if (isError) {
    expired(error);
  }

  return (
    <>
      {location.pathname !== '/products' ? (
        <Outlet />
      ) : (
        <>
          <Table
            columns={COLUMNS}
            data={data?.data?.data || [{}]}
            isLoading={isLoading || isFetching}
            filename="Products"
            addButtonTooltip="Add Product"
            onAddButtonClick={() => setAddModalShow(true)}
            expandRow={ExpandRow}
            fullHeight={true}
          />
          {addModalShow && (
            <AddProduct
              addModalShow={addModalShow}
              setAddModalShow={setAddModalShow}
              refetch={refetch}
            />
          )}
          <ConfirmModal
            title="Are you sure?"
            subtitle="You won't be able to revert this!"
            show={deleteShow}
            onSave={() => handleDelete()}
            onHide={() => setDeleteShow(false)}
            actionButtonLabel="Delete"
            actionButtonColor="error"
          />
        </>
      )}
    </>
  );
}

function ExpandRow({ data }) {
  return (
    <>
      <Box sx={{ width: { md: '50%', xs: '100%' } }}>
        <div className="ExpandTableMain">
          <caption style={caption}>Product's Details</caption>
          <table className="details-table" cellSpacing="0">
            <tr>
              <td>
                <b>Category</b>
              </td>
              <td>{data?.productCategory?.productCategory}</td>
            </tr>
            <tr>
              <td>
                <b>Name</b>
              </td>
              <td>{data?.name}</td>
            </tr>
            <tr>
              <td>
                <b>Code</b>
              </td>
              <td>{data?.code}</td>
            </tr>
            <tr>
              <td>
                <b>Quantity per carton</b>
              </td>
              <td>{data?.quantityPerCarton}</td>
            </tr>
            <tr>
              <td>
                <b>Weight per packet</b>
              </td>
              <td>{data?.weightPerPacket}</td>
            </tr>
            <tr>
              <td>
                <b>DRS price</b>
              </td>
              <td>{data?.dsrPrice}</td>
            </tr>
            <tr>
              <td>
                <b>Dealer price</b>
              </td>
              <td>{data?.dealerPrice}</td>
            </tr>
            <tr>
              <td>
                <b>Trade price</b>
              </td>
              <td>{data?.tradePrice}</td>
            </tr>
            <tr>
              <td>
                <b>Max retail price</b>
              </td>
              <td>{data?.maxRetailPrice}</td>
            </tr>
            <tr>
              <td>
                <b>Offer Type</b>
              </td>
              <td style={{ textTransform: 'capitalize' }}>{data?.offerType}</td>
            </tr>
            {data.offerType === 'free' && (
              <tr>
                <td>
                  <b>Free items per carton</b>
                </td>
                <td>{data?.freeItemsPerCarton}</td>
              </tr>
            )}
            {data.offerType === 'gift' && [
              <tr>
                <td>
                  <b>Gift Item Description</b>
                </td>
                <td>{data?.giftItemDescription}</td>
              </tr>,
              <tr>
                <td>
                  <b>Minimum order quantity for gift</b>
                </td>
                <td>{data?.minimumOrderQuantityForOffer}</td>
              </tr>,
            ]}
            <tr>
              <td>
                <b>Product description</b>
              </td>
              <td>{data?.description}</td>
            </tr>
            <tr>
              <td>
                <b>Active status</b>
              </td>
              <td>{data?.isActive ? 'Active' : 'Inactive'}</td>
            </tr>
            {data?.photos?.length ? (
              <tr>
                <td colSpan={2}>
                  <b>Photos</b>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      padding: 10,
                      gap: 10,
                    }}>
                    {data?.photos.map((photo, index) => (
                      <div
                        key={`${data?.id}-${index}`}
                        style={{ maxWidth: '250px', maxHeight: '200px' }}>
                        <img
                          src={photo}
                          alt={`${data?.name} ${index}`}
                          style={{
                            width: '100%',
                            height: '100%',
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </td>
              </tr>
            ) : (
              <tr>
                <td>
                  <b>Photos</b>
                </td>
                <td>No photos</td>
              </tr>
            )}
          </table>
        </div>
      </Box>
    </>
  );
}

export default ViewProduct;
const caption = {
  textAlign: 'center',
  display: 'block',
  color: '#fff',
  background: '#1976d2',
  padding: '5px',
};
