import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Stack } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useQuery } from 'react-query';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmModal from '../../component/ConfirmModal';
import { DateRangeColumnFilter } from '../../component/Table/SelectColumnFilter';
import Table from '../../component/Table/Table';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import useHeader from '../../hooks/useHeader';
import roundToDigit from '../../utils/roundToDigit';

function SrReportDetails() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const { setHeader } = useHeader();
  const { role, userId, Admin } = useAuth();

  React.useEffect(() => {
    setHeader({ title: 'SR Report' });
  }, []);

  const [entryStartDate, setEntryStartDate] = React.useState(
    dayjs().subtract(1, 'month').startOf('month'),
  );
  const [entryEndDate, setEntryEndDate] = React.useState(
    dayjs().endOf('month'),
  );

  const [deleteShow, setDeleteShow] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState('');

  const handleAdd = () => {
    navigate('/sr-report-entries/add');
  };
  const handleEdit = (id) => {
    navigate(`/sr-report-entries/edit/${id}`);
  };
  const handleDeleteSelection = (id) => {
    setDeleteId(id);
    setDeleteShow(true);
  };
  const handleDelete = () => {
    axiosPrivate
      .delete(`/api/v1/sales-activity/${deleteId}`)
      .then((res) => {
        if (res.status === 204) {
          toast.success('Document was deleted');
          refetch();
        }
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      })
      .finally(() => {
        setDeleteId('');
        setDeleteShow(false);
      });
  };

  const { data, isLoading, error, isError, refetch } = useQuery(
    ['sales-activity', entryStartDate, entryEndDate],
    () => {
      let url =
        '/api/v1/sales-activity?populate=yes&limit=none&sort=-reportDate';

      if (!['Super Admin', ...Admin, 'IMS'].includes(role)) {
        url += `&recordedBy=${userId}`;
      }
      if (entryStartDate?.isValid()) {
        url += `&createdAt[gte]=${entryStartDate.toISOString()}`;
      }
      if (entryEndDate?.isValid()) {
        url += `&createdAt[lte]=${entryEndDate.toISOString()}`;
      }

      return axiosPrivate.get(url).then((res) => {
        const info = [];

        res?.data?.data?.forEach((d) => {
          const orderValuePerMemo = roundToDigit(d?.orderValue / d?.memoCount);
          const linePerCall = roundToDigit(d?.orderQuantity / d?.memoCount);
          const aveSkuValuePerMemo = roundToDigit(
            orderValuePerMemo / linePerCall,
          );
          const strikeRate = roundToDigit(
            (d?.memoCount / d?.outletCount) * 100,
          );
          const orderExecutionRate = roundToDigit(
            (d?.deliveryValue / d?.orderValue) * 100,
          );

          const i = {
            ...d,
            orderValuePerMemo,
            linePerCall,
            aveSkuValuePerMemo,
            strikeRate,
            orderExecutionRate,
          };

          info.push(i);
        });

        return info;
      });
    },
  );

  const entryDateRangeFilter = React.useMemo(
    () => (
      <Stack>
        <input
          type="date"
          value={entryStartDate?.format('YYYY-MM-DD') || ''}
          onChange={(e) => {
            setEntryStartDate(dayjs(e.target.value).startOf('date'));
          }}
        />
        <input
          type="date"
          value={entryEndDate?.format('YYYY-MM-DD') || ''}
          onChange={(e) => {
            setEntryEndDate(dayjs(e.target.value).endOf('date'));
          }}
        />
      </Stack>
    ),

    [entryStartDate, entryEndDate],
  );

  const COLUMNS = React.useMemo(
    () => [
      {
        Header: 'Entered By',
        accessor: 'recordedBy.nameCode',
      },
      {
        Header: 'Entry Time',
        accessor: 'createdAt',
        Cell: ({ value }) =>
          value ? dayjs(value).format('DD/MM/YYYY hh:mm A') : null,
        Filter: entryDateRangeFilter,
      },
      {
        Header: 'Report Date',
        accessor: 'reportDate',
        Cell: ({ value }) => (value ? dayjs(value).format('DD/MM/YYYY') : null),
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'SO/SR',
        accessor: 'employee.nameCode',
      },
      {
        Header: 'HQ',
        accessor: 'employee.territory.parentTerritory.name',
      },
      {
        Header: 'Point',
        accessor: 'employee.territory.name',
      },
      {
        Header: 'Route',
        accessor: 'route',
      },
      {
        Header: 'Visited Outlets',
        accessor: 'outletCount',
      },
      {
        Header: 'Order Qty',
        accessor: 'orderQuantity',
        Cell: ({ value }) => (value ? `${value} ctn` : null),
        aggregate: 'sum',
      },
      {
        Header: 'Order Value',
        accessor: 'orderValue',
        Cell: ({ value }) => (value ? `${value} Tk` : null),
        aggregate: 'sum',
      },
      {
        Header: 'Memos',
        accessor: 'memoCount',
        aggregate: 'sum',
      },
      {
        Header: 'Delivery Qty',
        accessor: 'deliveryQuantity',
        Cell: ({ value }) => (value ? `${value} ctn` : null),
        aggregate: 'sum',
      },
      {
        Header: 'Delivery Value',
        accessor: 'deliveryValue',
        Cell: ({ value }) => (value ? `${value} Tk` : null),
        aggregate: 'sum',
      },
      {
        Header: 'Lifting Qty',
        accessor: 'liftingQuantity',
        Cell: ({ value }) => (value ? `${value} ctn` : null),
        aggregate: 'sum',
      },
      {
        Header: 'Stock Qty',
        accessor: 'stockQuantity',
        Cell: ({ value }) => (value ? `${value} ctn` : null),
      },
      {
        Header: 'Ave. SKU Per Memo',
        accessor: 'aveSkuValuePerMemo',
      },
      {
        Header: 'Order Value Per Memo',
        accessor: 'orderValuePerMemo',
      },
      {
        Header: 'Line Per Call',
        accessor: 'linePerCall',
      },
      {
        Header: 'Strike Rate',
        accessor: 'strikeRate',
        Cell: ({ value }) => (value ? `${value}%` : null),
      },
      {
        Header: 'Order Execution Rate',
        accessor: 'orderExecutionRate',
        Cell: ({ value }) => (value ? `${value}%` : null),
      },
      {
        Header: 'Action',
        id: 'Action',
        hidePrint: true,
        Cell: ({ row }) => {
          if (
            ['Super Admin', ...Admin, 'IMS'].includes(role) ||
            row?.original?.recordedBy?.id === userId
          ) {
            return (
              <Stack direction="row" alignItems="center" spacing={2}>
                <IconButton
                  onClick={() => handleEdit(row?.original?.id)}
                  className="Table_icon_btn">
                  <BorderColorIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleDeleteSelection(row?.original?.id)}
                  className="Table_icon_btn delete">
                  <DeleteIcon />
                </IconButton>
              </Stack>
            );
          }

          return null;
        },
      },
    ],
    [entryDateRangeFilter],
  );

  if (isError) {
    expired(error);
  }

  if (pathname !== '/sr-report-entries') {
    return <Outlet context={[refetch]} />;
  }

  return (
    <>
      <Table
        columns={COLUMNS}
        data={data || []}
        isLoading={isLoading}
        noWrap
        onAddButtonClick={handleAdd}
        addButtonTooltip="Add SR Report"
        filename="Sr_Report"
      />

      <ConfirmModal
        title="Are you sure?"
        subtitle="You won't be able to revert this!"
        show={deleteShow}
        onSave={handleDelete}
        onHide={() => {
          setDeleteId('');
          setDeleteShow(false);
        }}
        actionButtonLabel="Delete"
        actionButtonColor="error"
      />
    </>
  );
}

export default SrReportDetails;
