import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import React from 'react';
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MultiSelect from '../MultiSelect';
import MultiSelects from './MultiSelects';
import DashTitle from '../dashBoard/DashTitle';

function Filter({
  title,
  custcollect,
  year,
  setYear,
  collectionType,
  setCollectionType,
  typeData,
  transactionType,
  setTransactionType,
  //
  dosection,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  CustStock,
  // hq
  hq_sku,
  customerType,
  setCustomerType,
  territoryType,
  setTerritoryType,
  customerTypeData,
  territoryTypeData,
  employee_wise_active,
  valueKey,
  setValueKey,
}) {
  const typeOptions = [
    {
      title: 'All',
      value: 'all',
    },
    {
      title: 'Collection',
      value: 'collection',
    },
    {
      title: 'Damage Claim',
      value: 'damage',
    },
    {
      title: 'Sale',
      value: 'sale',
    },
    {
      title: 'Transfer',
      value: 'transfer',
    },
    {
      title: 'Salary',
      value: 'salary',
    },
    {
      title: 'Transport',
      value: 'transport',
    },
    {
      title: 'Return',
      value: 'return',
    },
    {
      title: 'Incentive',
      value: 'incentive',
    },
    {
      title: 'Bonus',
      value: 'bonus',
    },
    {
      title: 'Commission',
      value: 'commission',
    },
  ];

  return (
    <Stack
      direction={{ md: 'row' }}
      gap={2}
      alignItems="center"
      justifyContent={'space-between'}>
      <DashTitle title={title} />

      {custcollect && (
        <Stack direction="row" gap={1} width={{ md: '400px' }}>
          {!CustStock && (
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                size="small"
                value={collectionType}
                label="Type"
                onChange={(e) => setCollectionType(e.target.value)}>
                {typeOptions.map((info, index) => (
                  <MenuItem key={index} value={info.value}>
                    {info.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              fullWidth
              views={['year']}
              label="Year"
              value={year}
              onChange={(newValue) => {
                setYear(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} helperText={null} size="small" />
              )}
            />
          </LocalizationProvider>
        </Stack>
      )}

      {dosection && (
        <Stack
          direction={{ md: 'row' }}
          gap={1}
          alignItems={'center'}
          // width={{ md: 600 }}
        >
          <MultiSelect
            data={typeData}
            state={transactionType}
            setState={setTransactionType}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label=" Ordered Date Start"
              inputFormat="DD/MM/YYYY"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => (
                <TextField {...params} fullWidth size="small" />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Ordered Date End"
              inputFormat="DD/MM/YYYY"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => (
                <TextField {...params} fullWidth size="small" />
              )}
            />
          </LocalizationProvider>
        </Stack>
      )}

      {hq_sku && (
        <>
          <Stack
            direction={{ md: 'row' }}
            gap={1}
            alignItems={'center'}
            width={{ md: 600 }}>
            <MultiSelects
              data={customerTypeData}
              state={customerType}
              setState={setCustomerType}
            />
            {/* <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">
                Customer Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={customerType}
                label="Customer Type"
                onChange={(e) => setCustomerType(e.target.value)}>
                {customerTypeData.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.customerType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}

            <FormControl sx={{ width: '224px' }} size="small">
              <InputLabel id="demo-simple-select-label">
                Territory Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={territoryType}
                label="Territory Type"
                onChange={(e) => setTerritoryType(e.target.value)}>
                {territoryTypeData.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.territoryType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                fullWidth
                views={['year']}
                label="Year"
                value={year}
                onChange={(newValue) => {
                  setYear(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} helperText={null} size="small" />
                )}
              />
            </LocalizationProvider>
          </Stack>
        </>
      )}

      {employee_wise_active && (
        <Stack
          direction={{ md: 'row' }}
          gap={1}
          alignItems={'center'}
          width={{ md: 600 }}>
          <FormControl fullWidth>
            <InputLabel id="displayed-value-key">Displayed value</InputLabel>
            <Select
              labelId="displayed-value-key"
              id="displayed-value-key-select"
              value={valueKey}
              label="Displayed value"
              size="small"
              onChange={(e) => setValueKey(e.target.value)}>
              <MenuItem value="customerCount">Monthly Active Customer</MenuItem>
              <MenuItem value="totalCollection">
                Monthly Total Collection
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>
      )}
    </Stack>
  );
}

export default Filter;
