import React, { useEffect, useRef, useState } from 'react';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import validator from 'validator';
import Modals from '../../component/Modals';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import RedirectDialog from './RedirectDialog';

export default function ExitingDealerForm() {
  const { userId, role } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const { state: x } = useLocation();

  const [modalShow, setModalShow] = useState(false);
  const [redirectShow, setRedirectShow] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [createdId, setCreatedId] = useState('');

  // error states
  const [showModalError, setShowModalError] = useState(false);
  const [showFormError, setShowFormError] = useState(false);
  const [nameError, setNameError] = useState('');
  const [recordingPersonTypeError, setRecordingPersonTypeError] = useState('');
  const [mobileNoError, setMobileNoError] = useState('');
  const [customerError, setCustomerError] = useState('');
  const [srNameError, setSrNameError] = useState('');
  const [vanPullerNameError, setVanPullerNameError] = useState('');
  const [vanPullerMobileError, setVanPullerMobileError] = useState('');
  const [dealerManagerNameError, setDealerManagerNameError] = useState('');
  const [dealerManagerNumberError, setDealerManagerNumberError] = useState('');
  const [locationError, setLocationError] = useState('');

  // selection states
  const [regionList, setRegionList] = useState([]);
  const [pointTownList, setPointTownList] = useState([]);
  const [hqList, setHqList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);

  // form states
  const [image, setImage] = useState(null);
  const [name, setName] = useState(
    x?.name || localStorage.getItem('name') || '',
  );
  const [mobileNo, setMobileNo] = useState(
    x?.mobileNo || localStorage.getItem('mobileNo') || '',
  );
  const [recordingPersonType, setRecordingPersonType] = useState(
    x?.recordingPersonType || localStorage.getItem('recordingPersonType') || '',
  );
  const [visitDate, setVisitDate] = useState(
    x?.visitDate ? dayjs(x?.visitDate) : dayjs(),
  );
  const [dealerManagerName, setDealerManagerName] = useState(
    x?.dealerManagerName || '',
  );
  const [dealerManagerNumber, setDealerManagerNumber] = useState(
    x?.dealerManagerNumber || '',
  );
  const [vanPullerName, setVanPullerName] = useState(x?.vanPullerName || '');
  const [vanPullerMobile, setVanPullerMobile] = useState(
    x?.vanPullerMobile || '',
  );
  const [customer, setCustomer] = useState(x?.customer || {});
  const [srName, setSrName] = useState(x?.srName || {});
  const [region, setRegion] = useState(
    x?.region || {
      id: localStorage.getItem('regionId'),
      name: localStorage.getItem('region'),
    },
  );
  const [hq, setHq] = useState(
    x?.hq || {
      id: localStorage.getItem('hqId'),
      name: localStorage.getItem('hq'),
    },
  );
  const [pointTown, setPointTown] = useState(
    x?.pointTown || {
      id: localStorage.getItem('pointTownID'),
      name: localStorage.getItem('pointTown'),
    },
  );
  const [latitude, setLatitude] = useState(x?.latitude || null);
  const [longitude, setLongitude] = useState(x?.longitude || null);
  const [status, setStatus] = useState(x?.status || 'pending');

  const timer = useRef(null);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          setLocationError(null);
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              setLocationError(
                'You have to turn on location service and allow the request.',
              );
              break;
            case error.POSITION_UNAVAILABLE:
              setLocationError(
                'Location information is unavailable. Check your internet connection.',
              );
              break;
            case error.TIMEOUT:
              setLocationError('The request to get user location timed out.');
              break;
            case error.UNKNOWN_ERROR:
              setLocationError(
                'An unknown error occurred while getting your location.',
              );
              break;
            default:
              setLocationError(error.message);
              break;
          }
        },
      );
    } else {
      clearInterval(timer.current);
      setLocationError(
        'Geolocation is not supported by this browser. Please use Chrome Browser.',
      );
    }
  };

  useEffect(() => {
    if (!x?.id) {
      timer.current = setInterval(getLocation, 10000);
      getLocation();
    } else {
      clearInterval(timer.current);
    }
    return () => {
      clearInterval(timer.current);
    };
  }, [x?.id]);

  useEffect(() => {
    if (locationError) {
      alert(locationError);
    }
  }, [locationError]);

  // get region by region Id
  useEffect(() => {
    axiosPrivate
      .get(
        '/api/v1/territory?populate=yes&limit=none&territoryType=63aaa3c505e4dc7daa1a85ce',
      )
      .then((res) => {
        setRegionList(res?.data?.data);
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.message);
      });
  }, []);

  // get HQ by it's parent territory id
  useEffect(() => {
    axiosPrivate
      .get(
        `/api/v1/territory?populate=yes&limit=none&parentTerritory=${region?.id}`,
      )
      .then((res) => {
        setHqList(res?.data?.data);
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.message);
      });
  }, [region?.id]);

  // get Point/Town by it's parent territory id
  useEffect(() => {
    axiosPrivate
      .get(
        `/api/v1/territory?populate=yes&limit=none&parentTerritory=${hq?.id}`,
      )
      .then((res) => {
        setPointTownList(res?.data?.data);
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.message);
      });
  }, [hq?.id]);

  //   get all So/sr by point town id
  useEffect(() => {
    setEmployeeList([]);
    axiosPrivate
      .get(
        `/api/v1/employee?populate=yes&limit=none&isActive=true&sort=code&parentTerritory=${pointTown?.id}`,
      )
      .then((res) => {
        setEmployeeList([
          { label: 'VACANT', id: '656ed24b423d525752e551e2' },
          { label: 'DSR', id: '656ef445b797daef91748a89' },
          { label: 'DD-DSR', id: '656ef490423d525752e75546' },
          ...res?.data?.data,
        ]);
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.message);
      });
  }, [pointTown?.id]);

  React.useEffect(() => {
    setCustomerList([]);
    axiosPrivate
      .get(
        `/api/v1/customer?populate=yes&limit=none&isActive=true&sort=code&parentTerritory=${pointTown?.id}`,
      )
      .then((res) => {
        setCustomerList(res.data.data);
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.message);
      });
  }, [pointTown?.id]);

  // // -----------------------------

  // verify Mobile Number
  useEffect(() => {
    if (!validator.isMobilePhone(mobileNo, 'bn-BD', { strictMode: false })) {
      setMobileNoError('Invalid phone');
    } else {
      setMobileNoError(null);
    }
  }, [mobileNo]);

  // verify Primary Number
  useEffect(() => {
    if (
      !validator.isMobilePhone(vanPullerMobile, 'bn-BD', {
        strictMode: false,
      })
    ) {
      setVanPullerMobileError('Invalid phone');
    } else {
      setVanPullerMobileError(null);
    }
  }, [vanPullerMobile]);

  // verify van puller Number
  useEffect(() => {
    if (
      !validator.isMobilePhone(dealerManagerNumber, 'bn-BD', {
        strictMode: false,
      })
    ) {
      setDealerManagerNumberError('Invalid phone');
    } else {
      setDealerManagerNumberError(null);
    }
  }, [dealerManagerNumber]);

  //   verify Name
  useEffect(() => {
    if (name.length < 3) {
      setNameError('Valid name is required');
    } else {
      setNameError(null);
    }
  }, [name]);

  //   verify Recording Person Type
  useEffect(() => {
    if (recordingPersonType.length < 2) {
      setRecordingPersonTypeError('Must select a Type');
    } else {
      setRecordingPersonTypeError(null);
    }
  }, [recordingPersonType]);

  //   verify Dealer Manager Name
  useEffect(() => {
    if (dealerManagerName.length < 3) {
      setDealerManagerNameError('Dealer Manager Name is required');
    } else {
      setDealerManagerNameError(null);
    }
  }, [dealerManagerName]);

  //   verify Van puller Name
  useEffect(() => {
    if (vanPullerName.length < 3) {
      setVanPullerNameError('Van Puller Name is required');
    } else {
      setVanPullerNameError(null);
    }
  }, [vanPullerName]);

  //   verify customer
  useEffect(() => {
    if (customer?.name?.length < 3) {
      setCustomerError('Must select a Dealer');
    } else {
      setCustomerError('');
    }
  }, [customer]);

  //   verify Srname
  useEffect(() => {
    if (srName?.name?.length < 3) {
      setSrNameError('Must select an SO/SR');
    } else {
      setSrNameError('');
    }
  }, [srName]);

  // open modal
  useEffect(() => {
    if (
      localStorage.getItem('name') &&
      localStorage.getItem('mobileNo') &&
      localStorage.getItem('recordingPersonType') &&
      localStorage.getItem('regionId') &&
      localStorage.getItem('hqId')
    ) {
      setModalShow(false);
    } else if (x?.id) {
      setModalShow(false);
    } else {
      setModalShow(true);
    }
  }, [x?.id]);

  // edit modal
  const handleEdit = () => {
    setModalShow(true);
  };

  // save information on local storage
  const saveModal = () => {
    setShowModalError(true);
    if (!nameError && !mobileNoError && !recordingPersonTypeError) {
      setShowModalError(false);

      localStorage.setItem('name', name);
      localStorage.setItem('mobileNo', mobileNo);
      localStorage.setItem('recordingPersonType', recordingPersonType);
      localStorage.setItem('region', region.name);
      localStorage.setItem('regionId', region.id);
      localStorage.setItem('hq', hq.name);
      localStorage.setItem('hqId', hq.id);
      localStorage.setItem('pointTown', pointTown.name);
      localStorage.setItem('pointTownID', pointTown.id);

      window.location.reload();
    }
  };

  const handleSubmit = () => {
    setShowFormError(true);

    if (!x?.id) {
      getLocation();
    }

    if (
      !nameError &&
      !mobileNoError &&
      !customerError &&
      !srNameError &&
      !vanPullerNameError &&
      !vanPullerMobileError &&
      !dealerManagerNameError &&
      !dealerManagerNumberError
    ) {
      setShowFormError(false);
      setIsSubmitting(true);

      const data = new FormData();

      data.append('name', name);
      data.append('visitDate', visitDate);
      data.append('mobileNo', mobileNo);
      data.append('recordingPersonType', recordingPersonType);
      data.append('customer', customer?.id);
      data.append('srName', srName?.id);
      data.append('region', region?.id);
      data.append('hq', hq?.id);
      data.append('pointTown', pointTown?.id);
      data.append('dealerManagerName', dealerManagerName);
      data.append('dealerManagerNumber', dealerManagerNumber);
      data.append('vanPullerName', vanPullerName);
      data.append('vanPullerMobile', vanPullerMobile);
      data.append('latitude', latitude);
      data.append('longitude', longitude);
      if (x?.id) data.append('status', status);
      if (image instanceof File && image.type.startsWith('image/'))
        data.append('image', image);

      if (x?.id) {
        // Updating x document
        axiosPrivate
          .patch(`/api/v1/exdealer/${x?.id}`, data)
          .then((res) => {
            toast.success(res?.data?.message);

            if (res?.data?.data?.clamiedTaDa) {
              navigate('/exdealerinfo', { state: null });
            } else {
              setCreatedId(res?.data?.data?.id);
              setRedirectShow(true);
            }
          })
          .catch((error) => {
            toast.warn(error?.response?.data?.message);
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      } else {
        data.append('recordedBy', userId);

        axiosPrivate
          .post('/api/v1/exdealer', data)
          .then((res) => {
            toast.success(res?.data?.message);

            setCreatedId(res?.data?.data?.id);
            setRedirectShow(true);
          })
          .catch((error) => {
            toast.warn(error?.response?.data?.message);
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      }
    }
  };

  return (
    <>
      <Stack
        sx={{ maxWidth: '600px', margin: '20px auto' }}
        gap={2}
        p={2}
        pt={0}>
        <Stack
          direction="column"
          component={Paper}
          sx={{ borderRadius: '10px' }}
          p={3}
          gap={3}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography>Recording Person Information</Typography>
            <Button
              disabled={x?.id ? true : false}
              variant="contained"
              onClick={handleEdit}
              startIcon={<BorderColorIcon />}>
              Edit
            </Button>
          </Stack>

          <TextField
            disabled
            label="Name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={showFormError && nameError}
            helperText={showFormError && nameError}
          />

          <TextField
            disabled
            label="Mobile Number"
            type="text"
            value={mobileNo}
            onChange={(e) => setMobileNo(e.target.value)}
            error={showFormError && mobileNoError}
            helperText={showFormError && mobileNoError}
          />

          <FormControl
            disabled
            fullWidth
            error={showFormError && recordingPersonTypeError}>
            <InputLabel>Recording Person Type</InputLabel>
            <Select
              value={recordingPersonType}
              label="Recording Person Type"
              onChange={(e) => setRecordingPersonType(e.target.value)}>
              <MenuItem value="ASM">ASM</MenuItem>
              <MenuItem value="RSM">RSM</MenuItem>
              <MenuItem value="DSM">DSM</MenuItem>
              <MenuItem value="Others">Others</MenuItem>
            </Select>
            {showFormError ? (
              <FormHelperText>{recordingPersonTypeError}</FormHelperText>
            ) : null}
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              disabled
              label="Date of Visit"
              inputFormat="DD/MM/YYYY"
              value={visitDate}
              onChange={(newValue) => setVisitDate(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Stack>

        <Stack
          direction="column"
          component={Paper}
          sx={{ borderRadius: '10px' }}
          p={3}
          gap={3}>
          <Typography>Existing Dealer Information</Typography>

          <Autocomplete
            fullWidth
            disabled
            options={regionList}
            value={region}
            isOptionEqualToValue={(option, value) => value?.id === option?.id}
            onChange={(event, value) => {
              setRegion(value);
              setHq({});
              setPointTown({});
            }}
            getOptionLabel={(option) => option?.name || ''}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Select Region" />
            )}
          />

          <Autocomplete
            fullWidth
            disabled
            options={hqList}
            value={hq}
            isOptionEqualToValue={(option, value) => value?.id === option?.id}
            onChange={(event, value) => {
              setHq(value);
              setPointTown({});
            }}
            getOptionLabel={(option) => option?.name || ''}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Select HQ" />
            )}
          />

          <Autocomplete
            fullWidth
            // disabled
            options={pointTownList}
            value={pointTown}
            isOptionEqualToValue={(option, value) => value?.id === option?.id}
            onChange={(event, value) => {
              setPointTown(value);
              setCustomer({});
              setSrName({});
            }}
            getOptionLabel={(option) => option?.name || ''}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Select Point/Town" />
            )}
          />

          <Autocomplete
            fullWidth
            disabled={!pointTown?.id}
            options={customerList}
            value={customer}
            isOptionEqualToValue={(option, value) => value?.id === option?.id}
            onChange={(event, value) => {
              setCustomer(value);
            }}
            getOptionLabel={(option) =>
              option?.name ? `${option?.name} (${option?.code})` : ''
            }
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Exiting Dealer Name"
                error={showFormError && customerError}
                helperText={showFormError && customerError}
              />
            )}
          />

          <Autocomplete
            fullWidth
            disabled={!pointTown?.id}
            options={employeeList}
            value={srName}
            isOptionEqualToValue={(option, value) => value?.id === option?.id}
            onChange={(event, value) => {
              setSrName(value);
            }}
            getOptionLabel={(option) =>
              option?.nameCode ? option?.nameCode : option?.label || ''
            }
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="SO/SR"
                error={showFormError && srNameError}
                helperText={showFormError && srNameError}
              />
            )}
          />

          <TextField
            label="Van Puller Name"
            type="text"
            value={vanPullerName}
            onChange={(e) => setVanPullerName(e.target.value)}
            error={showFormError && vanPullerNameError}
            helperText={showFormError && vanPullerNameError}
          />

          <TextField
            label="Van Puller Mobile"
            type="text"
            value={vanPullerMobile}
            onChange={(e) => setVanPullerMobile(e.target.value)}
            error={showFormError && vanPullerMobileError}
            helperText={showFormError && vanPullerMobileError}
          />

          <TextField
            label="Dealer's Mananger Name"
            type="text"
            value={dealerManagerName}
            onChange={(e) => setDealerManagerName(e.target.value)}
            error={showFormError && dealerManagerNameError}
            helperText={showFormError && dealerManagerNameError}
          />

          <TextField
            label="Dealer's Mananger Number"
            type="text"
            value={dealerManagerNumber}
            onWheel={(e) => e.target.blur()}
            onChange={(e) => setDealerManagerNumber(e.target.value)}
            error={showFormError && dealerManagerNumberError}
            helperText={showFormError && dealerManagerNumberError}
          />

          {x?.id && ['Super Admin'].includes(role) ? (
            <FormControl>
              <FormLabel id="outlet-status-label">
                Verification Status
              </FormLabel>
              <RadioGroup
                aria-labelledby="outlet-status-label"
                name="outlet-status"
                value={status}
                defaultValue="pending"
                onChange={(e) => {
                  setStatus(e.target.value);
                }}>
                <FormControlLabel
                  value="pending"
                  control={<Radio />}
                  label={
                    <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                      Pending&nbsp;
                      <HelpOutlineIcon color="primary" fontSize="small" />
                    </Box>
                  }
                />
                <FormControlLabel
                  value="verified"
                  control={<Radio />}
                  label={
                    <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                      Verified&nbsp;
                      <CheckCircleIcon color="success" fontSize="small" />
                    </Box>
                  }
                />
                <FormControlLabel
                  value="failed"
                  control={<Radio />}
                  label={
                    <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                      Failed&nbsp;
                      <CancelIcon color="error" fontSize="small" />
                    </Box>
                  }
                />
              </RadioGroup>
            </FormControl>
          ) : null}

          {locationError ? (
            <p
              style={{
                fontSize: 'large',
                fontWeight: 'bold',
                color: 'red',
                textAlign: 'center',
              }}>
              {locationError}
            </p>
          ) : (
            <table width={200} style={{ margin: 'auto' }}>
              <tr>
                <th colSpan={2}>Location</th>
              </tr>
              <tr>
                <td>Latitude :</td>
                <td>{latitude}</td>
              </tr>
              <tr>
                <td>Longitude :</td>
                <td>{longitude}</td>
              </tr>
            </table>
          )}

          {showFormError && (
            <Typography
              variant="subtitle2"
              color="text.secondary"
              align="center">
              Make sure you have checked all input fields for errors before you
              proceed.
            </Typography>
          )}

          <Button
            disabled={isSubmitting}
            variant="contained"
            onClick={handleSubmit}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </Button>
        </Stack>
      </Stack>

      <Modals
        title="Set Recording Person Information"
        show={modalShow}
        width="lg"
        form
        hideicon>
        <Stack direction="column" gap={3}>
          <TextField
            label="Name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={showModalError && nameError}
            helperText={showModalError && nameError}
          />

          <TextField
            label="Mobile Number"
            type="text"
            value={mobileNo}
            onChange={(e) => setMobileNo(e.target.value)}
            error={showModalError && mobileNoError}
            helperText={showModalError && mobileNoError}
          />

          <FormControl
            fullWidth
            error={showFormError && recordingPersonTypeError}>
            <InputLabel>Recording Person Type</InputLabel>
            <Select
              value={recordingPersonType}
              label="Recording Person Type"
              onChange={(e) => setRecordingPersonType(e.target.value)}>
              <MenuItem value="ASM">ASM</MenuItem>
              <MenuItem value="RSM">RSM</MenuItem>
              <MenuItem value="DSM">DSM</MenuItem>
              <MenuItem value="Others">Others</MenuItem>
            </Select>
            {showModalError ? (
              <FormHelperText>{recordingPersonTypeError}</FormHelperText>
            ) : null}
          </FormControl>

          <Autocomplete
            fullWidth
            disabled={regionList.length < 1}
            options={regionList}
            value={region}
            isOptionEqualToValue={(option, value) => value?.id === option?.id}
            onChange={(event, value) => {
              setRegion(value);
              setHq({});
              setPointTown({});
            }}
            getOptionLabel={(option) => option?.name || ''}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Select Region" />
            )}
          />

          <Autocomplete
            fullWidth
            disabled={!region?.id || hqList.length < 1}
            options={hqList}
            value={hq}
            isOptionEqualToValue={(option, value) => value?.id === option?.id}
            onChange={(event, value) => {
              setHq(value);
              setPointTown({});
            }}
            getOptionLabel={(option) => option?.name || ''}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Select HQ" />
            )}
          />

          <Autocomplete
            fullWidth
            disabled={!hq?.id || pointTownList.length < 1}
            options={pointTownList}
            value={pointTown}
            isOptionEqualToValue={(option, value) => value?.id === option?.id}
            onChange={(event, value) => {
              setPointTown(value);
            }}
            getOptionLabel={(option) => option?.name || ''}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Select Point/Town" />
            )}
          />
        </Stack>

        <Button
          fullWidth
          disabled={
            name &&
            mobileNo &&
            recordingPersonType &&
            region?.name &&
            hq?.name &&
            pointTown?.name
              ? false
              : true
          }
          variant="contained"
          onClick={saveModal}
          sx={{ mt: 3 }}>
          Save
        </Button>
      </Modals>

      <RedirectDialog
        open={redirectShow}
        onClose={() => {
          setRedirectShow(false);
          navigate('/exdealerinfo', { state: null });
        }}
        onAccept={() => {
          setRedirectShow(false);
          navigate('/tadaform', {
            state: {
              exDealerVisitRef: createdId,
              travelDate: visitDate?.toDate(),
              reasonForTravel: 'Existing dealer visit',
              region: region,
              headquarter: hq,
              point: pointTown,
              latitude: latitude,
              longitude: longitude,
              from: 'FORM',
            },
          });
        }}
      />
    </>
  );
}
