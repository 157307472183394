import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Modals from '../../component/Modals';
import ProductQuantityInput from '../../component/ProductQuantityInput';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import isEmptyObject from '../../utils/isEmptyObject';

function ModifyTarget() {
  const navigate = useNavigate();
  const params = useParams();
  const [refetch] = useOutletContext();
  const axiosPrivate = useAxiosPrivate();
  const { userId, user, role } = useAuth();
  const id = params.targetId;

  // form data states
  const [customer, setCustomer] = React.useState({});
  const [selectedAt, setSelectedAt] = React.useState(new Date());

  const [customersList, setCustomersList] = React.useState([]);
  const [productsList, setProductsList] = React.useState([]);
  const [oldTarget, setOldTarget] = React.useState({});
  const [salesData, setSalesData] = React.useState(null);

  // rendering triggers
  const [triggerA, setTriggerA] = React.useState(0);
  const [triggerB, setTriggerB] = React.useState(0);
  const [triggerC, setTriggerC] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [filter, setFilter] = React.useState('');

  // preview states
  const [showPreview, setShowPreview] = React.useState(false);
  const [previewData, setPreviewData] = React.useState({});

  const handleSubmit = () => {
    setIsLoading(true);

    let method = 'post';
    let url = '/api/v1/target';
    const data = {
      customer: customer?.id,
      employee: userId,
      selectedAt,
      products: [],
    };

    productsList?.forEach((p) => {
      if (p?.targetQuantity > 0 || p?.saleQuantity > 0) {
        data.products.push(p);
      }
    });

    if (!data.products.length) {
      toast.warn('Nothing to update!');
      return;
    }

    if (id) {
      method = 'patch';
      url += `/${id}`;
    }

    axiosPrivate({ method, url, data })
      .then((res) => {
        toast.success(res?.data?.message);
        refetch();
        navigate('/target');
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.message);
      })
      .finally(() => setIsLoading(false));
  };

  const handleChange = (index, quantity) => {
    const list = [...productsList];
    list[index].targetQuantity = quantity;

    setProductsList(list);
    setTriggerB(Date.now());
  };

  const getSalesData = (start, end, customer) => {
    if (!customer?.territory?.id) return;

    setIsLoading(true);

    let notFound = true;
    const sd = start?.format('YYYY-MM-DD');
    const ed = end?.format('YYYY-MM-DD');

    axiosPrivate
      .get(
        `/api/v1/report/national-audited-ims?startDate=${sd}&endDate=${ed}&customer=${customer.id}`,
      )
      .then((res) => {
        const d = res?.data?.data?.[0];

        if (d?.stockStart?.products?.length > 0) {
          const sales = {};
          d?.inMarketSale?.products?.forEach((p) => {
            sales[p?.id] = p?.quantity > 0 ? p.quantity : 0;
          });

          setSalesData({
            isFound: true,
            customer: d?.customer?.id,
            month: dayjs(ed).format('YYYY-MM'),
            sales,
          });
          setTriggerB(Date.now());

          notFound = false;
        }

        if (notFound) {
          if (
            dayjs(end).subtract(1, 'month').startOf('month').unix() >
            dayjs(start).unix()
          ) {
            setSalesData({
              isFound: false,
              customer: customer?.id,
              month: dayjs(ed).format('YYYY-MM'),
            });
            setTriggerB(Date.now());
          } else {
            setSalesData(null);
            setTriggerB(Date.now());

            getSalesData(
              dayjs(start).subtract(3, 'day').startOf('date'),
              dayjs(end),
              customer,
            );
          }
        }
      })
      .catch((err) => toast.warn(err?.response?.data?.message));
  };

  // get sales data
  React.useEffect(() => {
    if (
      salesData?.isFound === false &&
      salesData?.customer === customer?.id &&
      salesData?.month ===
        dayjs(selectedAt).subtract(1, 'month').format('YYYY-MM')
    ) {
      toast.info('Sales data not found');
    } else if (
      salesData === null ||
      salesData?.customer !== customer?.id ||
      salesData?.month !==
        dayjs(selectedAt).subtract(1, 'month').format('YYYY-MM')
    ) {
      getSalesData(
        dayjs(selectedAt).subtract(2, 'month').endOf('month').startOf('date'),
        dayjs(selectedAt).subtract(1, 'month').endOf('month'),
        customer,
      );
    }
  }, [customer?.id, selectedAt]);

  // Get old target data
  React.useEffect(() => {
    setIsLoading(true);

    if (id) {
      axiosPrivate
        .get(`/api/v1/target/${id}?populate=yes`)
        .then((res) => {
          const old = {};
          res?.data?.data?.products?.forEach((p) => {
            old[p?.product?.id] = p;
          });
          setOldTarget(old);
          setCustomer(res?.data?.data?.customer);
          setSelectedAt(res?.data?.data?.selectedAt);
          setTriggerA(Date.now());
        })
        .catch((err) => {
          toast.warn(
            `Failed to get target data. ${err?.response?.data?.message}`,
          );
        });
    }
  }, [id]);

  // Get all products
  React.useEffect(() => {
    if (id && isEmptyObject(oldTarget)) return;

    axiosPrivate
      .get('/api/v1/product?populate=yes&limit=none&isActive=true&sort=name')
      .then((res) => {
        const allProducts = [];
        res?.data?.data?.forEach((p) => {
          const targetQuantity = oldTarget?.[p?.id]?.targetQuantity || 0;
          const quantityPerCarton = p?.quantityPerCarton;

          const cartons = Math.floor(targetQuantity / quantityPerCarton);
          const packets = targetQuantity % quantityPerCarton;
          let targetQuantityDisplay = `${cartons} ctn`;
          if (packets) {
            targetQuantityDisplay += ` ${packets} pcs`;
          }

          allProducts.push({
            ...p,
            product: p?.id,
            targetQuantity,
            targetQuantityDisplay,
          });
        });

        setProductsList(allProducts);
        setTriggerB(Date.now());
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.message);
      });
  }, [triggerA]);

  // set sales data in products list
  React.useEffect(() => {
    const products = [];
    const previewList = [];

    let cartonsSum = 0;
    let packetsSum = 0;

    productsList.forEach((p) => {
      const saleQuantity = salesData?.sales?.[p?.id] || 0;
      const targetQuantity = p.targetQuantity;
      const quantityPerCarton = p?.quantityPerCarton;

      const cartonsS = Math.floor(saleQuantity / quantityPerCarton);
      const packetsS = saleQuantity % quantityPerCarton;

      const cartonsT = Math.floor(targetQuantity / quantityPerCarton);
      const packetsT = targetQuantity % quantityPerCarton;

      let saleQuantityDisplay = `${cartonsS} ctn`;
      if (packetsS) {
        saleQuantityDisplay += ` ${packetsS} pcs`;
      }

      let targetQuantityDisplay = `${cartonsT} ctn`;
      if (packetsT) {
        targetQuantityDisplay += ` ${packetsT} pcs`;
      }

      cartonsSum += cartonsT;
      packetsSum += packetsT;

      const product = {
        ...p,
        saleQuantity,
        saleQuantityDisplay,
        targetQuantityDisplay,
      };

      if (targetQuantity > 0 || saleQuantity > 0) {
        previewList.push(product);
      }
      products.push(product);
    });

    let quantitySumDisplay = `${cartonsSum} ctn`;
    if (packetsSum) {
      quantitySumDisplay += ` ${packetsSum} pcs`;
    }

    setPreviewData({
      products: previewList,
      hasProducts: Boolean(previewList.length > 0),
      quantitySumDisplay,
    });
    setProductsList(products);
    setTriggerC(Date.now());
    setIsLoading(false);
  }, [triggerB]);

  // Get all customers
  React.useEffect(() => {
    let url = `/api/v1/customer?populate=yes&limit=none&isActive=true&sort=code`;

    if (['NSM', 'DSM', 'RSM', 'ASM', 'SO/SR', 'DSR'].includes(role)) {
      url += `&parentTerritory=${user?.employee?.territory?.id}`;
    }

    axiosPrivate
      .get(url)
      .then((res) => {
        if (res?.data?.data) {
          setCustomersList(res.data.data);
        }
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.message);
      });
  }, []);

  const productsControl = React.useMemo(() => {
    if (!customer?.id) {
      return (
        <Typography color="text.disabled">
          Select target customer to load products.
        </Typography>
      );
    }

    if (productsList.length < 1) {
      return <Typography color="text.disabled">Loading products...</Typography>;
    }

    return productsList.map((p, i) => {
      if (
        filter &&
        !p?.nameCode?.toLowerCase()?.includes(filter?.toLowerCase())
      ) {
        return null;
      }

      return (
        <Card key={p?.id} variant="outlined">
          <Stack direction="column" gap={1} p={2}>
            <Typography variant="subtitle1" fontWeight="bold">
              {`${i + 1}. ${p.nameCode}`}
            </Typography>

            <Typography variant="subtitle1">
              {`${dayjs(selectedAt)
                .subtract(1, 'month')
                .format('MMM YYYY')} IMS: `}
              {salesData === null ? (
                <Typography
                  component="span"
                  color="text.disabled"
                  variant="inherit">
                  Loading...
                </Typography>
              ) : salesData?.isFound === false ? (
                <Typography
                  component="span"
                  color="error.main"
                  variant="inherit">
                  No data
                </Typography>
              ) : (
                <Typography
                  component="span"
                  color="success.main"
                  variant="inherit"
                  fontWeight="bold">
                  {p?.saleQuantityDisplay}
                </Typography>
              )}
            </Typography>

            <Typography color="primary.main" variant="subtitle1">
              {`${dayjs(selectedAt).format('MMM YYYY')} Target: `}
            </Typography>

            <ProductQuantityInput
              size="small"
              cartonUnits={p?.quantityPerCarton}
              value={p?.targetQuantity}
              onChange={(q) => handleChange(i, q)}
            />
          </Stack>
        </Card>
      );
    });
  }, [customer?.id, triggerC]);

  return (
    <Container maxWidth="md">
      <IconButton
        aria-label="go back"
        sx={{ mb: 1 }}
        onClick={() => navigate('/target')}>
        <ArrowBackIcon />
      </IconButton>

      <Box>
        <Paper elevation={2} sx={{ p: 3 }}>
          <Stack gap={2}>
            <Autocomplete
              fullWidth
              disableClearable
              disabled={Boolean(id || customersList?.length < 1)}
              options={customersList}
              value={customer}
              isOptionEqualToValue={(option, value) => value?.id === option?.id}
              onChange={(event, value) => setCustomer(value)}
              getOptionLabel={(option) =>
                option?.name
                  ? `${option?.territory?.name} - ${option?.nameCode}`
                  : ''
              }
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Target Customer" />
              )}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Target Month"
                disableFuture
                views={['month', 'year']}
                inputFormat="MMMM YYYY"
                value={selectedAt}
                onChange={(newValue) => setSelectedAt(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={2}>
              <Typography variant="h6" color="primary">
                Target details
              </Typography>

              <TextField
                type="search"
                size="small"
                variant="outlined"
                placeholder="Search products"
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                  setTriggerC(Date.now());
                }}
              />
            </Stack>

            {productsControl}
          </Stack>
        </Paper>
      </Box>

      <Box sx={{ my: 2, textAlign: 'center' }}>
        <Button
          disabled={isLoading || !customer?.id || !previewData.hasProducts}
          size="large"
          color="primary"
          variant="contained"
          onClick={() => setShowPreview(true)}>
          Preview
        </Button>
      </Box>

      <Modals
        title="Preview"
        show={showPreview}
        onSave={() => setShowPreview(false)}
        onHide={() => setShowPreview(false)}
        width="lg"
        form>
        <Typography color="primary">Target Location:</Typography>
        <Typography gutterBottom>{customer?.territory?.nameCode}</Typography>

        <Typography color="primary">Target Customer:</Typography>
        <Typography gutterBottom>{customer?.nameCode}</Typography>

        <Typography color="primary">Target Month:</Typography>
        <Typography gutterBottom>
          {selectedAt ? dayjs(selectedAt).format('MMMM YYYY') : ''}
        </Typography>

        <Typography color="primary">Products:</Typography>

        <Stack direction={'column'} gap={2} sx={{ pb: 2 }}>
          {previewData?.products?.map((p, index) => {
            return (
              <Paper
                key={p?.id}
                variant="outlined"
                sx={{ p: 1, minWidth: 360 }}>
                <Typography fontWeight="bold">
                  {`${index + 1}. ${p?.nameCode}`}
                </Typography>

                <Typography variant="subtitle2">
                  {`${dayjs(selectedAt)
                    .subtract(1, 'month')
                    .format('MMM YYYY')} IMS: `}
                  {salesData?.isFound === false ? (
                    <Typography
                      component="span"
                      color="error.main"
                      variant="inherit">
                      No data
                    </Typography>
                  ) : (
                    <Typography
                      component="span"
                      color="success.main"
                      variant="inherit"
                      fontWeight="bold">
                      {p?.saleQuantityDisplay}
                    </Typography>
                  )}
                </Typography>

                <Typography variant="subtitle2">
                  {`${dayjs(selectedAt).format('MMM YYYY')} Target: `}
                  <b>{p?.targetQuantityDisplay}</b>
                </Typography>
              </Paper>
            );
          })}
        </Stack>

        <Typography color="primary">Total Target:</Typography>
        <Typography gutterBottom>{previewData?.quantitySumDisplay}</Typography>

        <br />

        <Button
          disabled={isLoading}
          fullWidth
          size="large"
          color="primary"
          variant="contained"
          onClick={handleSubmit}>
          Save
        </Button>
      </Modals>
    </Container>
  );
}

export default ModifyTarget;
