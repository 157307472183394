import React, { useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Box,
  LinearProgress,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import TableSkeleton from '../../../../component/Skeleton/TableSkeleton';
import { DateRangeColumnFilter } from '../../../../component/Table/SelectColumnFilter';
import Table from '../../../../component/Table/Table';
import Title from '../../../../component/report/Title';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import useExpiredSession from '../../../../hooks/useExpiredSession';
import roundToDigit from '../../../../utils/roundToDigit';
import CustomerProfileHeader from '../CustomerProfileHeader/CustomerProfileHeader';

function LedgerReport() {
  const params = useParams();
  const location = useLocation();

  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();

  const isReport = Boolean(location.pathname.startsWith('/report/cust-ledger'));

  const [customer, setCustomer] = useState({ id: params?.customerID });
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(false);
  const [allCustomers, setAllCustomers] = useState([]);

  // get all customers
  useEffect(() => {
    if (isReport) {
      setIsLoadingCustomers(true);
      axiosPrivate
        .get('/api/v1/customer?populate=yes&limit=none&sort=name')
        .then((res) => {
          setAllCustomers(res?.data?.data || []);
        })
        .catch((error) => {
          expired(error);
        })
        .finally(() => {
          setIsLoadingCustomers(false);
        });
    }
  }, [isReport]);

  // get report data
  async function getLedgerReport({ queryKey: [key, id] }) {
    if (!id) return [];

    const res = await axiosPrivate.get(
      `/api/v1/report/customer-ledger?customerId=${id}`,
    );

    return res?.data?.data || [];
  }

  const { data, isLoading, isError, error } = useQuery(
    ['customer-ledger', customer?.id],
    getLedgerReport,
  );

  if (isError) {
    expired(error);
  }

  const COLUMNS = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
        Cell: ({ row }) => {
          return row?.original?.dateF;
        },
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Opening',
        accessor: 'opening',
        Cell: ({ value }) => roundToDigit(value),
      },
      {
        Header: 'Sales',
        accessor: 'sales',
        Cell: ({ value }) => roundToDigit(value),
      },
      {
        Header: 'Deposit',
        accessor: 'deposit',
        Cell: ({ value }) => roundToDigit(value),
      },
      {
        Header: 'Transport',
        accessor: 'transport',
        Cell: ({ value }) => roundToDigit(value),
      },
      {
        Header: 'Return',
        accessor: 'return',
        Cell: ({ value }) => roundToDigit(value),
      },
      {
        Header: 'Comm',
        accessor: 'commission',
        Cell: ({ value }) => roundToDigit(value),
      },
      {
        Header: 'Salary',
        accessor: 'salary',
        Cell: ({ value }) => roundToDigit(value),
      },
      {
        Header: 'Bank Ch.',
        accessor: 'cost',
        Cell: ({ value }) => roundToDigit(value),
      },
      {
        Header: 'Damage',
        accessor: 'damage',
        Cell: ({ value }) => roundToDigit(value),
      },
      {
        Header: 'Total Debit',
        accessor: 'totalDebit',
        Cell: ({ value }) => roundToDigit(value),
      },
      {
        Header: 'Total Credit',
        accessor: 'totalCredit',
        Cell: ({ value }) => roundToDigit(value),
      },
      {
        Header: 'Closing',
        accessor: 'closing',
        Cell: ({ value }) => roundToDigit(value),
      },
    ],
    [],
  );

  return (
    <>
      {isReport ? (
        <>
          <Title title="Customer Ledger">
            <Box sx={{ minWidth: { xs: 240, sm: 360, lg: 480 } }}>
              {isLoadingCustomers ? (
                <div>
                  <Typography color="text.disabled" variant="body2">
                    Loading customer list...
                  </Typography>

                  <LinearProgress />
                </div>
              ) : (
                <Autocomplete
                  fullWidth
                  disabled={allCustomers.length < 1}
                  options={allCustomers}
                  value={customer}
                  isOptionEqualToValue={(option, value) =>
                    value?.id === option?.id
                  }
                  onChange={(event, value) => {
                    setCustomer(value);
                  }}
                  getOptionLabel={(option) =>
                    option?.name
                      ? `${option?.nameCode} - ${option?.customerType?.customerType} - ${option?.territory?.name}`
                      : ''
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Select customer" />
                  )}
                  size="small"
                />
              )}
            </Box>
          </Title>

          {customer?.name ? (
            <Typography variant="h6" sx={{ pt: 1 }}>
              {`${customer?.nameCode} - ${customer?.customerType?.customerType} - ${customer?.territory?.name}`}
            </Typography>
          ) : null}
        </>
      ) : (
        <CustomerProfileHeader cID={customer?.id} />
      )}

      {!customer?.id ? (
        <Paper
          variant="outlined"
          sx={{
            mt: 4,
            p: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Typography color="text.disabled" variant="body2" fontSize={24}>
            Select a customer from above to see their ledger report.
          </Typography>
        </Paper>
      ) : isLoading ? (
        <TableSkeleton />
      ) : (
        <Table
          columns={COLUMNS}
          data={data || []}
          filename="Customer Ledger"
          hideAddIcon
        />
      )}
    </>
  );
}

export default LedgerReport;
