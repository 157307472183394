import dayjs from 'dayjs';
import React from 'react';
import roundToDigit from '../utils/roundToDigit';
import useAxiosPrivate from './useAxiosPrivate';

const usePayroll = () => {
  const axiosPrivate = useAxiosPrivate();

  const [data, setData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [error, setError] = React.useState(null);

  const getData = React.useCallback(
    /**
     * @param {Date} startDate Start date
     * @param {Date} endDate End date
     * @param {String} employee Employee ID (MongoDB _id)
     */
    (startDate, endDate, employee) => {
      if (!startDate || !endDate) {
        setIsError(true);
        setError({
          response: {
            data: {
              status: 'failed',
              message: 'Start Date and End Date required',
            },
          },
        });
        return;
      }

      setIsLoading(true);

      const start = dayjs(startDate);
      const end = dayjs(endDate);
      const dI = start.subtract(3, 'day').startOf('day').toISOString();
      const dS = start.toISOString();
      const dE = end.toISOString();
      let collectionsUrl = `/api/v1/ledger?populate=yes&limit=none&type=collection&dateOfDeposit[gte]=${dS}&dateOfDeposit[lte]=${dE}`;
      let transactionsUrl = `/api/v1/transaction?populate=yes&limit=none&deliveredAt[gte]=${dS}&deliveredAt[lte]=${dE}`;
      let empLedgerUrl = `/api/v1/employee-ledger?limit=none&dateOfEntry[lte]=${dE}`;
      let travelAllowancesUrl = `/api/v1/travel-allowance?limit=none&status=approved&travelDate[gte]=${dS}&travelDate[lte]=${dE}`;
      let attendanceSRUrl = `/api/v1/report/sr-attendance?startDate=${dS}&endDate=${dE}`;
      let attendanceSMUrl = `/api/v1/report/employee-attendance?startDate=${dS}&endDate=${dE}`;
      let salesReportUrl = `/api/v1/report/national-audited-ims?populate=yes&startDate=${dI}&endDate=${dE}`;
      if (employee) {
        empLedgerUrl += `&employee=${employee}`;
        travelAllowancesUrl += `&employee=${employee}`;
        attendanceSRUrl += `&employee=${employee}`;
        attendanceSMUrl += `&employee=${employee}`;
      }
      Promise.all([
        axiosPrivate.get(collectionsUrl),
        axiosPrivate.get(transactionsUrl),
        axiosPrivate.get(empLedgerUrl),
        axiosPrivate.get(travelAllowancesUrl),
        axiosPrivate.get(attendanceSRUrl),
        axiosPrivate.get(attendanceSMUrl),
        axiosPrivate.get(salesReportUrl),
      ])
        .then((results) => {
          const collections = results?.[0]?.data?.data;
          const transactions = results?.[1]?.data?.data;
          const empLedgers = results?.[2]?.data?.data;
          const travelAllowances = results?.[3]?.data?.data;
          const attendanceSR = results?.[4]?.data?.data;
          const attendanceSM = results?.[5]?.data?.data;
          const inMarketSales = results?.[6]?.data?.data;

          const employees = {};

          // SO/SR
          attendanceSR?.forEach((a) => {
            const id = a?.employee?.id;
            const employee = { ...a?.employee };

            delete employee.territory;
            employee.territories = a?.territories;

            let totalAttendance = 0;
            let totalWorkWith = 0;
            Object.values(a?.dates)?.forEach((t) => {
              if (t?.activities?.length > 0) {
                totalAttendance += 1;
              }
              if (t?.workWith?.length > 0) {
                totalWorkWith += t?.workWith?.length;
              }
            });

            if (!employees[id]) {
              employees[id] = {
                startDate,
                endDate,
                employee,
                totalAttendance,
                totalWorkWith,
                foundLedgerTypes: [],
                ticketsPlus: [],
                ticketsMinus: [],
                inMarketSales: [],
                lifting: [],
                collections: [],
                totalLifting: '-',
                totalInMarketSalesQuantity: '-',
                totalInMarketSalesValue: 0,
                totalCollection: 0,
                totalTicketsPlus: 0,
                totalTicketsMinus: 0,
                totalDearnessAllowance: 0,
                totalTravelAllowance: 0,
                totalHotelBill: 0,
                totalOtherBill: 0,
                salaryBasic: employee?.salaryBasic || 0,
                salaryMobile: employee?.salaryMobile || 0,
                salaryInternet: employee?.salaryInternet || 0,
                previousDues: 0,
                totalPayable: 0,
                totalPaid: 0,
                totalDue: 0,
                salaryCollectionPercent: 0,
                salaryInMarketSalesPercent: 0,
                travelAllowanceCollectionPercent: 0,
                travelAllowanceInMarketSalesPercent: 0,
                totalPayCollectionPercent: 0,
                totalPayInMarketSalesPercent: 0,
              };
            }
          });

          // ASM/RSM/DSM
          attendanceSM?.forEach((a) => {
            const id = a?.employee?.id;
            const employee = { ...a?.employee };

            delete employee.territory;
            employee.territories = {
              'rank-2': a?.region,
              'rank-3': a?.headquarter,
            };

            const totalAttendance = a?.totalAttendance || 0;
            const totalWorkWith = a?.totalWorkWith || 0;

            if (!employees[id]) {
              employees[id] = {
                startDate,
                endDate,
                employee,
                totalAttendance,
                totalWorkWith,
                foundLedgerTypes: [],
                ticketsPlus: [],
                ticketsMinus: [],
                inMarketSales: [],
                lifting: [],
                collections: [],
                totalLifting: '-',
                totalInMarketSalesQuantity: '-',
                totalInMarketSalesValue: 0,
                totalCollection: 0,
                totalTicketsPlus: 0,
                totalTicketsMinus: 0,
                totalDearnessAllowance: 0,
                totalTravelAllowance: 0,
                totalHotelBill: 0,
                totalOtherBill: 0,
                salaryBasic: employee?.salaryBasic || 0,
                salaryMobile: employee?.salaryMobile || 0,
                salaryInternet: employee?.salaryInternet || 0,
                previousDues: 0,
                totalPayable: 0,
                totalPaid: 0,
                totalDue: 0,
                salaryCollectionPercent: 0,
                salaryInMarketSalesPercent: 0,
                travelAllowanceCollectionPercent: 0,
                travelAllowanceInMarketSalesPercent: 0,
                totalPayCollectionPercent: 0,
                totalPayInMarketSalesPercent: 0,
              };
            }
          });

          Object.entries(employees)?.forEach(([eId, emp]) => {
            // Lifting
            let liftingCartons = 0;
            let liftingPieces = 0;
            transactions?.forEach((t) => {
              if (
                t?.transactionType?.function === 'sale' &&
                ((emp?.employee?.role?.territoryType?.rank === 4 &&
                  emp?.employee?.territories?.['rank-4']?.id ===
                    t?.receiver?.territory?.id) ||
                  (emp?.employee?.role?.territoryType?.rank === 3 &&
                    emp?.employee?.territories?.['rank-3']?.id ===
                      t?.receiver?.territory?.parentTerritory?.id) ||
                  (emp?.employee?.role?.territoryType?.rank === 2 &&
                    emp?.employee?.territories?.['rank-2']?.id ===
                      t?.receiver?.territory?.parentTerritory
                        ?.parentTerritory) ||
                  emp?.employee?.role?.territoryType?.rank === 1)
              ) {
                employees[eId].lifting.push(t);

                t?.products?.forEach((p) => {
                  const qpc = p?.quantityPerCarton;
                  const quantity = p?.quantity + p?.freeQuantity;

                  const quantityC = Math.floor(quantity / qpc);
                  const quantityP = quantity % qpc;

                  liftingCartons += quantityC;
                  liftingPieces += quantityP;
                });
              }
            });
            let liftingDisplay = '-';
            if (liftingCartons || liftingPieces) {
              liftingDisplay = `${liftingCartons} ctn`;
              if (liftingPieces) liftingDisplay += ` ${liftingPieces} pcs`;
            }
            employees[eId].totalLifting = liftingDisplay;

            // IMS
            let inMarketSalesCartons = 0;
            let inMarketSalesPieces = 0;
            inMarketSales?.forEach((s) => {
              if (
                (emp?.employee?.role?.territoryType?.rank === 4 &&
                  emp?.employee?.territories?.['rank-4']?.id ===
                    s?.territories?.['rank-4']?.id) ||
                (emp?.employee?.role?.territoryType?.rank === 3 &&
                  emp?.employee?.territories?.['rank-3']?.id ===
                    s?.territories?.['rank-3']?.id) ||
                (emp?.employee?.role?.territoryType?.rank === 2 &&
                  emp?.employee?.territories?.['rank-2']?.id ===
                    s?.territories?.['rank-2']?.id) ||
                emp?.employee?.role?.territoryType?.rank === 1
              ) {
                employees[eId].inMarketSales.push(s);
                employees[eId].totalInMarketSalesValue +=
                  s?.inMarketSale?.total?.tradePrice;
                inMarketSalesCartons += s?.inMarketSale?.total?.quantityC;
                inMarketSalesPieces += s?.inMarketSale?.total?.quantityP;
              }
            });
            let inMarketSalesDisplay = '-';
            if (inMarketSalesCartons || inMarketSalesPieces) {
              inMarketSalesDisplay = `${inMarketSalesCartons} ctn`;
              if (inMarketSalesPieces)
                inMarketSalesDisplay += ` ${inMarketSalesPieces} pcs`;
            }
            employees[eId].totalInMarketSalesQuantity = inMarketSalesDisplay;

            // Collection
            collections?.forEach((c) => {
              if (
                (emp?.employee?.role?.territoryType?.rank === 4 &&
                  emp?.employee?.territories?.['rank-4']?.id ===
                    c?.customer?.territory?.id) ||
                (emp?.employee?.role?.territoryType?.rank === 3 &&
                  emp?.employee?.territories?.['rank-3']?.id ===
                    c?.customer?.territory?.parentTerritory?.id) ||
                (emp?.employee?.role?.territoryType?.rank === 2 &&
                  emp?.employee?.territories?.['rank-2']?.id ===
                    c?.customer?.territory?.parentTerritory?.parentTerritory) ||
                emp?.employee?.role?.territoryType?.rank === 1
              ) {
                employees[eId].collections.push(c);

                employees[eId].totalCollection +=
                  c?.approvedAmount + c?.costAmount;
              }
            });

            // TA & DA
            if (emp?.employee?.role?.attendanceMethod === 'representative') {
              employees[eId].totalDearnessAllowance =
                emp?.totalAttendance *
                emp?.employee?.role?.inTerritoryAllowance;

              const collectionSurplus =
                employees[eId].totalCollection -
                emp?.employee?.minimumCollection;

              if (collectionSurplus < 0) {
                employees[eId].salaryBasic =
                  (employees[eId].totalCollection *
                    (emp?.employee?.salaryPercent || 0)) /
                  100;

                employees[eId].totalTravelAllowance = 0;
              } else {
                employees[eId].totalTravelAllowance =
                  (collectionSurplus *
                    (emp?.employee?.travelAllowancePercent || 0)) /
                  100;
              }
            } else {
              travelAllowances?.forEach((t) => {
                if (eId === t?.employee) {
                  if (t?.dearnessAllowance > 0) {
                    employees[eId].totalDearnessAllowance += t?.approvedBill;
                  }
                  if (t?.travelAllowance > 0) {
                    employees[eId].totalTravelAllowance += t?.approvedBill;
                  }
                  if (t?.hotelBill > 0) {
                    employees[eId].totalHotelBill += t?.approvedBill;
                  }
                  if (t?.otherBill > 0) {
                    employees[eId].totalOtherBill += t?.approvedBill;
                  }
                }
              });
            }

            // Tickets & dues
            empLedgers?.forEach((s) => {
              if (eId === s?.employee) {
                const amount = s?.approvedAmount;

                // entries before start time
                if (dayjs(s?.dateOfEntry) < start) {
                  employees[eId].previousDues += amount;
                } else {
                  if (s?.isTicket) {
                    // tickets
                    if (amount > 0) {
                      employees[eId].ticketsPlus.push(s);
                      employees[eId].totalTicketsPlus += amount;
                    } else {
                      employees[eId].ticketsMinus.push(s);
                      employees[eId].totalTicketsMinus += amount;
                    }
                  } else {
                    // other entries
                    switch (s?.type) {
                      case 'BASIC SALARY':
                        employees[eId].foundLedgerTypes.push('BASIC SALARY');
                        employees[eId].salaryBasic = amount;
                        break;

                      case 'MOBILE BILL':
                        employees[eId].foundLedgerTypes.push('MOBILE BILL');
                        employees[eId].salaryMobile = amount;
                        break;

                      case 'INTERNET BILL':
                        employees[eId].foundLedgerTypes.push('INTERNET BILL');
                        employees[eId].salaryInternet = amount;
                        break;

                      case 'DA':
                        employees[eId].foundLedgerTypes.push('DA');
                        employees[eId].totalDearnessAllowance = amount;
                        break;

                      case 'TA':
                        employees[eId].foundLedgerTypes.push('TA');
                        employees[eId].totalTravelAllowance = amount;
                        break;

                      case 'HOTEL BILL':
                        employees[eId].foundLedgerTypes.push('HOTEL BILL');
                        employees[eId].totalHotelBill = amount;
                        break;

                      case 'OTHER BILL':
                        employees[eId].foundLedgerTypes.push('OTHER BILL');
                        employees[eId].totalOtherBill = amount;
                        break;

                      case 'NET PAID':
                        employees[eId].foundLedgerTypes.push('NET PAID');
                        employees[eId].totalPaid += amount * -1;
                        break;

                      default:
                        employees[eId].totalPayable += amount;
                        break;
                    }
                  }
                }
              }
            });

            // payable amount calculation
            employees[eId].totalPayable +=
              employees[eId].previousDues +
              employees[eId].totalTicketsPlus +
              employees[eId].totalTicketsMinus +
              employees[eId].salaryBasic +
              employees[eId].salaryMobile +
              employees[eId].salaryInternet +
              employees[eId].totalDearnessAllowance +
              employees[eId].totalTravelAllowance +
              employees[eId].totalHotelBill +
              employees[eId].totalOtherBill;

            employees[eId].totalDue =
              employees[eId].totalPayable - employees[eId].totalPaid;

            // percentage calculation
            employees[eId].salaryCollectionPercent =
              (employees[eId].salaryBasic / employees[eId].totalCollection) *
              100;

            employees[eId].salaryInMarketSalesPercent =
              (employees[eId].salaryBasic /
                employees[eId].totalInMarketSalesValue) *
              100;

            employees[eId].travelAllowanceCollectionPercent =
              (employees[eId].totalDearnessAllowance /
                employees[eId].totalCollection) *
              100;

            employees[eId].travelAllowanceInMarketSalesPercent =
              (employees[eId].totalDearnessAllowance /
                employees[eId].totalInMarketSalesValue) *
              100;

            employees[eId].totalPayCollectionPercent =
              (employees[eId].totalPayable / employees[eId].totalCollection) *
              100;

            employees[eId].totalPayInMarketSalesPercent =
              (employees[eId].totalPayable /
                employees[eId].totalInMarketSalesValue) *
              100;

            // rounding digits
            employees[eId].totalInMarketSalesValue = roundToDigit(
              employees[eId].totalInMarketSalesValue,
            );

            employees[eId].totalCollection = roundToDigit(
              employees[eId].totalCollection,
            );

            employees[eId].totalTicketsPlus = roundToDigit(
              employees[eId].totalTicketsPlus,
            );

            employees[eId].totalTicketsMinus = roundToDigit(
              employees[eId].totalTicketsMinus,
            );

            employees[eId].totalDearnessAllowance = roundToDigit(
              employees[eId].totalDearnessAllowance,
            );

            employees[eId].totalTravelAllowance = roundToDigit(
              employees[eId].totalTravelAllowance,
            );

            employees[eId].totalHotelBill = roundToDigit(
              employees[eId].totalHotelBill,
            );

            employees[eId].totalOtherBill = roundToDigit(
              employees[eId].totalOtherBill,
            );

            employees[eId].salaryBasic = roundToDigit(
              employees[eId].salaryBasic,
            );

            employees[eId].salaryMobile = roundToDigit(
              employees[eId].salaryMobile,
            );

            employees[eId].salaryInternet = roundToDigit(
              employees[eId].salaryInternet,
            );

            employees[eId].previousDues = roundToDigit(
              employees[eId].previousDues,
            );

            employees[eId].totalPayable = roundToDigit(
              employees[eId].totalPayable,
            );

            employees[eId].totalPaid = roundToDigit(employees[eId].totalPaid);

            employees[eId].totalDue = roundToDigit(employees[eId].totalDue);

            employees[eId].salaryCollectionPercent =
              employees[eId].salaryCollectionPercent === Infinity
                ? 0
                : roundToDigit(employees[eId].salaryCollectionPercent);

            employees[eId].salaryInMarketSalesPercent =
              employees[eId].salaryInMarketSalesPercent === Infinity
                ? 0
                : roundToDigit(employees[eId].salaryInMarketSalesPercent);

            employees[eId].travelAllowanceCollectionPercent =
              employees[eId].travelAllowanceCollectionPercent === Infinity
                ? 0
                : roundToDigit(employees[eId].travelAllowanceCollectionPercent);

            employees[eId].travelAllowanceInMarketSalesPercent =
              employees[eId].travelAllowanceInMarketSalesPercent === Infinity
                ? 0
                : roundToDigit(
                    employees[eId].travelAllowanceInMarketSalesPercent,
                  );

            employees[eId].totalPayCollectionPercent =
              employees[eId].totalPayCollectionPercent === Infinity
                ? 0
                : roundToDigit(employees[eId].totalPayCollectionPercent);

            employees[eId].totalPayInMarketSalesPercent =
              employees[eId].totalPayInMarketSalesPercent === Infinity
                ? 0
                : roundToDigit(employees[eId].totalPayInMarketSalesPercent);
          });

          setData(Object.values(employees));
          setIsError(false);
          setError(null);
        })
        .catch((error) => {
          setIsError(true);
          setError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [axiosPrivate],
  );

  return { data, isLoading, isError, error, getData };
};

export default usePayroll;
