import BorderColorIcon from '@mui/icons-material/BorderColor';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IconButton, Stack } from '@mui/material';
import React from 'react';
import { toast } from 'react-toastify';
import {
  MultiSelectColumnFilter,
  SelectColumnFilterBool,
} from '../../../component/Table/SelectColumnFilter';
import Table from '../../../component/Table/Table';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useExpiredSession from '../../../hooks/useExpiredSession';
import { useQuery } from 'react-query';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

function CustomerType() {
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const location = useLocation();
  const navigate = useNavigate();
  const [deleteShow, setDeleteShow] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState('');

  const handleAddCustomerType = () => {
    navigate('/settings/customer-type/add');
  };
  const handleEditCustomerType = (id) => {
    navigate(`/settings/customer-type/edit/${id}`);
  };

  const handleDelete = () => {
    axiosPrivate
      .delete(`/api/v1/customer/type/${deleteId}`)
      .then((res) => {
        if (res.status === 204) {
          toast.success('Document was deleted');
          refetch();
        }
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      })
      .finally(() => {
        setDeleteId('');
        setDeleteShow(false);
      });
  };

  // get Customer Type
  const { data, isLoading, isFetching, isError, error, refetch } = useQuery(
    'customer-type',
    () => {
      return axiosPrivate.get('/api/v1/customer/type?populate=yes&sort=rank');
    },
  );

  const COLUMNS = React.useMemo(
    () => [
      {
        Header: 'Rank',
        accessor: 'rank',
        Filter: () => null,
      },
      /* {
        Header: 'Rank',
        id: 'selection2',
        Cell: ({ row }) => {
          const handleUpRank = () => {
            axiosPrivate
              .patch(`/api/v1/customer/type/${row.original.id}/rank-up`)
              .then((res) => {
                toast.success(res.data.message);
                axiosPrivate
                  .get('/api/v1/customer/type?populate=yes&sort=rank')
                  .then((res) => {
                    setAllData(res?.data?.data);
                  });
              })
              .catch((error) => {
                toast.warn(error?.response?.data?.message);
              });
          };
          const handleDownRank = () => {
            axiosPrivate
              .patch(`/api/v1/customer/type/${row.original.id}/rank-down`)
              .then((res) => {
                toast.success(res.data.message);
                axiosPrivate
                  .get('/api/v1/customer/type?populate=yes&sort=rank')
                  .then((res) => {
                    setAllData(res?.data?.data);
                  });
              })
              .catch((error) => {
                toast.warn(error?.response?.data?.message);
              });
          };
          return (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="subtitle2">{row.original.rank}</Typography>
              <IconButton onClick={handleUpRank} className="Table_icon_btn">
                <ArrowUpwardIcon color="success" />
              </IconButton>
              <IconButton onClick={handleDownRank} className="Table_icon_btn">
                <ArrowDownwardIcon color="error" />
              </IconButton>
            </Stack>
          );
        },
      }, */
      {
        Header: 'Type',
        accessor: 'customerType',
      },
      {
        Header: 'Prefix',
        accessor: 'codePrefix',
      },
      {
        Header: 'Location Type',
        accessor: 'territoryType.territoryType',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
        // Cell: ({ value, row }) => {
        //   return `${value} (${row?.original?.codePrefix})`;
        // },
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ value, row }) => {
          if (value.length > 20) {
            return (
              <span title={row.original.description}>
                {row.original.description.slice(0, 20)}...
              </span>
            );
          }
          return row.original.description;
        },
      },
      {
        Header: 'Stock',
        accessor: 'hasStock',
        Cell: ({ value, row }) => {
          if (value) {
            return <CheckCircleIcon color="success" />;
          }
          return <HighlightOffIcon color="error" />;
        },
        Filter: SelectColumnFilterBool,
      },
      {
        Header: 'Ledger',
        accessor: 'hasLedger',
        Cell: ({ value, row }) => {
          if (value) {
            return <CheckCircleIcon color="success" />;
          }
          return <HighlightOffIcon color="error" />;
        },
        Filter: SelectColumnFilterBool,
      },
      {
        Header: 'Active',
        accessor: 'isActive',
        Cell: ({ value, row }) => {
          if (value) {
            return <CheckCircleIcon color="success" />;
          }
          return <HighlightOffIcon color="error" />;
        },
        Filter: SelectColumnFilterBool,
      },
      {
        Header: 'Action',
        id: 'Action',
        Cell: ({ row }) => {
          // const handleDeleteShow = () => {
          //   setDeleteId(row.original.id);
          //   setDeleteShow(true);
          // };
          return (
            <Stack direction="row" alignItems="center" spacing={2}>
              <IconButton
                onClick={() => handleEditCustomerType(row.original.id)}
                className="Table_icon_btn">
                <BorderColorIcon />
              </IconButton>
              {/* <IconButton
                onClick={handleDeleteShow}
                className="Table_icon_btn delete">
                <DeleteIcon />
              </IconButton> */}
            </Stack>
          );
        },
      },
    ],
    [],
  );

  if (isError) {
    expired(error);
  }

  return (
    <>
      {location?.pathname !== '/settings/customer-type' ? (
        <Outlet context={[refetch]} />
      ) : (
        <Table
          columns={COLUMNS}
          data={data?.data?.data || [{}]}
          filename="Customer_Type"
          addButtonTooltip="Add Customer Type"
          isLoading={isLoading || isFetching}
          onAddButtonClick={handleAddCustomerType}
        />
      )}

      {/* <ConfirmModal
        title="Are you sure?"
        subtitle="You won't be able to revert this!"
        show={deleteShow}
        onSave={handleDelete}
        onHide={() => {
          setDeleteId('');
          setDeleteShow(false);
        }}
        actionButtonLabel="Delete"
        actionButtonColor="error"
      /> */}
    </>
  );
}

export default CustomerType;
