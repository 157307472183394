import * as React from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import TocIcon from '@mui/icons-material/Toc';
import CloseIcon from '@mui/icons-material/Close';

export default function CustomSpeedDial({ actions }) {
  return (
    <SpeedDial
      ariaLabel="Actions"
      sx={{ position: 'absolute', bottom: 72, right: 24 }}
      icon={<SpeedDialIcon icon={<TocIcon />} openIcon={<CloseIcon />} />}>
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          tooltipOpen
          onClick={action.onEvent}
        />
      ))}
    </SpeedDial>
  );
}
