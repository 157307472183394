import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

function SpecialTable({ data }) {
  return (
    <Stack gap={2} sx={styles.main}>
      <Stack gap={1.5}>
        <Stack
          direction={{ md: 'row' }}
          gap={1}
          alignItems="flex-start"
          sx={{
            background: '#e0e0e0',
            padding: 1,
            overflow: 'auto',
          }}>
          <Box sx={{ width: 1 }}>
            <Typography
              variant="h1"
              sx={{
                textAlign: 'center',
                display: 'block',
                color: '#fff',
                background: (theme) => theme.palette.primary.main,
                padding: 1,
                fontSize: 14,
                fontWeight: 500,
              }}>
              Info
            </Typography>

            <TableContainer>
              <Table>
                <TableRow>
                  <TableCell component="th">Transaction Type</TableCell>
                  <TableCell>
                    {data?.transactionType?.transactionType}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">Sender</TableCell>
                  <TableCell>{data?.sender?.nameCode}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">Receiver</TableCell>
                  <TableCell>{data?.receiver?.nameCode}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">Order Date</TableCell>
                  <TableCell>
                    {dayjs(data?.orderedAt).format('DD/MM/YYYY')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">Product Confirm Date</TableCell>
                  <TableCell>
                    {dayjs(data?.productsConfirmedAt).format('DD/MM/YYYY')}
                  </TableCell>
                </TableRow>

                {data?.paymentsConfirmedAt ? (
                  <TableRow>
                    <TableCell component="th">Payment Confirm Date</TableCell>
                    <TableCell>
                      {dayjs(data?.paymentsConfirmedAt).format('DD/MM/YYYY')}
                    </TableCell>
                  </TableRow>
                ) : null}

                <TableRow>
                  <TableCell component="th">Status</TableCell>
                  <TableCell>
                    <>
                      {data?.isProductsConfirmed ? (
                        <Tooltip title="Products Confirmed">
                          <CheckCircleIcon color="primary" />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Products Pending">
                          <RadioButtonUncheckedIcon color="primary" />
                        </Tooltip>
                      )}

                      {data?.isPaymentsConfirmed ? (
                        <Tooltip title="Payments Confirmed">
                          <CheckCircleIcon color="secondary" />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Payments Pending">
                          <RadioButtonUncheckedIcon color="secondary" />
                        </Tooltip>
                      )}

                      {data?.isDelivered ? (
                        <Tooltip title="Delivery Confirmed">
                          <CheckCircleIcon color="success" />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Delivery Pending">
                          <RadioButtonUncheckedIcon color="success" />
                        </Tooltip>
                      )}
                    </>
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </Box>

          <Box
            sx={{
              ...styles.main,
              width: '100%',
              padding: 0,
            }}
            p={2}>
            <Typography
              variant="h1"
              sx={{
                textAlign: 'center',
                display: 'block',
                color: '#fff',
                background: (theme) => theme.palette.primary.main,
                padding: 1,
                fontSize: 14,
                fontWeight: 500,
              }}>
              Products
              {/* {`${item?.total?.quantityC} ctn ${item?.total?.quantityP} pcs`} */}
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.products?.map((p, i) => {
                    const totalCartons = Math.floor(
                      (p?.quantity + p?.freeQuantity) / p?.quantityPerCarton,
                    );
                    const totalPieces =
                      (p?.quantity + p?.freeQuantity) % p?.quantityPerCarton;

                    let totalQuantityDisplay = `${totalCartons} ctn`;
                    if (totalPieces > 0)
                      totalQuantityDisplay += ` ${totalPieces} pcs`;
                    return (
                      <TableRow key={i} sx={{ td: { whiteSpace: 'initial' } }}>
                        <TableCell>
                          {p?.product?.name} ({p?.product?.code})
                        </TableCell>
                        <TableCell>{totalQuantityDisplay}</TableCell>
                        <TableCell>{p?.totalPrice} tk</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Stack>
      </Stack>

      {/* <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ height: '100px' }}>
          <Typography>No Data Found</Typography>
        </Stack> */}
    </Stack>
  );
}

export default SpecialTable;

const styles = {
  main: {
    h6: {
      display: 'inline-block',
      fontSize: 14,
      fontWeight: 700,
    },
    th: {
      fontWeight: 'bold',
      padding: 1,
      whiteSpace: 'nowrap',
      textTransfrom: 'capitalize',
      background: '#fff',
    },
    td: {
      padding: 1,
      whiteSpace: 'nowrap',
      background: '#fff',
    },
  },
};
