import React, { useMemo, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Icon, Stack, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import CountColorTypography from '../../component/CountColorTypography';
import TableSkeleton from '../../component/Skeleton/TableSkeleton';
import {
  DateRangeColumnFilter,
  MultiSelectColumnFilter,
  SelectColumnFilterBool,
} from '../../component/Table/SelectColumnFilter';
import Table from '../../component/Table/Table';
import Title from '../../component/report/Title';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import { Link } from 'react-router-dom';

export default function CustActivity() {
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();

  const [startDate, setStartDate] = useState(
    dayjs().startOf('month').format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState(
    dayjs().endOf('month').format('YYYY-MM-DD'),
  );

  const { data, isLoading, isError, error } = useQuery(
    ['customer-activity', startDate, endDate],

    () =>
      axiosPrivate
        .get(
          `/api/v1/report/customer-activity?startDate=${startDate}&endDate=${endDate}`,
        )
        .then((res) => {
          const results = [];

          res?.data?.data?.forEach((d) => {
            const result = {
              ...d,
              customerType: d?.customertypes?.[0]?.customerType,
              nameCode: `${d?.name} (${d?.code})`,
              activityCount: 0,
              ledgersCount: d?.ledgers?.length || 0,
              transactionSentCount: d?.transactionsSent?.length || 0,
              stockhistoriesCount: d?.stockhistories?.length || 0,
              territories: {},
            };

            d?.tx?.forEach((t) => {
              result.territories[`rank-${t?.territoryType?.rank}`] =
                t?.territory?.name || '';
            });

            let orderCount = 0;
            let deliveryCount = 0;

            d?.transactions?.forEach((t) => {
              if (
                t?.deliveredAt &&
                new Date(t?.deliveredAt).getTime() >=
                  new Date(t?.orderedAt).getTime()
              ) {
                deliveryCount += 1;
              } else {
                orderCount += 1;
              }
            });

            result.activityCount =
              result.ledgersCount +
              result.transactionSentCount +
              result.stockhistoriesCount +
              orderCount +
              deliveryCount;

            result.orderCount = orderCount;
            result.deliveryCount = deliveryCount;

            results.push(result);
          });

          return results;
        }),
  );

  const COLUMNS = useMemo(
    () => [
      {
        Header: 'Region',
        accessor: 'territories.rank-2',
      },
      {
        Header: 'HQ',
        accessor: 'territories.rank-3',
      },
      {
        Header: 'Point/Town',
        accessor: 'territories.rank-4',
      },
      /* {
        Header: 'Route/Beat',
        accessor: 'territories.rank-5',
      }, */
      {
        Header: 'Customer Name & Code',
        accessor: 'nameCode',
        Cell: ({ value, row }) => (
          <CountColorTypography
            component={Link}
            to={`/customers/transaction/${row?.original?._id}`}
            count={row?.original?.activityCount}
            error={0}
            variant="body2"
            sx={{ '&:hover': { textDecoration: 'underline' } }}>
            {value}&nbsp;
            <Icon sx={{ fontSize: 14 }}>open_in_new</Icon>
          </CountColorTypography>
        ),
      },
      {
        Header: 'Type',
        accessor: 'customerType',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Starting Date',
        accessor: 'dateOfStarting',
        Cell: ({ value }) => (value ? dayjs(value).format('DD/MM/YYYY') : ''),
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Closing Date',
        accessor: 'dateOfClosing',
        Cell: ({ value }) => (value ? dayjs(value).format('DD/MM/YYYY') : ''),
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Active',
        accessor: 'isActive',
        Cell: ({ value }) =>
          value ? (
            <CheckCircleIcon color="success" />
          ) : (
            <HighlightOffIcon color="error" />
          ),
        Filter: SelectColumnFilterBool,
      },
      {
        Header: 'Approved Collections',
        accessor: 'ledgersCount',
        Cell: ({ value }) => (
          <CountColorTypography
            count={value}
            error={0}
            success={1}
            align="center"
            variant="body2">
            {value}
          </CountColorTypography>
        ),
      },
      {
        Header: 'Deliveries Sent',
        accessor: 'deliveryCount',
        Cell: ({ value }) => (
          <CountColorTypography
            count={value}
            error={0}
            normal={1}
            align="center"
            variant="body2">
            {value}
          </CountColorTypography>
        ),
      },
      {
        Header: 'Pending Orders',
        accessor: 'orderCount',
        Cell: ({ value }) => (
          <CountColorTypography
            count={value}
            error={0}
            normal={1}
            align="center"
            variant="body2">
            {value}
          </CountColorTypography>
        ),
      },
      {
        Header: 'Deliveries Received',
        accessor: 'transactionSentCount',
        Cell: ({ value }) => (
          <CountColorTypography
            count={value}
            error={0}
            normal={1}
            align="center"
            variant="body2">
            {value}
          </CountColorTypography>
        ),
      },
      {
        Header: 'Physical Stocks',
        accessor: 'stockhistoriesCount',
        Cell: ({ value }) => (
          <CountColorTypography
            count={value}
            error={0}
            warning={2}
            success={3}
            align="center"
            variant="body2">
            {value}
          </CountColorTypography>
        ),
      },
    ],
    [],
  );

  if (isError) {
    expired(error);
  }

  return (
    <>
      <Title title="Customer Activity">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="row" gap={1}>
            <DesktopDatePicker
              label="From"
              inputFormat="DD/MM/YYYY"
              value={startDate}
              onChange={(newValue) =>
                setStartDate(dayjs(newValue).format('YYYY-MM-DD'))
              }
              renderInput={(params) => <TextField {...params} size="small" />}
            />

            <DesktopDatePicker
              label="To"
              inputFormat="DD/MM/YYYY"
              value={endDate}
              onChange={(newValue) =>
                setEndDate(dayjs(newValue).format('YYYY-MM-DD'))
              }
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </Stack>
        </LocalizationProvider>
      </Title>

      {isLoading ? (
        <TableSkeleton />
      ) : (
        <Table columns={COLUMNS} data={data} hideAddIcon noWrap />
      )}
    </>
  );
}
