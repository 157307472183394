import React from 'react';
import ExpandIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import HeadingTitle from '../../../component/HeadingTitle';
import { DateRangeColumnFilter } from '../../../component/Table/SelectColumnFilter';
import Table from '../../../component/Table/Table';
import useAuth from '../../../hooks/useAuth';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useExpiredSession from '../../../hooks/useExpiredSession';
import TransactionData from './ExpandRowInfo';
import Modals from '../../../component/Modals';
import {
  ExpTable,
  ExpandTableCaption,
  ExpandTableContainer,
} from '../../../assets/style/table/customTable';

function LinkCollections() {
  const location = useLocation();
  const navigate = useNavigate();
  const [refetch] = useOutletContext();
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const { role, Admin } = useAuth();

  const [verifyShow, setVerifyShow] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [collectionIds, setCollectionIds] = React.useState([]);
  const [totalCollection, setTotalCollection] = React.useState(0);

  const transaction = location?.state?.data;

  const { data, isLoading, isError, error } = useQuery(
    ['transaction-all-collections', transaction?.id],
    () => {
      const startDate = dayjs(transaction?.orderedAt)
        .subtract(1, 'month')
        .startOf('month')
        .toISOString();
      const endDate = dayjs(transaction?.orderedAt)
        .endOf('month')
        .toISOString();

      let url =
        '/api/v1/ledger?populate=yes&limit=none&sort=-dateOfDeposit&type=collection&approvedAmount[gt]=0';
      if (startDate) url += `&dateOfDeposit[gte]=${startDate}`;
      if (endDate) url += `&dateOfDeposit[lte]=${endDate}`;
      if (transaction?.transactionType?.senderLedgerAction !== 'none')
        url += `&customer[in][0]=${transaction?.sender?.id}`;
      if (transaction?.transactionType?.receiverLedgerAction !== 'none')
        url += `&customer[in][1]=${transaction?.receiver?.id}`;

      return axiosPrivate.get(url).then((res) => res?.data?.data);
    },
  );

  const COLUMNS = React.useMemo(
    () => [
      {
        id: 'ID',
        Header: 'Full ID',
        accessor: 'id',
        hidden: true,
      },
      {
        Header: 'ID',
        accessor: 'shortId',
      },
      {
        Header: 'Deposit Date',
        accessor: 'dateOfDeposit',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY');
          return value;
        },
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Entry Date',
        accessor: 'dateOfEntry',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY');
          return value;
        },
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Depositor',
        accessor: 'customer.nameCode',
      },
      {
        Header: 'Post Under',
        accessor: 'postUnder.nameCode',
      },
      {
        Header: 'Location',
        accessor: 'customer.territory.code',
      },
      {
        Header: 'Entry Amount',
        accessor: 'entryAmount',
        aggregate: 'sum',
      },
      {
        Header: 'Approved Amount',
        accessor: 'approvedAmount',
        aggregate: 'sum',
      },
      {
        Header: 'Online Charge',
        accessor: 'costAmount',
        aggregate: 'sum',
      },
      {
        Header: 'Incentive Amount',
        accessor: 'bonusAmount',
        aggregate: 'sum',
      },
      {
        Header: 'Bank Name',
        accessor: 'bankName',
        Cell: ({ value, row }) =>
          value ? (
            <span title={value}>{row.original.bankNameX || value}</span>
          ) : (
            row.original.paymentMode
          ),
      },
      {
        Header: 'Trx. Code',
        accessor: 'transactionID',
      },
      {
        Header: 'Recorded By',
        accessor: 'recordedBy.nameCode',
      },
    ],
    [],
  );

  const handleShowModal = () => {
    if (selectedRows?.length > 0) {
      const ids = [];
      let total = 0;

      selectedRows.forEach((i) => {
        ids.push(i?.original?.id);
        total += i?.original?.approvedAmount + i?.original?.costAmount;
      });

      setCollectionIds(ids);
      setTotalCollection(total);

      setVerifyShow(true);
    } else {
      toast.warn('Please select collections to link');
    }
  };

  const handleSubmit = () => {
    axiosPrivate
      .patch(`/api/v1/transaction/${transaction?.id}`, {
        collectionRefs: collectionIds,
      })
      .then(() => {
        toast.success('Linked successfully');
        navigate('/transactions');
        refetch();
      })
      .catch((error) => expired(error));
  };

  if (isError) {
    expired(error);
  }

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <HeadingTitle>Collections</HeadingTitle>
      </Box>

      <Table
        columns={COLUMNS}
        data={data || []}
        isLoading={isLoading}
        hideAddIcon
        fullHeight
        noWrap
        rowSelection
        setSelectionValues={setSelectedRows}
      />

      <Box sx={{ my: 3, textAlign: 'center' }}>
        {['Super Admin', ...Admin, 'DO', 'Accounts'].includes(role) ? (
          <Button
            disabled={selectedRows?.length < 1}
            color="primary"
            variant="contained"
            size="large"
            onClick={handleShowModal}>
            Link selected collections
          </Button>
        ) : null}
      </Box>

      <Accordion variant="outlined">
        <AccordionSummary
          expandIcon={<ExpandIcon />}
          sx={{
            '.MuiAccordionSummary-content': {
              flexDirection: 'column',
            },
          }}>
          <Typography variant="h6" gutterBottom>
            Transaction Details
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            divider={<Divider flexItem orientation="vertical" />}>
            <Typography variant="subtitle2">
              Order Date:&nbsp;
              <Typography component="span" variant="inherit" fontWeight="bold">
                {dayjs(transaction?.orderedAt).format('DD/MM/YYYY')}
              </Typography>
            </Typography>
            <Typography variant="subtitle2">
              Payable Amount:&nbsp;
              <Typography component="span" variant="inherit" fontWeight="bold">
                {transaction?.payableAmount}
              </Typography>
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <TransactionData data={transaction} hideActions />
        </AccordionDetails>
      </Accordion>

      <Modals
        title="Link selected collections"
        show={verifyShow}
        onHide={() => setVerifyShow(false)}
        onSave={handleSubmit}>
        <Box sx={{ width: '100%', flexGrow: 1 }}>
          <ExpandTableCaption>Selected collections</ExpandTableCaption>
          <ExpandTableContainer>
            <ExpTable cellSpacing="0">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Deposit Date</TableCell>
                  <TableCell>Approved Amount</TableCell>
                  <TableCell>Online Charge</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedRows.map((i) => (
                  <TableRow key={i?.original?.id}>
                    <TableCell>{i?.original?.shortId}</TableCell>
                    <TableCell>
                      {dayjs(i?.original?.dateOfDeposit).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>{i?.original?.approvedAmount}</TableCell>
                    <TableCell>{i?.original?.costAmount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableRow>
                <TableCell colSpan={2}>
                  <b>Total collection</b>
                </TableCell>
                <TableCell colSpan={2}>
                  <b>{totalCollection}</b>
                </TableCell>
              </TableRow>
            </ExpTable>
          </ExpandTableContainer>
        </Box>

        <Box sx={{ mt: 4, width: '100%', flexGrow: 1 }}>
          <ExpandTableCaption>Transaction info</ExpandTableCaption>
          <ExpandTableContainer>
            <ExpTable cellSpacing="0">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Order Date</TableCell>
                  <TableCell>Payable Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{transaction?.shortId}</TableCell>
                  <TableCell>
                    {dayjs(transaction?.orderedAt).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>{transaction?.payableAmount}</TableCell>
                </TableRow>
              </TableBody>
            </ExpTable>
          </ExpandTableContainer>
        </Box>
      </Modals>
    </>
  );
}

export default LinkCollections;
