import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/system/Box';
import CloseIcon from '@mui/icons-material/Close';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import React, { useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import Modals from '../../../component/Modals';
import { Autocomplete, FormControl, MenuItem, Select } from '@mui/material';
import { toast } from 'react-toastify';

function AddProduct(props) {
  const axiosPrivate = useAxiosPrivate();

  // form data states
  const [code, setCode] = React.useState('');
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [quantityPerCarton, setQuantityPerCarton] = React.useState(null);
  const [dealerPrice, setDealerPrice] = React.useState(null);
  const [dsrPrice, setDsrPrice] = React.useState(null);
  const [tradePrice, setTradePrice] = React.useState(null);
  const [maxRetailPrice, setMaxRetailPrice] = React.useState(null);
  const [weightPerPacket, setWeightPerPacket] = useState(null);
  const [offerType, setOfferType] = useState('none');
  const [freeItemsPerCarton, setFreeItemsPerCarton] = useState(0);
  const [minimumOrderQuantityForOffer, setMinimumOrderQuantityForOffer] =
    useState(0);
  const [giftItemDescription, setGiftItemDescription] = useState(' ');
  const [photos, setPhotos] = React.useState([]);
  const [productCat, setProductCat] = useState('');
  const [category, setCategory] = useState([]);

  // get product category
  React.useEffect(() => {
    axiosPrivate
      .get(
        '/api/v1/product/category?populate=yes&count=yes&page=1&limit=none&sort=-createdAt&isActive=true',
      )
      .then((res) => {
        setCategory(res?.data?.data);
      })
      .catch((error) => console.warn(error));
  }, []);
  // get product category

  //get offer type value
  const offerTypeInfo = ['free', 'gift', 'none'];

  const handleSubmit = () => {
    const data = new FormData();
    data.append('productCategory', productCat.id);
    data.append('code', code);
    data.append('name', name);
    data.append('description', description);
    data.append('quantityPerCarton', quantityPerCarton);
    data.append('dealerPrice', dealerPrice);
    data.append('dsrPrice', dsrPrice);
    data.append('tradePrice', tradePrice);
    data.append('maxRetailPrice', maxRetailPrice);
    data.append('weightPerPacket', weightPerPacket);
    data.append('offerType', offerType);
    data.append('freeItemsPerCarton', freeItemsPerCarton);
    data.append('minimumOrderQuantityForOffer', minimumOrderQuantityForOffer);
    data.append('giftItemDescription', giftItemDescription);
    photos?.forEach((p) => {
      if (p.photo instanceof File) data.append('photos', p.photo);
    });

    axiosPrivate
      .post('/api/v1/product', data)
      .then((res) => {
        toast.success(res?.data?.message);
        props.setAddModalShow(false);
        props.refetch();
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.message);
      });
  };

  const photosPreview = React.useMemo(() => {
    return photos?.map((p) => {
      if (!(p.photo instanceof File)) return undefined;
      return (
        <Grid key={p.id} item xs={12} sm={6} lg={4} xl={3}>
          <Card>
            <CardHeader
              subheader={p.photo?.name}
              subheaderTypographyProps={{ variant: 'caption' }}
              action={
                <IconButton
                  size="small"
                  color="error"
                  aria-label="remove photo"
                  onClick={() => {
                    const values = [...photos];
                    values.splice(
                      values.findIndex((value) => value.id === p.id),
                      1,
                    );
                    setPhotos(values);
                  }}>
                  <CloseIcon />
                </IconButton>
              }
            />
            <CardMedia
              component="img"
              height="160"
              image={URL.createObjectURL(p.photo)}
              alt={p.photo?.name}
            />
          </Card>
        </Grid>
      );
    });
  }, [photos?.length]);

  return (
    <>
      <Modals
        title="Add Product"
        show={props.addModalShow}
        onSave={() => props.setAddModalShow(false)}
        onHide={() => props.setAddModalShow(false)}
        form
        width="lg">
        <Stack direction={{ xs: 'column', sm: 'row' }} gap={2} mb={2}>
          <Box component={Paper} elevation={2} width={{ xs: 1, md: 1 / 2 }}>
            <Stack gap={1} sx={{ p: 3 }}>
              <Box>
                <Typography variant="subtitle2">
                  Category <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Autocomplete
                  size="small"
                  disableClearable
                  autoHighlight
                  fullWidth
                  options={category}
                  getOptionLabel={(option) =>
                    `${option?.productCategory} (${option?.codePrefix})` || ''
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={(event, value) => setProductCat(value)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Box>
              <Box>
                <Typography variant="subtitle2">
                  Name <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  size="small"
                  type="text"
                  variant="outlined"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Box>
              <Box>
                <Typography variant="subtitle2">
                  Code <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  size="small"
                  type="text"
                  variant="outlined"
                  fullWidth
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </Box>

              <Box>
                <Typography variant="subtitle2">Description</Typography>
                <TextField
                  size="small"
                  type="text"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Box>
            </Stack>
          </Box>

          <Box component={Paper} elevation={2} width={{ xs: 1, md: 1 / 2 }}>
            <Stack gap={1} direction="row" flexWrap="wrap" sx={{ p: 3 }}>
              <Box width={{ lg: '49%', xs: 1 }}>
                <Typography variant="subtitle2">
                  Quantity per carton <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  size="small"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  variant="outlined"
                  fullWidth
                  value={quantityPerCarton}
                  onChange={(e) => setQuantityPerCarton(e.target.value)}
                />
              </Box>
              <Box width={{ lg: '49%', xs: 1 }}>
                <Typography variant="subtitle2">
                  DSR price <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  size="small"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  variant="outlined"
                  fullWidth
                  value={dsrPrice}
                  onChange={(e) => setDsrPrice(e.target.value)}
                />
              </Box>
              <Box width={{ lg: '49%', xs: 1 }}>
                <Typography variant="subtitle2">
                  Dealer price <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  size="small"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  variant="outlined"
                  fullWidth
                  value={dealerPrice}
                  onChange={(e) => setDealerPrice(e.target.value)}
                />
              </Box>
              <Box width={{ lg: '49%', xs: 1 }}>
                <Typography variant="subtitle2">
                  Trade price <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  size="small"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  variant="outlined"
                  fullWidth
                  value={tradePrice}
                  onChange={(e) => setTradePrice(e.target.value)}
                />
              </Box>
              <Box width={{ lg: '49%', xs: 1 }}>
                <Typography variant="subtitle2">
                  Max retail price <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  size="small"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  variant="outlined"
                  fullWidth
                  value={maxRetailPrice}
                  onChange={(e) => setMaxRetailPrice(e.target.value)}
                />
              </Box>
              <Box width={{ lg: '49%', xs: 1 }}>
                <Typography variant="subtitle2">
                  Weight Per Packet <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  size="small"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  variant="outlined"
                  fullWidth
                  value={weightPerPacket}
                  onChange={(e) => setWeightPerPacket(e.target.value)}
                />
              </Box>
              <Box width={{ lg: '49%', xs: 1 }}>
                <Typography variant="subtitle2">Offer Type</Typography>
                <FormControl fullWidth>
                  <Select
                    size="small"
                    value={offerType}
                    onChange={(e) => setOfferType(e.target.value)}>
                    {offerTypeInfo.map((info, index) => (
                      <MenuItem key={index} value={info}>
                        {info}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box width={{ lg: '49%', xs: 1 }}>
                <Typography variant="subtitle2">
                  Free Items Per Carton
                </Typography>
                <TextField
                  size="small"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  variant="outlined"
                  fullWidth
                  value={freeItemsPerCarton}
                  onChange={(e) => setFreeItemsPerCarton(e.target.value)}
                />
              </Box>
              <Box width={{ lg: '49%', xs: 1 }}>
                <Typography variant="subtitle2">
                  Minimum Order Quantity For Offer
                </Typography>
                <TextField
                  size="small"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  variant="outlined"
                  fullWidth
                  value={minimumOrderQuantityForOffer}
                  onChange={(e) =>
                    setMinimumOrderQuantityForOffer(e.target.value)
                  }
                />
              </Box>
              <Box width={{ xs: 1 }}>
                <Typography variant="subtitle2">
                  Gift Item Description
                </Typography>
                <TextField
                  size="small"
                  type="text"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                  value={giftItemDescription}
                  onChange={(e) => setGiftItemDescription(e.target.value)}
                />
              </Box>
            </Stack>
          </Box>
        </Stack>

        <Paper elevation={2} sx={{ p: 3 }}>
          <Typography variant="subtitle2" sx={{ pb: 2 }}>
            Photos
          </Typography>
          <Grid container spacing={3}>
            {photosPreview}

            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <Box
                sx={{
                  minHeight: 200,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <Fab
                  component="label"
                  htmlFor="choose-product-photos"
                  variant="extended"
                  size="medium"
                  aria-label="add photos">
                  <AddAPhotoOutlinedIcon sx={{ mr: 1 }} />
                  Add photos
                </Fab>
                <input
                  id="choose-product-photos"
                  type="file"
                  accept="image/png, image/jpeg"
                  hidden
                  multiple
                  onChange={(event) => {
                    const files = [...photos];
                    [...event.target.files]?.forEach((file) => {
                      files.push({
                        id: `${Date.now()}${Math.random()}`,
                        photo: file,
                      });
                    });
                    setPhotos(files);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>

        <Box textAlign="center" mt={2}>
          <Button
            size="large"
            color="primary"
            variant="contained"
            onClick={handleSubmit}>
            Save product
          </Button>
        </Box>
      </Modals>
    </>
  );
}

export default AddProduct;
